<template>
  <v-card color="grey lighten-4" class="pb-2">
    <v-list-item class="grey lighten-3">
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <l-map :style="`height: ${maxContentHeight}px; z-index:0`" :zoom="zoom" :center="mapCenter">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    
    <template v-for="(item, i) in markers">
      <l-marker 
        :key="i"
        :lat-lng="[item.latitude, item.longitude]"
        v-if="item.latitude && item.longitude"
        :icon="icondiv(item)"
      ></l-marker>
    </template>
      
    
    
  </l-map>
  </v-card>
</template>

<script>
  // <l-marker v-for="(item, i) in markers" :key="i" :lat-lng="[item.latitude, item.longitude]"></l-marker>
// import L from 'leaflet';
// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { divIcon } from "leaflet";

export default {
  components: {
    LMap, 
    LTileLayer,
    LMarker
  },
  data: () => ({
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    // zoom: 15,
    // center: [51.505, -0.159],
    // markerLatLng: [51.504, -0.159],
    // markers: [{name: 'test', longitude: 107.63134002685547, latitude: -6.946066024567296}],

    showMore: false,
    rolename: "admin",
    greetingMessage: "Hello...",
    // title: 'judul'
  }),
  methods: {
    getFirstNotNull(vpositions) {
      if (vpositions.length > 0) {
        for (let i = 0; i < vpositions.length; i++) {
          let vpos = vpositions[i];
          if (vpos.latitude && vpos.longitude) {
            return vpos;
          }
        }
      }
      return null;
    },
    checkRole: function () {
      return true;
    },
    icondiv(itemData) {
      // let vhtml = this.defaultMapIcon;

      let vicon = "mdi-map-marker"
      let vcolor = "blue--text"
      if (typeof itemData.icon !== 'undefined') {
        vicon = itemData.icon
      }
      if (typeof itemData.color !== 'undefined') {
        vcolor = itemData.color
      } 
      let vhtml = `<i style="font-size: 32px" class="v-icon notranslate mdi ${vicon} theme--light ${vcolor}"></i>`
      // let vhtml = this.getMarkerHtml(this.mapMode, itemData).html;
      return divIcon({
        html: vhtml,
        // iconSize: [60, 62],
        iconSize: [38, 95],
        iconAnchor: [14, 28],
        popupAnchor: [0, -30],
        className: "myDivIcon",
      });
    },
  },
  computed: {
    maxContentHeight () {
      // return 800
      return this.height - 56;
    },
    mapCenter () {
      if (this.center) {
        return this.center
      }
      return this.defaultMapCenter
    },
    defaultMapCenter () {
      let result = [-6.901461, 107.618551];
      let vpoint = this.getFirstNotNull(this.markers);
      if (vpoint) {
        result = [vpoint.latitude, vpoint.longitude];
      }
      return result;
    }
  },
  created () {
    // let me = this
    console.log('map center', this.center)

  },
  props: {
    height: {
      type: Number,
      default: 500,
    },
    zoom: {
      type: Number,
      default: 16
    },
    center: Array,
    markers: {
      type: Array,
      default: function () {
        return [
          {
            name: 'Test',
            latitude: -6.901461,
            longitude: 107.618551
          }
        ]
      }
    },
    title: {
      type: String,
      default: 'Map'
    }

  },
};
</script>