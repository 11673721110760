<template>
  <div>
    <map-card :height="720" :title="'Map View: ' + title" :markers="deviceList"
      :categories="categoryDefs"
      :map-icons="mapIconDefs"
      :popup-headers="popupHeaders"
      :detail-headers="detailHeaders">
    </map-card>
  </div>
</template>

<script>
import MapCard from "@/components/Cards/MapCardExt";

export default {
  components: {
    MapCard,
  },
  data: () => ({
    categoryDefs: [
      { value: "status", caption: "Status" },
      { value: "year", caption: "Year" },
      { value: "projectname", caption: "Project" },
      { value: "profilename", caption: "Device Type" },
      // { value: "rssi_strength", caption: "RSSI" },
    ],
    popupHeaders: [
      { field: "profilename", caption: "Device Type", icon: "mdi-cog"},
      { field: "alamat", caption: "Lokasi / Alamat", icon: "mdi-cog"},
      { field: "projectname", caption: "Project Name", icon: "mdi-cog"},
      { field: "year", caption: "Year of Project", icon: "mdi-cog"},
      { field: "instansiname", caption: "Organization", icon: "mdi-cog"},
      // {
      //   field: "address",
      //   caption: "Address",
      //   icon: "mdi-map-marker",
      //   default: "-",
      // },
      { field: "lastdata", caption: "Last Data Time", icon: "mdi-cog"},
      // { field: "energy", caption: "Energy", icon: "mdi-cog", unit: "kWh" },
      // { field: "power", caption: "Power", icon: "mdi-cog", unit: "W" },
      // { field: "voltage", caption: "Voltage", icon: "mdi-cog", unit: "V" },
      // { field: "current", caption: "Current", icon: "mdi-cog", unit: "A" },
      // { field: "powerfactor", caption: "Power Factor" },
      // { field: "lightsensor", caption: "Light Sensor" },
      // { field: "battery", caption: "Battery Level", unit: "%" },
      { field: "status_caption", caption: "Status", icon: "mdi-cog", unit: "kWh" },
      { field: "status_description", caption: "Status Description", icon: "mdi-cog", unit: "kWh" },

      // { field: "brightness", caption: "Brightness" },
    ],
    detailHeaders: [
      { field: "profilename", caption: "Device Type", icon: "mdi-cog"},
      { field: "alamat", caption: "Lokasi / Alamat", icon: "mdi-cog"},
      { field: "projectname", caption: "Project Name", icon: "mdi-cog"},
      { field: "year", caption: "Year of Project", icon: "mdi-cog"},
      { field: "instansiname", caption: "Organization", icon: "mdi-cog"},
      { field: "lastdata", caption: "Last Data Time", icon: "mdi-cog"},
      { field: "energy", caption: "Energy", icon: "mdi-cog", unit: "kWh" },
      { field: "power", caption: "Power", icon: "mdi-cog", unit: "W" },
      { field: "voltage", caption: "Voltage", icon: "mdi-cog", unit: "V" },
      { field: "current", caption: "Current", icon: "mdi-cog", unit: "A" },
      { field: "powerfactor", caption: "Power Factor" },
      { field: "lightsensor", caption: "Light Sensor" },
      { field: "battery", caption: "Battery Level", unit: "%" },
      { field: "status_caption", caption: "Status", icon: "mdi-cog", unit: "kWh" },
      { field: "status_description", caption: "Status Description", icon: "mdi-cog", unit: "kWh" },
    ],
    mapIconDefsData: {
      status: {
        on: {
          caption: "ON",
          icon: "mdi-map-marker-check",
          color: "green--text",
          // html: '<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-check theme--light green--text"></i>',
          // class:
          //   "v-icon notranslate mdi mdi-map-marker-check theme--light green--text",
        },
        off: {
          caption: "OFF",
          icon: "mdi-map-marker",
          color: "grey--text",
          // html: '<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker theme--light grey--text"></i>',
          // class:
          //   "v-icon notranslate mdi mdi-map-marker theme--light grey--text",
        },
        offline: {
          caption: "Offline",
          icon: "mdi-map-marker-off-outline",
          color: "grey--text text--darken-4",
          // html: '<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-off-outline theme--light grey--text text--darken-4"></i>',
          // class:
          //   "v-icon notranslate mdi mdi-map-marker-off-outline theme--light grey--text text--darken-4",
        },
        error: {
          caption: "Warning",
          icon: " mdi-map-marker-alert",
          color: "orange--text text--darken-2",
          // html: '<i style="font-size: 28px" class="v-icon v-icon-large notranslate mdi mdi-map-marker-alert theme--light red--text"></i>',
          // class:
          //   "v-icon notranslate mdi mdi-map-marker-alert theme--light red--text",
        },
        maintenance: {
          caption: "Maintenance",
          icon: "mdi-map-marker-remove-variant",
          color: "orange--text text--darken-2",
          // html: '<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-remove-variant theme--light red--text  text--darken-2"></i>',
          // class:
          //   "v-icon notranslate mdi mdi-map-marker-remove-variant theme--light red--text text--darken-2",
        },
        other: {
          caption: "Other / Unknown",
          icon: "mdi-map-marker-question",
          color: "grey--text text--darken-4",
          // html: '<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-question theme--light grey--text text--darken-4"></i>',
          // class:
          //   "v-icon notranslate mdi mdi-map-marker-question theme--light grey--text text--darken-4",
        },
      },
      rssi_strength: {
        rendah : {
          caption: "Rendah",
          icon: "mdi-wifi-strength-1",
          color: "red--text",
        },
        sedang : {
          caption: "Sedang",
          icon: "mdi-wifi-strength-2",
          color: "orange--text",
        },
        tinggi : {
          caption: "Tinggi",
          icon: "mdi-wifi-strength-4",
          color: "green--text",
        },
        other : {
          caption: "Tidak diketahui",
          icon: "mdi-wifi-strength-outline",
          color: "grey--text text--darken-3",
        },
      }
    },
    colorList: ['red--text', 'purple--text', 'blue--text', 'teal--text', 'green--text', 'orange--text', 'brown--text',
        'blue-grey--text', 'amber--text', 'lime--text', 'cyan--text', 'indigo--text']
  }),
  computed: {
    deviceList() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      if (vitem && Object.keys(vitem).length) {
        if (typeof vitem.listDevices !== "undefined") {
          console.log('list-devices.....', vitem.listDevices)
          return vitem.listDevices;
        }
      }
      // console.log('NONE')
      return [vitem];
    },
    title() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      console.log("tree-selected", vitem);
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type === "device") {
          return vitem.name;
        } else if (vitem.name === "root") {
          return "All Devices";
        } else {
          return vitem.caption + " " + vitem.name;
        }
      } else {
        return "";
      }
      // return 'test-2'
    },
    mapIconDefs () {
      // return this.mapIconDefsData
      let result = this.mapIconDefsData
      result['year'] = this.extractYearIcon(this.deviceList)
      result['projectname'] = this.extractProjectIcon(this.deviceList)
      result['profilename'] = this.extractProfileName(this.deviceList)
      
      return result
    },
  },
  methods: {
    extractYearIcon(vlist) {
      let result = {}
      let idx = 0
      console.log('vlist', vlist)
      let xcolors = this.colorList
      for (let i=0; i<vlist.length; i++) {
        let vitem = vlist[i]
        if ((typeof vitem!=='undefined') && (typeof vitem['year'] !== 'undefined')) {
          let vvalue = vitem['year']+''
          if (typeof result[vvalue] === 'undefined') {
            let vidx = idx % xcolors.length
            let vcolor = xcolors[vidx]
            idx = idx+1
            // let vhtml = `<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-question theme--light ${vcolor}"></i>`
            // let vclass = `v-icon notranslate mdi mdi-map-marker-question theme--light ${vcolor}`
            result[vvalue] = {
              caption: vvalue+'',
              icon: 'mdi-map-marker-check',
              color: vcolor
            }
          } 
        }

      }
      return result
    },
    extractProjectIcon(vlist) {
      let result = {}
      let idx = 0
      console.log('vlist', vlist)
      let xcolors = this.colorList
      for (let i=0; i<vlist.length; i++) {
        let vitem = vlist[i]
        if ((typeof vitem!=='undefined') && (typeof vitem['projectname'] !== 'undefined')) {
          let vvalue = vitem['projectname']+''
          if (typeof result[vvalue] === 'undefined') {
            let vidx = idx % xcolors.length
            let vcolor = xcolors[vidx]
            idx = idx+1
            // let vhtml = `<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-question theme--light ${vcolor}"></i>`
            // let vclass = `v-icon notranslate mdi mdi-map-marker-question theme--light ${vcolor}`
            result[vvalue] = {
              caption: vvalue+'',
              icon: 'mdi-map-marker-star',
              color: vcolor
            }
          } 
        }

      }
      return result
    },
    extractProfileName(vlist) {
      let result = {}
      let idx = 0
      console.log('vlist', vlist)
      let xcolors = this.colorList
      for (let i=0; i<vlist.length; i++) {
        let vitem = vlist[i]
        if (typeof vitem['profilename'] !== 'undefined') {
          let vvalue = vitem['profilename']+''
          if (typeof result[vvalue] === 'undefined') {
            let vidx = idx % xcolors.length
            let vcolor = xcolors[vidx]
            idx = idx+1
            // let vhtml = `<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-question theme--light ${vcolor}"></i>`
            // let vclass = `v-icon notranslate mdi mdi-map-marker-question theme--light ${vcolor}`
            let xvalue = vvalue
            if (vvalue+''==='') {
              vvalue = 'other'
              xvalue = 'Other'
            }
            result[vvalue] = {
              caption: xvalue+'',
              icon: 'mdi-cog',
              color: vcolor
            }
          } 
        }

      }
      return result
    }
  },
  mounted() {},
};
</script>

