<template>
  <div>
    <v-card color="grey lighten-4" class="pb-2 pa-4" v-if="showMode === 'main'">
      <v-row>
        <v-col
          v-for="(item, n) in photographList"
          :key="n"
          class="d-flex child-flex pa-2"
          cols="4"
        >
          <v-img
            :src="`${baseUrl}/spju/imagefile/${deviceCode}/${item}?token=${token}`"
            aspect-ratio="1"
            class="grey lighten-2"
            @click="clickImage(item)"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col
          class="d-flex child-flex"
          cols="4"
          justify="center"
          align="center"
          @click="uploadImage"
        >
          <v-icon>mdi-camera-outline</v-icon>
        </v-col>
      </v-row>

      <p style="height: 10px">&nbsp;</p>
    </v-card>
    <v-card v-if="showMode === 'current'">
      <v-img
        :src="`${baseUrl}/spju/imagefile/${deviceCode}/${currentImage}?token=${token}`"
        class="grey lighten-2"
        contain
        @click="closeDialog()"
      >
      </v-img>
    </v-card>

    <v-card v-if="showMode === 'upload'" justify="center">
      <div class="text-xs-center">
        <image-uploader
        :preview="true"
        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
        capture="environment"
        :debug="1"
        doNotResize="gif"
        :autoRotate="true"
        outputFormat="file"
        @input="setImage"
      >
        
      </image-uploader>

        <v-row>
          <v-col
            class="d-flex child-flex"
            cols="12"
            justify="center"
            align="center"
            @click="uploadImage"
          >
            <div @click="loadImage">
            <v-icon large>mdi-camera-outline</v-icon>
            <div>Click to Select/Replace</div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-spacer></v-spacer>
      <v-divider class="pb-2"></v-divider>
      <v-card-actions>
        <v-btn text color="accent" @click="closeDialog">Cancel</v-btn
        >
        <v-btn
          primary
          color="primary"
          @click="doUploadImage"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
        // <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
        //     accept="image/*" class="input-file" id="fileInput" />

      
// import axios from "axios"

import ImageUploader from "vue-image-upload-resize";
import store from '@/store'

export default {
  components: {
    ImageUploader
  },
  data: () => ({
    currentImage: "",
    showImage: false,
    showMode: "main",
    hasImage: false,
    image: null
  }),
  computed: {
    photographList() {
      let vitem = this.$store.getters["devices/photographList"];
      if (vitem && Object.keys(vitem).length) {
        return vitem;
      }
      return null;
    },
    deviceCode() {
      return this.$route.params.devicecode;
    },
    baseUrl () {
      let xhost
      var vhost = window.location.hostname
      if (vhost==='localhost') {
        xhost = 'http://localhost:1337'
      } else {
        xhost = window.location.protocol+'//api.'+window.location.host
      }
      return xhost
    },
    token() {
      var vUserInfo = store.getters.USER_INFO
      var vtoken = vUserInfo.token
      return vtoken
    }
  },
  mounted() {
    console.log("params", this.$route.params);
    this.$store.dispatch("devices/getPhotographList", {
      devicecode: this.deviceCode,
    });
  },
  
  // handleFetch (event) {
  //   console.log('handle fetch....')
  //   const newRequest = new Request(event.request, {
  //           headers: {"Authorization": "Bearer XXX-my-token"},
  //           mode: "cors"
  //       });
  //       return fetch(newRequest);
  // },
  // created () {
  //   console.log('before mount..')
  //   window.addEventListener('fetch', this.handleFetch, true )
  // },
  // unmounted () {
  //   // self.removeEventListener('fetch', this.handleFetch, true)
  // },
  // created () {

  // }
  methods: {
    filesChange(fieldName, fileList) {
      console.log('fileChange...', fileList)
      if (fileList.length>0) {
        this.hasImage = true;
        this.fimage = fileList[0];
      }
    },
    setImage (output) {
      // let vinput = document.getElementById('fileInput')
      // console.log(vinput)
      console.log('output...', output)
      this.hasImage = true;
      this.image = output;
      console.log('Info', output.info)
      console.log('Exif', output.exif)
    },
    clickImage(imgsrc) {
      console.log("click-image", imgsrc);
      this.currentImage = imgsrc;
      this.showImage = true;
      this.showMode = "current";
    },
    closeDialog() {
      this.showMode = "main"
      this.showImage = false
      this.currentImage = ""
    },
    uploadImage() {
      this.showMode = "upload";
    },
    loadImage () {
      // document.getElementById('fileInput').onchange = this.filesChange;
      document.getElementById('fileInput').addEventListener("change", this.filesChange);
      document.getElementById('fileInput').click();
    },
    async doUploadImage () {
      console.log('do upload image')
      if (this.hasImage) {
        await this.uploadFile(this.image)
      }
      this.showMode = "main"
      this.$store.dispatch("devices/getPhotographList", {
        devicecode: this.deviceCode,
      });
      // location.reload()

    },
    async uploadFile(file) {
      let formData = new FormData();
      formData.append("file", file);
      // var data = await axios.get(`spju/device/history/${payload.devicecode}?tanggal=${payload.tanggal}`)
      return await this.$store.dispatch("devices/uploadPhoto", {
        devicecode: this.deviceCode,
        formData: formData,
        options: {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      });

      // return await axios.post(`spju/upload/${this.deviceCode}`, formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data"
      //   },
      // })
    }
  },
};
</script>

<style>
#fileInput {
  display: none;
}
</style>
