import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import vuetify from './plugins/vuetify'
import router from './router'

import store from './store'
import { Icon } from 'leaflet';


import 'leaflet/dist/leaflet.css';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'


console.log('Test xx')
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});
console.log('Icon-Default', Icon.Default)


Vue.config.productionTip = false

var xhost = ''
var vhost = window.location.hostname
if (vhost==='localhost') {
  xhost = 'http://localhost:1337'
// xhost = 'https://api.pju.antares.id'
} else {
  xhost = window.location.protocol+'//api.'+window.location.host
}
console.log('xxxhost', xhost)

axios.defaults.baseURL = xhost+"/";
axios.defaults.withCredentials = true;
var vUserInfo = store.getters.USER_INFO
var vtoken = vUserInfo.token
axios.defaults.headers.common = {'Authorization': `Bearer ${vtoken}`}


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
