import axios from "axios";

var getUserInfoStorage = function() {
  console.log('get store info')
  var strinfo = localStorage.getItem('user-info')
  try {
    if (strinfo===null) {
      return {}
    }
    var userinfo = JSON.parse(strinfo);
    return userinfo
  } catch (Exception) {
    return {}
  }
}
export default {
  state: {
      username: localStorage.getItem('user-username') || '',
      userinfo: getUserInfoStorage()
  },
  getters: {
      USERNAME: state => {
        return state.username;
      },
      USER_INFO: state => {
        return state.userinfo
      },
  },
  mutations: {
      SET_USERNAME: (state, payload) => {
        state.username = payload;
        localStorage.setItem('user-username', payload)
      },
      CLEAR_USERNAME: (state) => {
          state.username = ''
          localStorage.removeItem('user-username')
      },
      CLEAR_USERINFO: (state) => {
          state.username = ''
          state.userinfo = {}
          localStorage.removeItem('user-info')
          localStorage.removeItem('user-username')
      },
      SET_LOGIN_INFO: (state, payload) => {
        state.userinfo = payload;
        var vjsondata = JSON.stringify(payload)
        localStorage.setItem('user-info', vjsondata)
      },
  },
  actions: {
    // async LOGIN (context, payload) {
    //   console.log('channel Axios payload', payload)
    //   var xres = await axios.post ('system/login', payload)
    //   let vresult = xres.data
    //   console.log('received data', xres)
    //   context.commit('loadDeviceTree', vresult)
    // },
    LOGIN: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`system/login`, payload)
          .then(({ data, status }) => {
            if (status === 200) {
              console.log('login data...xxx', data)
                // commit('SET_USERNAME', data.username)
                commit('SET_LOGIN_INFO', data.data)
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    LOGOUT: ({commit}) => {
        // commit('CLEAR_USERNAME')
        commit('CLEAR_USERINFO')
        console.log('LOGOUT....')
        // localStorage.removeItem('user-info')
    },
    REFRESH_TOKEN: () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`token/refresh`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};