const counter = {
  namespaced: true,
  state: () => ({
    count: 1
  }),
  mutations: {
    reset (state) {
      // `state` is the local module state
      state.count = 0
    },
    increment (state) {
      // `state` is the local module state
      state.count++
    },
    addition (state, payload) {
      // `state` is the local module state
      console.log('xpayload', payload)
      state.count = state.count+payload
    }

  },
  actions: {
    reset (context) {
      context.commit('reset')
    },
    increment (context) {
      context.commit('increment')
    },
    addition (context, payload) {
      console.log(context)
      context.commit('addition', payload)
    }
  },
  getters: {
    doubleCount (state) {
      return state.count * 2
    },
    count (state) {
      return state.count
    }
  }
}

export default counter