<template>
  <div>
    <search-view
      :name ="config.name"
      :config="config"
      :dataset="filter_data"
      :loading="loading"
      @search="doSearch"
      @onchange="onDataChange"
    ></search-view>
  </div>
</template>

<script>
// import VJsf from "@koumoul/vjsf/lib/VJsf.js";
// import "@koumoul/vjsf/lib/VJsf.css";
// import "@koumoul/vjsf/lib/deps/third-party.js";

// import Vue from "vue";

import SearchView from "../Editor/SearchView.vue";

// import fschema from "./filter.schema.json";
// import axios from "axios";

export default {
  components: {
    SearchView
  },
  data: () => ({
    test: 1,
    valid: true,
    filter_data: {
      tenant_code: "pjudemo"
    },
    deviceIndex: {},
    model: {},
    config: {
      // title: 'Select Tenant',
      name: 'filter',
      dialog: false,
      hide_actions: true,
      schema: {
        type: "object",
        properties: {
          tenant_code: {
            "type": "string",
            "title": "Select Customer",
            "x-fromData": "context.list_devices",
            "x-itemKey": "tenantcode",
            "x-itemTitle": "tenantname",
            "x-display": "combobox",
          }
        },
        required: ["tenant_code"]
      },
      context: {
        list_devices: [{
          val: 'A', label: 'Aaaa'
        }]
      }
    }
    // options: {
    //   httpLib: axios,
    //   context: {
    //     list_tahun: [
    //       { val: "all", label: "Semua Tahun"},
    //       { val: "2019", label: "2019" },
    //       { val: "2020", label: "2020" },
    //       { val: "2021", label: "2021" },
    //       { val: "2022", label: "2022" },
    //       { val: "2023", label: "2023" },
    //       { val: "2024", label: "2024" },
    //       { val: "2025", label: "2025" }
    //     ],
    //     list_status: [
    //       { val: "all", label: "Semua Status"},
    //       { val: "on", label: "Device ON" },
    //       { val: "off", label: "Device OFF" },
    //       { val: "error", label: "Device Error" },
    //       { val: "unknown", label: "Device Unknown" },
    //       { val: "maintenance", label: "Maintenance" }
    //     ]
    //   }
    // },
    // schema: fschema
  }),
  methods: {
    async onChange(event, doClear = true) {
      console.log(event, doClear)
    },
    doSearch(data) {
      this.$emit("search", data);
    },
    updateDeviceList(vdata) {
      console.log('vdata******', vdata)
      let vList = vdata
      this.deviceIndex = {}
      for(let i=0; i<vdata.length; i++) {
        this.deviceIndex[vdata[i].devicecode] = vdata[i]
      }
      this.config.context.list_devices = vList
    },
    onDataChange (vdiff, vdata) {
      console.log('vdiff', vdiff)
      console.log('vdata', vdata)
      this.$emit("onchange", vdiff, vdata);
      if (vdiff['devicecode']==='all') {
        this.$emit('onselection', {})
      } else {
        let vselect = this.deviceIndex[vdiff['devicecode']]
        if (typeof vselect !== 'undefined') {
          this.$emit('onselection', vselect)
        } else {
          this.$emit('onselection', {})
        }

      }
    }
  },
  mounted() {
    // this.config.context.list_devices = this.dataset
    this.updateDeviceList(this.dataset)
    // let me = this
    // setTimeout(function () {
    //   me.doSearch(me.filter_data)
    // }, 1000)

    // this.doSearch(this.filter_data)
  },
  watch: {
    dataset (newData) {
      console.log('dataset***...', newData)
      this.updateDeviceList(newData)
      // Vue.set(this.config.context, 'list_devices', newData)
      
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    dataset: {
      type: Array,
      default: function () {
        return [];
      }
    },

    // config: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       title: 'Filter',
    //       subtitle: '',
    //       actions: [
    //         // {caption: "Submit", icon: "mdi-cloud-upload"},
    //         // {caption: "Cancel", icon: "mdi-cloud-upload", color: "info"},
    //         // {icon: "mdi-cloud-upload", color: "info"}
    //       ],
    //     };
    //   },
    // }
  }
}

</script>