<template>
  <div>
    <search-view
      :name ="config.name"
      :config="config"
      :dataset="filter_data"
      :loading="loading"
      @search="doSearch"
    ></search-view>
  </div>
</template>

<script>
// import VJsf from "@koumoul/vjsf/lib/VJsf.js";
// import "@koumoul/vjsf/lib/VJsf.css";
// import "@koumoul/vjsf/lib/deps/third-party.js";

import SearchView from "../Editor/SearchView.vue";

import fschema from "./filter.schema.json";
// import axios from "axios";

export default {
  components: {
    SearchView
  },
  data: () => ({
    test: 1,
    valid: true,
    filter_data: {
      tahun: "all",
      project: "all",
      provinsi: "all",
      kota: "all",
      wilayah: "all",
      meteran: "all",
      merk: "all",
      status: "all",
      sub_status: "all"
    },
    model: {},
    config: {
      title: 'Filter',
      name: 'filter',
      dialog: true,
      schema: fschema,
      context: {
        list_status: [
          { val: "all", label: "Semua Status"},
          { val: "on", label: "Device ON" },
          { val: "off", label: "Device OFF" },
          { val: "offline", label: "Device Offline" },
          { val: "error", label: "Warning" },
          { val: "maintenance", label: "Device Maintenance" }
        ],
        list_sub_status: [
          { val: "all", label: "Semua Warning"},
          { val: "no_current_data", label: "No Current Data" },
          { val: "other", label: "Warning Lainnya" }
        ]
      }
    }
    // options: {
    //   httpLib: axios,
    //   context: {
    //     list_tahun: [
    //       { val: "all", label: "Semua Tahun"},
    //       { val: "2019", label: "2019" },
    //       { val: "2020", label: "2020" },
    //       { val: "2021", label: "2021" },
    //       { val: "2022", label: "2022" },
    //       { val: "2023", label: "2023" },
    //       { val: "2024", label: "2024" },
    //       { val: "2025", label: "2025" }
    //     ],
    //     list_status: [
    //       { val: "all", label: "Semua Status"},
    //       { val: "on", label: "Device ON" },
    //       { val: "off", label: "Device OFF" },
    //       { val: "error", label: "Device Error" },
    //       { val: "unknown", label: "Device Unknown" },
    //       { val: "maintenance", label: "Maintenance" }
    //     ]
    //   }
    // },
    // schema: fschema
  }),
  methods: {
    async onChange(event, doClear = true) {
      console.log(event, doClear)
    },
    doSearch(data) {
      this.$emit("search", data);
    }
  },
  mounted() {
    let me = this
    setTimeout(function () {
      me.doSearch(me.filter_data)
    }, 1000)

    // this.doSearch(this.filter_data)
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
    // config: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       title: 'Filter',
    //       subtitle: '',
    //       actions: [
    //         // {caption: "Submit", icon: "mdi-cloud-upload"},
    //         // {caption: "Cancel", icon: "mdi-cloud-upload", color: "info"},
    //         // {icon: "mdi-cloud-upload", color: "info"}
    //       ],
    //     };
    //   },
    // }
  }
}

</script>