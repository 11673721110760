<template>
  <v-card color="grey lighten-4" class="pb-2">
    <v-list-item class="grey lighten-3">
      <v-list-item-title class="text-h6"> {{ title }} </v-list-item-title>
      <v-spacer></v-spacer>
      <vlist-item-action>
        <date-picker v-model="tanggal" @input="onDateInput"></date-picker>
      </vlist-item-action>
      <vlist-item-action>
        <date-picker v-model="tanggal2" @input="onDateInput"></date-picker>
      </vlist-item-action>
    </v-list-item>

    <v-divider></v-divider>
    <v-list-item class="grey lighten-3" v-if="false">
      <v-list-item-title class="text-h6">
        {{ tanggal }} {{ date }}
      </v-list-item-title>
    </v-list-item>
    <scatter
      :chart-data="chartdata"
      :options="options"
      :height="height"
    ></scatter>
    <v-list-item-title class="text-caption px-10">
      {{ footer }}&nbsp;
    </v-list-item-title>
  </v-card>
</template>

<script>
import Scatter from "@/components/Scatter.js";
import DatePicker from "@/components/Editor/DatePicker.vue";

let xAxesConfig = {
  type: "time",
  time: {
    unit: "day",
    displayFormats: {
      minute: "YYYY-MM-DD",
    },
    tooltipFormat: "YYYY-MM-DD",
  },
  scaleLabel: {
    labelString: "Timestamp",
  },
};

export default {
  components: {
    Scatter,
    DatePicker,
  },
  data() {
    return {
      datacollection: null,
      tanggal: "",
      tanggal2: "",
      // options: {
      //   scales: {
      //     xAxes: [xAxesConfig],
      //     yAxes: me.yscales,
      //   },
      // },
      xvalue: 0.0,
      colors: [
        "#222222",
        "#3a86ff",
        "#ff006e",
        "#2a9d8f",
        "#264653",
        "#e9c46a",
        "#8ac926",
        "#f4a261",
        "#e76f51",
        "#8338ec",
        "#d62828",
        "#8c584c",
        "003566",
      ],
      xdata: [
        [
          { x: 0, y: 5 },
          { x: 1, y: 3 },
          { x: 3, y: 6 },
          { x: 4, y: 15 },
          { x: 5, y: 11 },
          { x: 6, y: 8 },
          { x: 9, y: 9 },
          { x: 10, y: 15 },
        ],
        [
          { x: 0, y: 15 },
          { x: 1, y: 13 },
          { x: 3, y: 8 },
          { x: 4, y: 15 },
          { x: 5, y: 20 },
          { x: 6, y: 11 },
          { x: 9, y: 12 },
          { x: 10, y: 15 },
        ],
        [
          { x: 0, y: 12 },
          { x: 1, y: 3 },
          { x: 3, y: 4 },
          { x: 4, y: 2 },
          { x: 5, y: 1 },
          { x: 6, y: 0 },
          { x: 9, y: 11 },
          { x: 10, y: 13 },
        ],
      ],
    };
  },
  props: {
    date: {
      type: String,
      default: "",
    },
    date2: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Title",
    },
    datasets: {
      type: Array,
      default: function () {
        return [{ x: 0, y: 1 }];
      },
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    labels: {
      type: Array,
      default: function () {
        return [];
      },
    },
    footer: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 250,
    },
    yscales: {
      type: Object,
      default: undefined
    }
  },
  mounted() {
    // this.fillData();
    this.tanggal = this.date;
    this.tanggal2 = this.date2;
    // this.$nextTick(() => {
    //   this.tanggal = this.date
    // });
  },
  computed: {
    chartdata() {
      let result = {};
      let datasets = [];
      for (let i = 1; i < this.headers.length; i++) {
        let item = this.headers[i];
        let idx = i % this.colors.length;
        let chartItem = {
          label: item["caption"],
          borderColor: this.colors[idx],
          backgroundColor: ["rgba(47, 152, 208, 0)"],
          showLine: true,
          yAxisID: item["unit"],
          data: this.chdata[i - 1],
        };
        datasets.push(chartItem);
      }
      console.log("chartdata", result);
      result = { datasets: datasets };
      return result;
    },
    chdata() {
      let result = [];
      if (this.headers.length > 1) {
        let xaxis = this.headers[0];
        let xfield = xaxis["field"];
        let istime = false;
        if (xaxis["unit"] === "time") {
          istime = true;
        }

        for (let i = 1; i < this.headers.length; i++) {
          let rows = [];
          let yaxis = this.headers[i];
          let yfield = yaxis["field"];
          for (let j = 0; j < this.datasets.length; j++) {
            let dsrow = this.datasets[j];
            let xvalue = dsrow[xfield];
            if (istime) {
              xvalue = new Date(xvalue).getTime();
            }
            let item = { x: xvalue, y: dsrow[yfield] };
            rows.push(item);
          }
          result.push(rows);
        }
      }
      console.log(result);
      return result;
    },
    options () {
      let result = {
        scales: {
          xAxes: [xAxesConfig],
        }
      }
      if (this.yscales) {
        result.scales.yAxes = this.yscales
      }
      return result
    },
    xchartdata() {
      let result = [];
      let cnt = 1;
      console.log("datasets.....", this.datasets);
      for (let i = 0; i < this.datasets.count; i++) {
        let items = this.datasets[i];
        let label = "";
        if (typeof this.labels[i] !== "undefined") {
          label = this.labels[i];
        } else {
          label = "item-" + cnt;
          cnt = cnt + 1;
        }

        let resitem = {
          label: label,
          borderColor: this.getColor(i),
          backgroundColor: [this.getColor(i)],
          showLine: true,
          data: items,
        };
        result.push(resitem);
      }
      return result;
    },
  },
  methods: {
    onDateInput() {
      console.log("on Date Input", this.tanggal, this.tanggal2);
      this.$emit("datechanged", this.tanggal, this.tanggal2);
    },
    getColor(idx) {
      let vidx = idx % this.colors.length;
      return this.colors[vidx];
    },
    fillData() {
      console.log("firing phiil");
      this.datacollection = {
        datasets: [
          {
            label: "My First dataset",
            borderColor: "rgba(47, 152, 208, 1)",
            backgroundColor: ["rgba(255, 152, 208, 0)"],
            showLine: true,
            data: [],
          },
          {
            label: "My Second dataset",
            borderColor: "rgba(0, 0, 208, 1)",
            backgroundColor: ["rgba(120, 152, 255, 0)"],
            showLine: true,
            data: [],
          },
        ],
      };
      console.log("chartdata.....", this.chartdata);
    },
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>