<template>
  <v-card :loading="loading">
    
    <v-card-title v-if="config.title">{{ config.title }}</v-card-title>
      <v-divider v-if="config.title" />
    <v-card-text>
      <div v-if="config.isDebug">
        {{xdataset}}
      </div>

      <v-form v-model="valid">
        <v-jsf v-model="xdataset" :schema="schema " :options="xoptions" @change="onChange($event,xdataset)" @input="onInput" @message="TestMsg"/>
      </v-form>
      
    </v-card-text>
    <v-card-actions v-if="!config.hide_actions">

      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="doReset">Reset</v-btn>
      <v-btn color="primary" @click="doSearch">Search</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import VJsf from "@koumoul/vjsf/lib/VJsf.js";
import Vue from "vue";
import "@koumoul/vjsf/lib/VJsf.css";
import "@koumoul/vjsf/lib/deps/third-party.js";
import axios from "axios";
import ObjDiffMapper from "../../utils/ObjDiffMapper"
import objPath from 'object-path'
// import { config } from "process";


// fungsi untuk mengubah parameter dengan kurawal, misalnya {b} menjadi nilai dari b
const replaceParam = function(str, obj) {
var re = /{([^}]+)}/g;
var matches = str.match(re);
if (matches) {
  for (var i = 0; i < matches.length; i++) {
    var key = matches[i].replace('{', '').replace('}', '');
    var value = objPath.get(obj, key);
    str = str.replace(matches[i], value);
  }
}
return str;
}


export default {
components: {
  VJsf
},
data: function () {
  return {
    xdataset: {},
    dialog: false,
    valid: true,
    dialog_caption: "Edit Data",
    msgDialog: false,
    dialog_message: "",
    model: {},
    options: {
      httpLib: axios,
      // httpOptions: {
      //   baseURL: axios.defaults.baseURL+'/run/exec/pln-nusantara/navitas/v1/'
      // }
    },
    oldevent: {},
    schema: Vue.util.extend({}, this.config.schema),
  };
},
computed: {
  calcDataset () {
    return this.dataset
  },
  router_params () {
    return this.$route.query
  },
  xoptions: function () {
    let voptions = this.options
    voptions.context = this.config.context
    return voptions
  },
},
watch: {
  'config.dialog' (value) {
    this.dialog = value
  },
  dataset (value) {
    this.xdataset = value
  },
  dialog (value) {
    if (!value) {
      this.$emit('message', this.name, 'close-dialog')
    } 
  }
},
props: {
  name: {
    type: String,
    default: function () {
      return 'untitled';
    },
  },
  config: {
    type: Object,
    default: function () {
      return {};
    },
  },
  dataset: {
    type: Array,
    default: function () {
      return [];
    },
  },
  loading: {
    type: Boolean,
    default: false,
  }
},
methods: {
  async lookupData(vinput, vdata, vconfig) {
    let xurl = replaceParam(vconfig.url, vdata)
    let vurl = axios.defaults.baseURL+'/run/exec/pln-nusantara/navitas/v1/'+xurl
    console.log('vurl', vurl)
    let xdata = await axios.get(vurl)
    console.log('xdata', xdata)
    let vresult = xdata?.data
    let result = null
    if (typeof vresult[vconfig?.itemsProp] !== 'undefined') {
      result = vresult[vconfig?.itemsProp][vconfig?.value]
    }
    return result
  },
  closeDialog() {
    this.$emit('message', 'close-dialog')
  },
  async onChange(event, doClear=true) {
    console.log('onChange-event', event)
    console.log('old event', this.oldevent)
    const diff = ObjDiffMapper(this.oldevent, event)
    // console.log('diff', diff)
    // console.log('diff-x', Object.keys(diff)[0])
    let edited_fields = Object.keys(diff)
    for (let i=0; i<edited_fields.length; i++) {
      let edited_field = edited_fields[i]
      if (edited_field) {
        if (doClear) {
          const affected_field = objPath.get(this.config, 'schemaOnChange.clear.'+edited_field)
          console.log('affected_field', affected_field)
          if (affected_field) {
            for (const el of affected_field) {
              this.xdataset[el] = null
            }
          }
        }
        const updated_field = objPath.get(this.config, 'schemaOnChange.update.'+edited_field)
        console.log('updated_field', updated_field)
        if (updated_field) {
          for (const el of updated_field) {
            let xfunc = el?.function
            let xval = objPath.get(this.xdataset, el.from)
            if (xfunc==='lookup') {
              let xdata = await this.lookupData(el, this.xdataset, el.config)
              xval = xdata
            }
            objPath.set(this.xdataset, el.to, xval)
          }
        }
        console.log('xdataset', this.xdataset)
      }
    }
    
    this.$emit('onchange', diff, this.xdataset)
    this.oldevent = {...event}
    await this.updateRouter()
    // console.log('event.target.name', event.target.name)
  },

  async updateRouter() {
    // update router dari xdataset sesuai list yang ada di config.update_router
    if (this.config.update_router){
      let update = {}
      let doUpdate = false
      for (let i=0; i<this.config.update_router.length; i++) {
        let xfield = this.config.update_router[i]
        let xval = objPath.get(this.xdataset, xfield)
        if (typeof xval !== 'undefined' && xval !== null) {
          objPath.set(update, xfield, xval)
          doUpdate = true
        }
      }
      console.log('update', update)
      if (doUpdate) {
        this.$router.replace({query: update})
      }

    }
    

  },
  

  // async onChange(event, x, y, z) {
  //   console.log('onChange', event, x, y, z)
  //   console.log('old event', this.oldevent)
  //   const diff = ObjDiffMapper(this.oldevent, event)
  //   console.log('diff', diff)
  //   const affected_field = this.config.schemaOnChange.clear[Object.keys(diff)[0]]
  //   console.log('affected_field', affected_field)
  //   if (affected_field) {
  //     for (const el of affected_field) {
  //       this.schema.properties[el].items = []
  //       this.xdataset[el] = {}
  //     }
  //   }

  //   this.oldevent = {...event}
  //   // console.log('event.target.name', event.target.name)
  // },
  onInput(event, x, y, z) {
    console.log('onInput', event, x, y, z)
  },
  TestMsg(a, b, c, d) {
    console.log('TestMsg', a, b, c, d)
  },
  doSearch() {
    console.log('this.dataset', this.xdataset)
    // message, command, name, data
    // this.xdataset.do_search = 1
    this.onChange(this.xdataset, false)
    // this.$emit('message', this.name, 'search', this.xdataset)
    this.$emit('search', this.xdataset)
  },
  doReset() {
    this.xdataset = this.dataset
    this.$emit('reset', this.xdataset)
  }

},
mounted () {
  this.dialog = this.config.dialog
  let vdataset = this.dataset
  let vroute = this.$route.query
  this.xdataset = {...vroute, ...vdataset}
  this.onChange(this.xdataset, false)
  if (this.xdataset?.do_search) {
    this.doSearch()
  }
  // this.oldevent = {...this.xdataset}
  // let me = this
  // setTimeout(function(){
  //   me.dialog = true
  // }, 5000)
  
}
};
</script>
