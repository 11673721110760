<template>
<div>
  <span v-if="dateHelper" style="display:none">
    {{tanggal}} {{value}}
  </span>
  
<v-menu
    v-model="menuParent"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="text_field"
        v-model="tanggal"
        prepend-icon="mdi-calendar"
        
        v-bind="attrs"
        v-on="on"
        hide-details
        class="pa-0"
      ></v-text-field>
    </template>
    <v-date-picker v-model="tanggal" @input="onInputDate"></v-date-picker>
  </v-menu>
</div>
</template>
<script>
export default {
  components: {},
  props: ['value'],
  data() {
    return {
      dateHelper: true,
      menuParent: false,
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOS
    };
  },
  methods: {
    onInputDate () {
      this.menuParent = false
      console.log('input', this.tanggal)
      this.$emit("input", this.tanggal)
    }
  },
  watch: {
    // date (newVal) {
    //   console.log('input', newVal)
    //   this.$emit("input", newVal)
    // },
    value (newVal) {
      console.log('value', newVal)
      this.tanggal = newVal
    }
  },
  mounted () {
    this.tanggal = this.value
    console.log(this.tanggal)
    setTimeout(() => {
      this.dateHelper = false
      // console.log('set Timeout ')
      // this.tanggal = '2020-08-09'
      // this.$refs['text_field'].$el.focus()
    }, 200);

  },
  created () {
    // console.log('created...')
    // this.date = this.value
  }
};
</script>
