<template>
  <v-card color="grey lighten-4" class="pb-2">
    <v-list-item class="grey lighten-3">
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-card-text>
      <v-row align="center">

        <v-col class="d-flex" cols="12" sm="6">
          <v-select v-model="kota" :items="items" label="Kota/Kabupaten"></v-select>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-select v-model="area" :items="items" label="Area" ></v-select>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-select v-model="subarea" :items="items" label="Sub Area" solo></v-select>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-select v-model="organization" :items="items" label="Organization" solo></v-select>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-select v-model="project" :items="items" label="Projects" solo></v-select>
        </v-col>

      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn primary color="secondary">Reset</v-btn>
      <v-btn primary color="primary" @click="dialog = true">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "TreeItem",
  data: () => ({
    title: "Add Device",
  }),
};
</script>
