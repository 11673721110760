<template>
  <v-card color="grey">
    <v-alert border="left" colored-border :color="color" class="py-2 grey lighten-5">
      <div class="d-flex flex-no-wrap justify-space-between align-center">
        <v-card-text class="py-0 px-2">
          <span class="overline">{{title}}</span>
          <p class="mb-0">
            <span :class="numberClass+' text--primary'" v-if="number!=='x'">{{number}}</span>
            <span :class="'ml-3 text-h6 grey--text text--darken-2'">{{unit}}</span>
            <span class="text-h4">&nbsp;</span>
          </p>
        </v-card-text>

        <v-avatar :color="color">
          <v-icon dark v-if="icon"> {{icon}} </v-icon>
          <span class="white--text text-h5" v-else-if="initial"> {{initial}} </span>
        </v-avatar>
      </div>
      <div class="text-caption px-2">
        {{desc}}&nbsp;
      </div>
    </v-alert>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    test: 1,
  }),
  props: {
    number: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Title'
    },
    unit: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    initial: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: 'red'
    },
    numberClass: {
      type: String,
      default: 'text-h4'
    }


  }
};
</script>
<style scoped>
.ta-top {
  vertical-align: top;
}
</style>