var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({
    'background-image': 'url(' + require('@/assets/background_app.jpg') + ')',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    'background-repeat': 'no-repeat',
    'background-attachment': 'fixed',
    'background-position': 'center',
    'background-size': 'cover',
  }),attrs:{"id":"inspire"}},[_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-card',{staticClass:"pb-0",attrs:{"color":"white"}},[_c('v-list-item',{staticClass:"gloomy",attrs:{"dark":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"userform"},[_c('label',{attrs:{"for":"x-username"}},[_vm._v("Enter your username")]),_c('v-text-field',{ref:"username",staticClass:"pt-0",attrs:{"id":"x-username","xxlabel":"User Name","name":"username","prepend-icon":"mdi-account","placeholder":" ","type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('label',{attrs:{"for":"x-password"}},[_vm._v("Enter your password")]),_c('v-text-field',{ref:"password",staticClass:"pt-0",attrs:{"id":"x-password","xxlabel":"Password","name":"password","placeholder":" ","prepend-icon":"mdi-lock","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.login}},[_vm._v("Login")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }