<template>
  <v-card color="grey lighten-4" class="pb-2">
    <v-list-item class="grey lighten-3">
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="false">
      <v-list-item-content>
        {{ markers }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="false">
      <v-list-item-content>
        {{ mapIcons }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="false">
      <v-list-item-content> -{{ mapMode }}- </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <l-map
      :style="`height: ${maxContentHeight}px; z-index:0`"
      :options="{ zoomControl: false }"
      :zoom="zoom"
      :center="mapCenter"
      ref="map-obj"
    >
      <l-control-zoom position="bottomright"></l-control-zoom>
      <l-control position="topleft" class="grey lighten-3">
        <v-btn-toggle
          v-model="mapMode"
          tile
          color="primary accent-3"
          dense
          group
        >
          <template v-for="(item, i) in categoryShowDef">
            <v-btn :key="i" :value="item.value">
              {{ item.caption }}
            </v-btn>
          </template>
        </v-btn-toggle>
      </l-control>
      <l-control position="bottomright" class="grey lighten-5">
        <v-btn text color="primary" outlined elevation="2" @click="zoomAll">
          Zoom All
        </v-btn>
      </l-control>
      <l-control position="topright" v-if="false">
        <v-card color="grey lighten-4" class="pb-2" dense>
          <v-list-item class="grey lighten-3" dense>
            <v-list-item-content>
              <v-list-item-title class="text-title">
                {{ title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-text>{{ dialog }}</v-card-text>
          <v-btn @click="doClick">OK</v-btn>
        </v-card>
      </l-control>

      <l-control position="bottomleft">
        <v-card @click="clickHandler" dense>
          <v-list dense>
            <v-list-item v-for="(item, i) in legends" :key="i">
              <div v-html="item.html"></div>
              <span class="ml-3 subtitle-2">{{ item.caption }}</span>
            </v-list-item>
          </v-list>
        </v-card>
      </l-control>

      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

      <v-marker-cluster :options="clusterOptions">
        <template v-for="(item, i) in markers">
          <l-marker
            :key="i"
            :lat-lng="[item.latitude, item.longitude]"
            :icon="icondiv(item)"
            v-if="item.latitude && item.longitude"
            :ref="'markerx-' + i"
          >
            <l-popup :options="{ closeButton: false, minWidth: 480 }">
              <v-btn
                @click="closeMapPopup()"
                fab
                x-small
                absolute
                top
                right
                class="ml-8 primary"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
              <v-card elevation="0">
                <v-list-item two-line class="px-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.devicecode
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <data-list
                  width="500"
                  :header="popupHeaders"
                  :data="item"
                  dense
                >
                </data-list>
                <v-card-actions class="px-0" dense>
                  <v-btn @click="showStreetView(item)" fab x-small dark color="primary"
                    ><v-icon>mdi-google-street-view</v-icon></v-btn
                  >
                  <v-btn @click="showDetailData(item)" fab x-small dark color="primary"
                    ><v-icon> mdi-view-list-outline</v-icon></v-btn
                  >
                  <v-btn @click="showDetailPhoto(item)" fab x-small dark color="primary"
                    ><v-icon> mdi-image-album</v-icon></v-btn
                  >
                </v-card-actions>
              </v-card>
            </l-popup>
          </l-marker>
        </template>
      </v-marker-cluster>
    </l-map>
    <v-dialog max-width="800" v-model="dialog">
      <v-card>
        <v-list-item two-line >
          <v-list-item-content >
            <v-list-item-title>Street View: {{ detailData.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ detailData.devicecode }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <iframe
          :src="`https://pju.antares.id/stview-dist/index.html?longitude=${streetLongitude}&latitude=${streetLatitude}`"
          width="800"
          height="600"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <v-card-actions dense>
          <v-btn @click="dialog = false" small dark color="primary"
            ><v-icon> mdi-close</v-icon> Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="detailDialog">
      <v-card >
        <v-list-item two-line >
          <v-list-item-content >
            <v-list-item-title>{{ detailData.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ detailData.devicecode }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <data-list width="500" :header="detailHeaders" :data="detailData" dense list-class="" list-item-class="">
        </data-list>
        <v-card-actions dense>
          <v-btn @click="detailDialog = false" small dark color="primary"
            ><v-icon> mdi-close</v-icon> Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="800" v-model="photoDialog">
      <v-card >
        <v-list-item two-line >
          <v-list-item-content >
            <v-list-item-title>{{ detailData.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ detailData.devicecode }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-text>
        <v-row v-if="dialogMode=='list'">
        <v-col
          v-for="(item, n) in photographList"
          :key="n"
          class="d-flex child-flex pa-2"
          cols="4"
        >
          <v-img
            :src="`${baseUrl}/spju/imagefile/${detailData.devicecode}/${item}?token=${token}`"
            aspect-ratio="1"
            class="grey lighten-2"
            @click="clickImage(item)"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        
      </v-row>
      <v-row v-if="dialogMode=='image'">
        <v-col>
        <v-img 
        :src="`${baseUrl}/spju/imagefile/${detailData.devicecode}/${currentImage}?token=${token}`"
        class="grey lighten-2"
        contain
        @click="clickImage2(item)"
      >
      </v-img>
        </v-col>
      </v-row>
        
        </v-card-text>
        <v-card-actions dense>
          <v-btn @click="photoDialog = false" small dark color="primary"
            ><v-icon> mdi-close</v-icon> Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-model="imageDialog">
      <v-card>
        TEST
    </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// <l-popup :options="{closeButton: false}">
//   <v-card
//     elevation="0"
//     width="400"
//   >
//     <v-card-text class="px-0 pt-2" >
//       {{item}}
//     </v-card-text>
//     <v-card-actions class="px-0" dense>
//       <v-btn @click="dialog=true" icon><v-icon>mdi-google-street-view</v-icon></v-btn>
//     </v-card-actions>
//   </v-card>

// </l-popup>

// <l-marker v-for="(item, i) in markers" :key="i" :lat-lng="[item.latitude, item.longitude]"></l-marker>
// import L from 'leaflet';
// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// latitude: "-6.9711250203062205"
// longitude: "107.69027352333069"
// <iframe src="https://www.google.com/maps/embed?pb=!4v1657382379258!6m8!1m7!1s9hA1muoPss8UmB4W0_QtTg!2m2!1d-6.919314040460276!2d107.572033599354!3f170.15004!4f0!5f0.7820865974627469" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControl,
  LControlZoom,
} from "vue2-leaflet";
import { icon } from "leaflet";
import icon_img from "@/assets/markers/map-marker.svg";
import { divIcon } from "leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import DataList from "./DataList";
import store from '@/store'

// <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LControlZoom,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    LPopup,
    DataList,
  },
  data: () => ({
    dialog: false,
    detailDialog: false,
    photoDialog: false,
    detailData: {},
    streetLongitude: 107.690,
    streetLatitude: -6.971,
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    // zoom: 15,
    // center: [51.505, -0.159],
    // markerLatLng: [51.504, -0.159],
    // markers: [{name: 'test', longitude: 107.63134002685547, latitude: -6.946066024567296}],
    clusterOptions: {
      maxClusterRadius: 20,
    },
    icon: icon({
      iconUrl: icon_img,
      iconSize: [32, 37],
      iconAnchor: [16, 33],
    }),
    iconSize: 64,
    mapMode: "status",
    // defaultMapIcon: '<i class="v-icon notranslate mdi mdi-target theme--light blue--text"></i>',

    showMore: false,
    rolename: "admin",
    greetingMessage: "Hello...",

    // title: 'judul'
  }),
  // https://pju.antares.id/stview-dist/index.html?longitude=undefined&latitude=-6.94402120264601
  methods: {
    getFirstNotNull(vpositions) {
      if (vpositions.length > 0) {
        for (let i = 0; i < vpositions.length; i++) {
          let vpos = vpositions[i];
          if (vpos.latitude && vpos.longitude) {
            return vpos;
          }
        }
      }
      return null;

    },

    doClick() {
      this.dialog = true;
      // alert('test')
    },
    showStreetView(vitem) {
      this.detailData = vitem
      this.streetLongitude = vitem.longitude
      this.streetLatitude = vitem.latitude
      this.dialog = true
    },
    zoomAll() {
      console.log("markerBounds", this.markerBounds);
      this.$refs["map-obj"].mapObject.fitBounds(this.markerBounds);
    },
    closeMapPopup() {
      console.log("refs-test", this.$refs);
      this.$refs["map-obj"].mapObject.closePopup();
    },
    showDetailData (vitem) {
      this.detailData = vitem
      this.detailDialog = true
    },
    async showDetailPhoto (vitem) {
      this.dialogMode = 'list'
      this.detailData = vitem
      if (vitem && vitem.devicecode) {
        await this.$store.dispatch("devices/getPhotographList", {
          devicecode: vitem.devicecode,
        })
      }
      this.photoDialog = true

    },

    clickImage(imgsrc) {
      console.log("click-image", imgsrc);
      this.currentImage = imgsrc;
      this.dialogMode = 'image'
      this.$forceUpdate();
      // this.imageDialog = true;
    },
    clickImage2() {
      this.dialogMode = 'list'
      this.$forceUpdate();
      // this.imageDialog = true;
    },

    icondiv(itemData) {
      // let vhtml = this.defaultMapIcon;
      let vhtml = this.getMarkerHtml(this.mapMode, itemData).html;
      // let vmapMode = this.mapMode;
      // if (vmapMode === "status") {
      //   if (typeof itemData["status"] !== "undefined") {
      //     let vstatus = itemData["status"];
      //     vhtml = this.mapIcons[vmapMode]["other"]["html"];
      //     if (typeof this.mapIcons[vmapMode][vstatus] !== "undefined") {
      //       vhtml = this.mapIcons[vmapMode][vstatus]["html"];
      //     }
      //   }
      // }
      return divIcon({
        html: vhtml,
        // iconSize: [60, 62],
        iconSize: [38, 95],
        iconAnchor: [14, 28],
        popupAnchor: [0, -30],
        className: "myDivIcon",
      });
    },
    checkRole: function () {
      return true;
    },
    clickHandler() {},
    xgetLegendHtml(vitem) {
      console.log("vitem...", vitem);
      let vcolor = vitem.color;
      let vicon = vitem.icon;
      // return vcolor+' - '+vicon
      // return '<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-off-outline theme--light grey--text text--darken-4"></i>'
      return `<i style="font-size: 28px" class="v-icon notranslate mdi ${vicon} theme--light ${vcolor}"></i>`;
      // return '<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-off-outline theme--light grey--text text--darken-4"></i>'
    },
    getLegendHtml(vitem) {
      console.log("legend-html", vitem);
      // return 'XXX'
      // return vitem
      let vcolor = vitem.color;
      let vicon = vitem.icon;
      // return vcolor+' - '+vicon
      // return '<i style="font-size: 28px" class="v-icon notranslate mdi mdi-map-marker-off-outline theme--light grey--text text--darken-4"></i>'
      return `<i style="font-size: 28px" class="v-icon notranslate mdi ${vicon} theme--light ${vcolor}"></i>`;
    },
    getMarkerHtml(field, item) {
      console.log("getMarkerHtml", field, item)
      console.log('mapIcons', this.mapIcons)
      let html = this.defaultMapIcon;
      let caption = "Other";
      // let vclass = this.defaultMapIconClass;
      let xvalue = "unknown";
      if (typeof item !== "undefined" && typeof item[field] !== "undefined") {
        xvalue = item[field];
        if (typeof this.mapIcons[field] !== "undefined") {
          if (typeof this.mapIcons[field][xvalue] !== "undefined") {
            html = this.getLegendHtml(this.mapIcons[field][xvalue]);
            console.log("html", html);
            caption = this.mapIcons[field][xvalue]["caption"];
            // vclass = this.mapIcons[field][xvalue]["class"];
          } else {
            xvalue = "other";
            if (typeof this.mapIcons[field]["other"] !== "undefined") {
              html = this.getLegendHtml(this.mapIcons[field]["other"]);
              console.log("html", html);
              caption = this.mapIcons[field]["other"]["caption"];
              // vclass = this.mapIcons[field]["other"]["class"];
            }
          }
        } else {
          xvalue = "unknown";
        }
      }
      return {
        xvalue: xvalue,
        html: html,
        caption: caption,
      };
    },
  },
  computed: {
    token() {
      var vUserInfo = store.getters.USER_INFO
      var vtoken = vUserInfo.token
      return vtoken
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
    maxContentHeight() {
      // return 800
      return this.height - 56;
    },
    photographList() {
      let vitem = this.$store.getters["devices/photographList"];
      if (vitem && Object.keys(vitem).length) {
        return vitem;
      }
      return [];
    },
    baseUrl () {
      let xhost
      var vhost = window.location.hostname
      if (vhost==='localhost') {
        xhost = 'http://localhost:1337'
      } else {
        xhost = window.location.protocol+'//api.'+window.location.host
      }
      return xhost
    },

    markerBounds() {
      let vlatmin = null;
      let vlatmax = null;
      let vlonmin = null;
      let vlonmax = null;
      for (let i = 0; i < this.markers.length; i++) {
        let vmarker = this.markers[i];
        if (i === 0) {
          vlatmin = vmarker.latitude;
          vlatmax = vmarker.latitude;
          vlonmin = vmarker.longitude;
          vlonmax = vmarker.longitude;
        } else {
          if (vlatmin > vmarker.latitude) {
            vlatmin = vmarker.latitude;
          }
          if (vlatmax < vmarker.latitude) {
            vlatmax = vmarker.latitude;
          }
          if (vlonmin > vmarker.longitude) {
            vlonmin = vmarker.longitude;
          }
          if (vlonmax < vmarker.longitude) {
            vlonmax = vmarker.longitude;
          }
        }
      }
      return [
        [vlatmin, vlonmin],
        [vlatmax, vlonmax],
      ];
    },
    mapCenter() {
      let result = [-6.901461, 107.618551];
      // console.log("mapCenter - markers: ", this.markers, this.markers.length);
      let vpoint = this.getFirstNotNull(this.markers);
      // console.log("mapCenter - vpoint: ", vpoint);
      if (vpoint) {
        result = [vpoint.latitude, vpoint.longitude];
      }
      // if (this.markers.length > 0) {
      //   let vpoint = this.markers[0];
      //   result = [vpoint.latitude, vpoint.longitude];
      // } else if (this.markers.length > 1) {
      //   let vpoint = this.markers[0];
      //   result = [vpoint.latitude, vpoint.longitude];
      // }
      // console.log("mapCenter - result: ", result);
      return result;
    },
    categoryShowDef() {
      return this.categories;
    },
    defaultMapIcon() {
      let result = `<i style="font-size: 28px" class="${this.defaultIconClass}"></i>`;
      return result;
    },
    defaultMapIconClass() {
      return this.defaultIconClass;
    },
    legends() {
      // looping item of this.MapiCons
      let xfield = this.mapMode;
      let xfields = this.mapIcons?.[xfield]
      let keys = Object.keys(xfields);
      // let keys = this.mapIcons?.status?.keys();
      let result = {}
      keys.forEach((key) => {
        let field = xfield
        let item = { [field]: key }
        let { xvalue, html, caption } = this.getMarkerHtml(field, item);
        if (caption) {
          result[xvalue] = {
            html: html,
            caption: caption,
            // class: vclass,
          };

        }
      });
      return result
      

    },
    xlegends() {
      let result = {};
      let field = this.mapMode;
      for (let i = 0; i < this.markers.length; i++) {
        let item = this.markers[i];
        let { xvalue, html, caption } = this.getMarkerHtml(field, item);

        result[xvalue] = {
          html: html,
          caption: caption,
          // class: vclass,
        };
      }
      return result;
    },
  },
  created() {
    // let me = this
    console.log("map center", this.center);
  },
  props: {
    height: {
      type: Number,
      default: 500,
    },
    zoom: {
      type: Number,
      default: 16,
    },
    center: {
      type: Array,
      default: function () {
        return [-6.901461, 107.618551];
      },
    },
    markers: {
      type: Array,
      default: function () {
        return [
          {
            name: "Test",
            latitude: -6.901461,
            longitude: 107.618551,
          },
        ];
      },
    },
    title: {
      type: String,
      default: "Map",
    },
    iconDefs: {
      type: Array,
      default: function () {
        return [
          {
            iconUrl: icon_img,
            iconSize: [32, 37],
            iconAnchor: [16, 37],
          },
        ];
      },
    },
    categories: {
      type: Array,
      default: function () {
        return [
          { value: "status", caption: "Status" },
          { value: "devicetype", caption: "Device Type" },
          { value: "rssi", caption: "RSSI" },
          { value: "province", caption: "Province" },
        ];
      },
    },
    defaultIconClass: {
      type: String,
      default: "v-icon notranslate mdi mdi-map-marker theme--light blue--text",
    },
    mapIcons: {
      type: Object,
      default: function () {
        return {};
      },
    },
    popupHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    detailHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },

  },
};
</script>
<style scoped>
.button-legend {
  background-color: red;
}
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
