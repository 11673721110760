<template>
  <v-card class="overflow-hidden mx-auto" height="100%" max-width="500">
    {{infoSelect}}
    {{singleDevice}}
    <number-view
      :number="deviceCode"
      unit=""
      :title="singleDevice.name"
      icon="mdi-heart"
      color="orange"
      number-class="text-h5"
      class="ma-2"
    ></number-view>
    <v-bottom-navigation
      absolute
      grow
      scroll-target="#hide-on-scroll-example"
      v-model="infoSelect"
    >
      <v-btn color="deep-purple accent-4" value="info" text>
        <span>Info</span>

        <v-icon>mdi-information-outline</v-icon>
      </v-btn>


      <v-btn color="deep-purple accent-4" value="add" text>
        <span>Add</span>

        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>

      <v-btn color="deep-purple accent-4" value="location" text>
        <span>Location</span>

        <v-icon>mdi-map-marker-circle</v-icon>
      </v-btn>

      <v-btn color="deep-purple accent-4" value="maintenance" text>
        <span>Maintenance</span>

        <v-icon>mdi-cogs</v-icon>
      </v-btn>

      <v-btn color="deep-purple accent-4" value="photo" text>
        <span>Photo</span>

        <v-icon>mdi-camera-plus-outline</v-icon>
      </v-btn>

    </v-bottom-navigation>

    <v-responsive
      id="hide-on-scroll-example"
      class="overflow-y-auto"
      max-height="600"
    >
      <v-responsive>
        <detail-info v-if="infoSelect==='info'"></detail-info>
        <add-device v-if="infoSelect==='add'"></add-device>
        <location v-if="infoSelect==='location'"></location>
        <maintenance v-if="infoSelect==='maintenance'"></maintenance>
        <photograph v-if="infoSelect==='photo'"></photograph>
      </v-responsive>
    </v-responsive>
  </v-card>
</template>

<script>

import DetailInfo from "@/views/SmartLightMobile/DetailInfo"
import AddDevice from "@/views/SmartLightMobile/AddDevice"
import Location from "@/views/SmartLightMobile/Location"
import Maintenance from "@/views/SmartLightMobile/Maintenance"
import Photograph from "@/views/SmartLightMobile/Photograph"

import NumberView from "@/components/Cards/NumberView";


export default {
  components: {
    DetailInfo,
    AddDevice,
    Location,
    Maintenance,
    Photograph,
    NumberView
  },
  data: () => ({
    infoSelect: "info"
  }),
  computed: {
    singleDevice() {
      let vitem = this.$store.getters["devices/singleDevice"];
      if (vitem && Object.keys(vitem).length) {
        return vitem;
        // if (typeof vitem.listDevices !== 'undefined' && typeof vitem.listDevices[0] !== 'undefined') {
        //   return vitem.listDevices [0]
        // }
      }
      return null;
    },
    deviceCode () {
      return this.$route.params.devicecode
    }
  },
  methods: {},

};
</script>

