<template>
  <v-card :height="height">
    <v-card-text>
      <v-row>
        <v-col v-for="(item, i) in number_array" :key="i" :cols="colwidth" :md="colwidth" :lg="colwidth">
          <v-list two-line>
            <template v-for="(child, idx) in item">
              <v-subheader class="subtitle-2" :key="idx" v-if="child.type === 'group'">{{ child.text }}</v-subheader>
              

                <v-list-item class="my-0" v-if="child.type === 'number'" :key="idx">
                  <v-list-item-icon >
                    <v-icon color="indigo" >
                      {{ child.icon }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-subtitle>{{ child.text }}</v-list-item-subtitle>
                    <span>{{ getValue(child) }} {{ child.unit }}</span>
                    <!-- <v-list-item-title>{{ getValue(child) }} {{ child.unit }}</v-list-item-title> -->
                  </v-list-item-content>

                  
                </v-list-item>
              

            </template>

          </v-list>

        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ceil } from 'mathjs';
import objPath from 'object-path';

export default {
  data: () => ({
    test: 1,
  }),
  computed: {
    colwidth() {
      if (this.columns === 1) {
        return 12
      } else if (this.columns === 2) {
        return 6
      } else if (this.columns === 3) {
        return 4
      } else if (this.columns === 4) {
        return 3
      } else {
        return 2
      }
    },
    number_array() {
      let vcol = this.columns
      if (vcol < 1) {
        vcol = 1
      }
      // hitung jumlah baris yang tipenya bukan group dari numbers
      let vcount = 0
      for (let i = 0; i < this.numbers.length; i++) {
        if (this.numbers[i].type !== 'group') {
          vcount++
        }
      }
      let first_group = false
      if (this.numbers.length > 0) {
        if (this.numbers[0].type === 'group') {
          first_group = true
        }
      }

      let xrow = ceil(vcount / vcol)

      let result = []
      let current = []


      let vcounter = 0

      console.log('counter.....', vcount, vcol, xrow)
      for (let i = 0; i < this.numbers.length; i++) {

        current.push(this.numbers[i])
        if (this.numbers[i].type !== 'group') {
          vcounter++
        }
        if (vcounter >= xrow) {
          result.push(current)
          if (first_group) {
            current = []
            first_group = false
          } else {
            current = []
          }
          current = []
          vcounter = 0
        }

      }
      if (current.length > 0) {
        result.push(current)
      }
      return result
    }
  },
  methods: {
    getValue(item) {
      if (item.type === 'number') {
        if (item.value) {
          let value = objPath.get(this.number_data, item.value)
          return value
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    }
  },
  props: {
    numbers: {
      type: Array,
      default: () => [
      ],
    },
    columns: {
      type: Number,
      default: 1
    },
    number_data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: 500
    },


    title: {
      type: String,
      default: 'Title'
    },
    unit: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    initial: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: 'red'
    },
    numberClass: {
      type: String,
      default: 'text-h4'
    }


  }
};
</script>
<style scoped>
.ta-top {
  vertical-align: top;
}
</style>