<template>
  <v-app id="inspire">
    <v-app-bar app  elevation="0" outlined dense>

      <v-toolbar-title>Smart PJU
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-1" icon color="default" v-bind="attrs" v-on="on">
            <v-icon dark> mdi-account-outline </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
          >
          <v-list-item v-if="false">
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-icon>mdi-power </v-icon>
            <v-list-item-title class="ml-2" @click="logout">Logout</v-list-item-title>
          </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-btn
        v-if="false"
        class="mr-1"
        icon
        color="default"
        @click="rightDrawer = !rightDrawer"
      >
        <v-icon dark>mdi-store-cog</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <title-view
        :subtitle="deviceCode"
        unit=""
        :title="deviceDisplayName"
        icon="mdi-lightning-bolt"
        color="orange"
        number-class="text-h5"
        class="ma-2"
      ></title-view>

    <v-bottom-navigation
      absolute
      grow
      scroll-target="#hide-on-scroll-example"
      v-model="infoSelect"
    >
      <v-btn v-for="(btn, i) in btmButtons" :key="i" color="deep-purple accent-4" value="info" text @click="goToLink(btn)">
        <span>{{btn.caption}}</span>

        <v-icon>{{btn.icon}}</v-icon>
      </v-btn>


    </v-bottom-navigation>

    <v-responsive
      id="hide-on-scroll-example"
      class="overflow-y-auto"
      :max-height="respHeight"
    >
        <router-view></router-view>
    </v-responsive>
    </v-main>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>Telkom Antares</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import TitleView from "@/components/Cards/TitleView";

export default {
  components: {
    TitleView
  },
  data: () => ({
    drawer: null,
    drawerMode: 1,
    rightDrawer: false,
    selectedItem: '',
    btmButtons: [
      {
        name: 'info',
        caption: 'Info',
        icon: 'mdi-information-outline'
      },
      {
        name: 'add',
        caption: 'Add',
        icon: 'mdi-plus-circle-outline'
      },
      {
        name: 'location',
        caption: 'Location',
        icon: 'mdi-map-marker-circle'
      },
      // {
      //   name: 'maintenance',
      //   caption: 'Maintenance',
      //   icon: 'mdi-cogs'
      // },
      {
        name: 'photo',
        caption: 'Photo',
        icon: 'mdi-camera-plus-outline'
      }
    ]
  }),
  methods: {
    goToLink (vobj) {
      let vlink = '/devices/'+vobj.name+'/'+this.deviceCode
      this.$router.push(vlink)
    },
    onSelection(selection) {
      console.log("on-selection", selection);
      this.$store.dispatch("devices/selectDeviceItem", selection);
    },
    logout() {
      this.$store
        .dispatch("LOGOUT", {
          username: this.username,
          password: this.password,
        })
        .then((success) => {
          console.log("success", success);
          console.log("username", this.$store.getters.USERNAME);
          this.$router.push("/");
          location.reload();
        })
        .catch(function (err) {
          console.log(err.response);
          if (
            typeof err.response !== "undefined" &&
            typeof err.response.data !== "undefined" &&
            typeof err.response.data.message !== "undefined"
          ) {
            // alert(err.response.data.message);
            console.log("error", err.response.data.message);
          } else {
            // alert("Connection Error");
            console.log("error", err);
          }
        });
    },
  },
  computed: {
    windowHeight () {
      return window.innerHeight
    },
    respHeight () {
      return this.windowHeight - 240
    },
    singleDevice() {
      let vitem = this.$store.getters["devices/singleDevice"];
      if (vitem && Object.keys(vitem).length) {
        return vitem;
        // if (typeof vitem.listDevices !== 'undefined' && typeof vitem.listDevices[0] !== 'undefined') {
        //   return vitem.listDevices [0]
        // }
      }
      return null;
    },
    deviceCode () {
      // let vdevice = this.singleDevice
      // if (vdevice && typeof vdevice.devicecode !== 'undefined') {
      //   return vdevice.devicecode
      // }
      // return ''
      return this.$route.params.devicecode
    },
    deviceDisplayName () {
      let vdevice = this.singleDevice
      if (vdevice && typeof vdevice.devicecode !== 'undefined') {
        return vdevice.name
      }
      return '- not found -'

    }
  },
  mounted() {
    console.log('params', this.$route.params)
    this.$store.dispatch("devices/getSingleDevice", {devicecode: this.deviceCode});
  },
};
</script>
