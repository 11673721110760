<template>
  <v-app>
    <div class="d-flex flex-column justify-space-between align-center pt-8">
    <v-img
      :width="width"
      :src="require('../assets/not-found.png')"
    ></v-img>
    <h4 class="purple--text mt-2 mb-4">Page Not Found</h4>
    <v-btn rounded dark color="purple" @click="goHome" vx-if="!isMobile()">
      <v-icon left> mdi-home </v-icon>
      Go Home
    </v-btn>
  </div>

    
  </v-app>
</template>

<script>
export default {
  data: () => ({ 
    text2: 'x',
    // singleDevice: false
    width: 300,
  }),
  methods: {
    goHome() {
      let goHome = "/"
      this.$router.push(goHome)
      location.reload()
    },
    // isMobile () {
    //   if (screen.width <= 760) {
    //     return true
    //   }
    //   return false
    // },
  }
}
</script>
