
<template>
    <v-list
      dense
      v-scroll.self="onScroll"
      :class="listClass"
    >
      <template v-for="item in header" >

      <v-list-item :key="item.id" v-if="(typeof data[item.field] !== 'undefined')" :class="listItemClass" style="min-height:24px">
        <v-list-item-content class="py-1">{{
          item.caption
        }} </v-list-item-content>
        <v-list-item-content class="align-end py-1">
          {{
              (data[item.field] !== null)
              ? data[item.field]
              : item.default
              
          }}
        </v-list-item-content>
      </v-list-item>
      </template>

      <slot name="default"></slot>

    </v-list>
</template>

<script>
export default {
  name: "TreeItem",
  data: () => ({
  }),
  methods: {
  },
  computed: {
  },
  props: {
    header: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: undefined,
    },
    listClass: {
      type: String,
      default: 'transparent pb-2 px-0 overflow-y-auto'
    },
    listItemClass: {
      type: String,
      default: 'px-0'
    }

  },
};
</script>