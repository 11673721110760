<template>
  <v-app 
    :style="{
      'background-image': 'url(' + require('@/assets/background_app.jpg') + ')',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      'background-repeat': 'no-repeat',
      'background-attachment': 'fixed',
      'background-position': 'center',
      'background-size': 'cover',
    }"
  id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card color="white" class="pb-0">
              <v-list-item dark class="gloomy">
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-card-text>
                <v-form ref="userform">
                  <label for="x-username">Enter your username</label>
                  <v-text-field
                    class="pt-0"
                    id="x-username"
                    ref="username"
                    xxlabel="User Name"
                    v-model="username"
                    name="username"
                    prepend-icon="mdi-account"
                    placeholder=" "
                    type="text"
                  ></v-text-field>
                  <label for="x-password">Enter your password</label>
                  <v-text-field
                    class="pt-0"
                    ref="password"
                    v-model="password"
                    id="x-password"
                    xxlabel="Password"
                    name="password"
                    placeholder=" "
                    prepend-icon="mdi-lock"
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="login">Login</v-btn>
              </v-card-actions>
              
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  components: {
  },
  name: "login",
  data: () => ({
    // username: 'superadmin',
    // password: 'testadmin',
    title: 'Login Smart APJ',
    username: "",
    password: "",
    error: false,
    autofillFix: false,
    actions: [
      {caption: "Login", color: "info", xicon: "mdi-cloud-upload", isText: true, size:'large'},
    ]
  }),
  methods: {
    login() {
      var me = this
      console.log("LOGIN");
      this.$store
        .dispatch("LOGIN", {
          username: this.username,
          password: this.password,
        })
        .then((success) => {
          console.log("success", success);
          console.log("username", me.$store.getters.USERNAME);
          let pathTo = "/"
          if (me.$route.query && me.$route.query.redirect) {
            pathTo = me.$route.query.redirect
          }
          console.log(pathTo)
          setTimeout(() => {
            me.$router.push(pathTo);
            setTimeout(() => {
            location.reload();
              
            }, 1000);
            
          }, 1000);
        })
        .catch(function (err) {
          console.log(err);
          console.log(err.response);
          if (
            typeof err.response !== "undefined" &&
            typeof err.response.data !== "undefined" &&
            typeof err.response.data.message !== "undefined"
          ) {
            alert(err.response.data.message);
          } else {
            alert("Connection Error");
          }
        });
      // .catch(error => {
      //     this.error = true;
      // })
    },
    

  },
  props: {
    source: String,
  },
  mounted() {
    let me = this
    // console.log(this.$refs.username.$el)
    setTimeout(() => {
      //     window.focus()
      // console.log('username', this.$refs.username.focus())
      // me.$refs.username.$el.focus();
      // me.$refs.password.focus()
      me.$refs.username.focus()
      setTimeout(() => {
        // me.$refs.username.focus()
        me.$refs.username.$el.dispatchEvent(new KeyboardEvent('keydown', {'key': 'a'}));
      }, 1000)
      
    }, 1000);
  },
};
</script>