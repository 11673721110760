<template>
  <v-card color="grey lighten-4" class="pb-2" :width="width">
    <v-list-item class="grey lighten-3">
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ title }}
          
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list
      dense
      class="transparent pb-2 overflow-y-auto"
      :height="maxContentHeight"
    >
      <template v-for="item in header" >

      <v-list-item :key="item.id" v-if="(typeof data[item.field] !== 'undefined')">
        <v-list-item-content class="py-1">{{
          item.caption
        }} </v-list-item-content>
        <v-list-item-content class="align-end py-1">
          {{
            dataValue(item)              
          }}
        </v-list-item-content>
      </v-list-item>
      </template>

      <slot name="default"></slot>

    </v-list>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    showMore: false,
    rolename: "admin",
    greetingMessage: "Hello...",
  }),
  methods: {
    dataValue(item) {
      try {
        if (typeof this.data!=='undefined' && typeof this.data[item.field] !== 'undefined' && this.data[item.field] !== null) {
          if (item.decimal) {
            let xvalue = this.data[item.field]
            return Number(xvalue).toFixed(item.decimal)
          } else {
            return this.data[item.field]
          }
        }        
        return ''
      } catch (error) {
        return ''
      }
    },
    checkRole: function () {
      return true;
    },
  },
  computed: {
    maxContentHeight() {
      // return 800
      return this.height - 56;
    },
  },
  props: {
    header: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: "Title",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 500,
    },
    width: {
      type: Number,
      default: undefined,
    },

  },
};
</script>