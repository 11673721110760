<template>
  <v-row>
    <v-col cols="7">
    <group-view  :numbers="number_setting_solbat" :number_data="dataset" :columns="3" :height="450">

    </group-view>
    </v-col>
    <!-- <v-col cols="5">
    <group-view  :numbers="number_setting_battery" :number_data="numberData" :columns="2">

    </group-view>
    </v-col> -->
    <v-col cols="5">
    <group-view  :numbers="number_setting_other" :number_data="dataset" :columns="2" :height="450">

    </group-view>
    </v-col>
  </v-row>
  <!-- <v-row> -->

    <!-- <template v-for="(item, i) in numberSetting">
      <v-col
        cols="6"
        md="6"
        lg="6"

        :key="i"
      >
        <group-view
          :title="item.title"
          :numbers="item.numbers"
          :color="item.color"
        ></group-view>
      </v-col>
    </template> -->
  <!-- </v-row> -->
</template>

<script>
import GroupView from "@/components/Cards/GroupNumberView";
// import SampleData from "./sampledata.json";
// var SampleData = 

export default {
  components: {
    GroupView
  },
  data: () => ({
    test: 1,
    numberData: {
      solar: {
        current: 0,
        voltage: 0,
        power: 0,
        status: 'OK'
      },
      battery: {
        voltage: 0,
        max_voltage: 100,
        temp: 0,
        charging_status: 'Normal, Running, No Charging',
        current: 0,
        min_voltage: 0,
        soc: 50.5,
        status: '-'
      },
      dc_load:{
        current: 0,
        voltage: 0,
        power: 0,
        load_status: 'OK'
      },
      controller: {
        temp: 30,
        status: 'OK'
      },
      energy: {
        hasil: 100,
        komsumsi: 100
      }
    },
    number_setting_solar: [
      {
        "text": "Solar Information",
        "type": "group"
      },
      {
        "text": "Solar Current",
        "type": "number",
        "icon": "mdi-cog",
        "value": "solar_current",
        "unit": "A"
      },
      {
        "text": "Solar Voltage",
        "type": "number",
        "icon": "mdi-cog",
        "value": "solar_voltage",
        "unit": "V"
      },
      {
        "text": "Solar Power",
        "type": "number",
        "icon": "mdi-cog",
        "value": "solar_power",
        "unit": "A"
      },
      {
        "text": "Solar Status",
        "type": "number",
        "icon": "mdi-cog",
        "value": "solar_status",
        "unit": ""
      },
    ],
    number_setting_battery: [
      {
        "text": "Battery Information",
        "type": "group"
      },
      {
        "text": "Battery Voltage",
        "type": "number",
        "icon": "mdi-cog",
        "value": "battery_voltage",
        "unit": "V"
      },
      {
        "text": "Max Voltage",
        "type": "number",
        "icon": "mdi-cog",
        "value": "battery_max_voltage",
        "unit": "V"
      },
      {
        "text": "Battery Temp",
        "type": "number",
        "icon": "mdi-cog",
        "value": "battery_temp",
        "unit": "°C"
      },
      {
        "text": "Charging Status",
        "type": "number",
        "icon": "mdi-cog",
        "value": "battery_charging_status",
        "unit": ""
      },
      {
        "text": " ",
        "type": "group"
      },
      {
        "text": "Battery Current",
        "type": "number",
        "icon": "mdi-cog",
        "value": "battery_current",
        "unit": "A"
      },
      {
        "text": "Min Voltage",
        "type": "number",
        "icon": "mdi-cog",
        "value": "battery_min_voltage",
        "unit": "V"
      },
      {
        "text": "Battery SOC",
        "type": "number",
        "icon": "mdi-cog",
        "value": "battery_soc",
        "unit": "%"
      },
      {
        "text": "Battery Status",
        "type": "number",
        "icon": "mdi-cog",
        "value": "battery_status",
        "unit": ""
      },
    ],
    number_setting_other: [
      {
        "text": "DC Load Information",
        "type": "group"
      },
      {
        "text": "Load Current",
        "type": "number",
        "icon": "mdi-cog",
        "value": "load_current",
        "unit": "A",
        "xvalue": "123.45"
      },
      {
        "text": "Load Voltage",
        "type": "number",
        "icon": "mdi-cog",
        "value": "load_voltage",
        "unit": "V"
      },
      {
        "text": "Load Power",
        "type": "number",
        "icon": "mdi-cog",
        "value": "load_power",
        "unit": "W"
      },
      {
        "text": "Load Status",
        "type": "number",
        "icon": "mdi-cog",
        "value": "load_status",
        "unit": ""
      },
      {
        "text": "Controller Information",
        "type": "group"
      },
      {
        "text": "Device Temp",
        "type": "number",
        "icon": "mdi-cog",
        "value": "scc_temp",
        "unit": "°C"
      },
      {
        "text": "Device Status",
        "type": "number",
        "icon": "mdi-cog",
        "value": "scc_status",
        "unit": ""
      },
      {
        "text": "Daily Energy",
        "type": "group"
      },
      {
        "text": "Energy Result",
        "type": "number",
        "icon": "mdi-cog",
        "value": "energy_generated",
        "unit": ""
      },
      {
        "text": "Energy Consumption",
        "type": "number",
        "icon": "mdi-cog",
        "value": "energy_consumed",
        "unit": ""
      },
      

    ],
    numberSettingx: [
      {
        "field": "name",
        "title": "Solar Information",
        "unit": "",
        "icon": "mdi-ceiling-light-outline",
        "color": "light-green darken-2",
        "numbers": [{
          "text": "Solar Current",
          "value": "99",
          "unit": "A"
        },{
          "text": "Solar Voltage",
          "value": "99",
          "unit": "V"
        },{
          "text": "Solar Power",
          "value": "99",
          "unit": "W"
        },{
          "text": "Solar Status",
          "value": "OK",
          "unit": ""
        }]
      },
      {
        "field": "name",
        "title": "Battery Information",
        "unit": "",
        "icon": "mdi-ceiling-light-outline",
        "color": "light-green darken-2",
        "numbers": [{
          "text": "Battery Voltage",
          "value": "99",
          "unit": "V"
        },{
          "text": "Max Voltage",
          "value": "99",
          "unit": "V"
        },{
          "text": "Battery Temp",
          "value": "99",
          "unit": "°C"
        },{
          "text": "Charging Status",
          "value": "-",
          "unit": ""
        },{
          "text": "Battery Current",
          "value": "99",
          "unit": "A"
        },{
          "text": "Battery Current",
          "value": "99",
          "unit": "A"
        }]
      },
      {
        "field": "name",
        "title": "DC Load Information",
        "unit": "",
        "icon": "mdi-ceiling-light-outline",
        "color": "light-green darken-2",
        "numbers": [{
          "text": "Load Current",
          "value": "99",
          "unit": "A"
        },{
          "text": "Solar Voltage",
          "value": "99",
          "unit": "V"
        },{
          "text": "Solar Power",
          "value": "99",
          "unit": "W"
        },{
          "text": "Solar Status",
          "value": "OK",
          "unit": ""
        }]
      },
    ]
  }),
  computed: {
    number_setting_solbat () {
      return this.number_setting_solar.concat(this.number_setting_battery)
    }
  },
  
  mounted() {},
  props: {
    dataset: {
      type: Array,
      default: function () {
        return [{ x: 0, y: 1 }];
      },
    },
  }
};
</script>