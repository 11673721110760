<template>
  <v-app id="inspire">
    <div>
      <v-navigation-drawer v-model="drawer" app dark color="gloomy" width="350">
        <div class="pa-4 gloomymore">
          <v-img :src="require('../../assets/smart-pju.png')"></v-img>
        </div>
        
        <v-row>
          <v-col col="12" class="my-4 mx-3">
            <v-list-item dark v-if="false">
              {{ userInfo }}xx
            </v-list-item>

            <!-- <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Filter</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="$store.dispatch('devices/refreshDeviceTree', queryFilter)">
                    <v-icon :color="isLoading ? 'grey' : 'white'">mdi-refresh</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item> -->
            <select-tenant :dataset="tenantList" class="mb-2" v-if="userInfo.role_name=='user_tenant'"></select-tenant>
            <filter-view :loading="isLoading" @search="doSearch"></filter-view>
            <select-device :dataset="deviceList" @onselection="onSelection"></select-device>
          </v-col>
        </v-row>
        

        <!-- <template v-if="(drawerMode = 1)">
          
        </template>

        <nav-drawer v-if="(drawerMode = 0)" /> -->
      </v-navigation-drawer>

      <v-app-bar app class="mt-2 mb-2 ml-4 mr-4" elevation="0" outlined dense v-if="!isMobile()">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-tabs align-with-title>
          <v-tab to="/dashboard">
            <v-icon small>mdi-home</v-icon>
            <span class="ml-2">Dashboard</span></v-tab>

          <v-tab to="/viewmap"><v-icon small>mdi-map-marker-outline</v-icon>
            <span class="ml-2">Map View</span></v-tab>
          <v-tab to="/viewlist"><v-icon small>mdi-view-list</v-icon>
            <span class="ml-2">List View</span></v-tab>
        </v-tabs>

        <v-toolbar-title>
          <v-list-item two-line class="pr-2">
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{ userInfo.username }}</v-list-item-subtitle>
              <v-list-item-title>{{ userInfo.fullname }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-1" icon color="default" v-bind="attrs" v-on="on">
              <v-icon dark> mdi-account-outline </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-if="false">
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-icon>mdi-power </v-icon>
                <v-list-item-title class="ml-2" >Logout</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-btn v-if="false" class="mr-1" icon color="default" @click="rightDrawer = !rightDrawer">
          <v-icon dark>mdi-store-cog</v-icon>
        </v-btn>
      </v-app-bar>

      <v-app-bar app elevation="0" outlined dense v-if="isMobile()">

        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>Smart PJU
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-1" icon color="default" v-bind="attrs" v-on="on">
              <v-icon dark> mdi-account-outline </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-if="false">
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-icon>mdi-power </v-icon>
                <v-list-item-title class="ml-2" @click="logout">Logout</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-btn v-if="false" class="mr-1" icon color="default" @click="rightDrawer = !rightDrawer">
          <v-icon dark>mdi-store-cog</v-icon>
        </v-btn>
      </v-app-bar>

    </div>
    <v-main>
      <div class="pa-4 mt-2">
        <router-view></router-view>
      </div>
    </v-main>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>Telkom Antares</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
// import NavDrawer from "./NavDrawer";
// import DeviceDrawer from "./DeviceDrawer";
import FilterView from "./FilterView";
import SelectDevice from "./SelectDevice";
import SelectTenant from "./SelectTenant";
import xstore from '@/store/index.js'

export default {
  components: {
    // NavDrawer,
    FilterView,
    SelectDevice,
    SelectTenant,
    // DeviceDrawer,
  },
  data: () => ({
    drawer: null,
    drawerMode: 1,
    rightDrawer: false,
    selectedItem: '',
    queryFilter: {},
    xdeviceList: [
      {devicecode: "01", devicename: "Test"}
    ],
    // deviceList: [],
    userInfo: {}
  }),
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      }
      return false
    },
    onSelection(selection) {
      console.log("on-selection", selection);
      this.$store.dispatch("devices/selectDeviceItem", selection);
    },
    logout() {
      this.$store
        .dispatch("LOGOUT", {
          username: this.username,
          password: this.password,
        })
        .then((success) => {
          console.log("success", success);
          console.log("username", this.$store.getters.USERNAME);
          this.$router.push("/");
          location.reload();
        })
        .catch(function (err) {
          console.log(err.response);
          if (
            typeof err.response !== "undefined" &&
            typeof err.response.data !== "undefined" &&
            typeof err.response.data.message !== "undefined"
          ) {
            // alert(err.response.data.message);
            console.log("error", err.response.data.message);
          } else {
            // alert("Connection Error");
            console.log("error", err);
          }
        });
    },
    doSearch(data) {
      this.queryFilter = data
      this.$store.dispatch('devices/refreshDeviceTree', this.queryFilter)
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['devices/deviceTreeLoading']
    },
    deviceList() {
      let vitem = this.$store.getters["devices/deviceTree"];
      if (vitem && Object.keys(vitem).length) {
        if (typeof vitem.listDevices !== "undefined") {
          // return this.formatData(this.headers, vitem.listDevices)
          return vitem.listDevices;
        }
      }
      return [];
    },
    tenantList() {
      return [
        {
          tenantcode: "pjudemo",
          tenantname: "PJU Demo"
        },
        {
          tenantcode: "neyser_pju_demo",
          tenantname: "Dev PJU Demo"
        },
        {
          tenantcode: "solaren_pju_demo_3",
          tenantname: "PJU Demo II"
        }
      ]
    }

  },
  mounted() {
    // console.log('x-USERINFO', xstore.getters.USER_INFO)
    // let me = this
    // setInterval(function () {
    //   me.$store.dispatch('devices/refreshDeviceTree', me.queryFilter)
    // }, 30000)

    // setTimeout(function () {
    //   me.deviceList.push({devicecode: "02", devicename: "Test 2"})
    //   me.deviceList.push({devicecode: "03", devicename: "Test 3"})
    // }, 1000)

  },
  created() {
    this.userInfo = xstore.getters.USER_INFO
  }
};
</script>
