<template>
  <div class="small">
    <scatter :chart-data="datacollection" :chart-options="options"></scatter>
    <button @click="fillData()">Randomize</button>
  </div>
</template>

<script>
  import Scatter from '@/components/Scatter.js'

  export default {
    components: {
      Scatter
    },
    data () {
      return {
        datacollection: null,
        options: null
      }
    },
    mounted () {
      this.fillData()
    },
    methods: {
      fillData () {
          console.log("firing phiil");
        this.datacollection = {
            datasets: [{
            label: 'My First dataset',
            borderColor: 'rgba(47, 152, 208, 0.2)',
            backgroundColor: [
                                'rgba(47, 152, 208, 0.2)',
                            ],
            data: [{
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }]
        }, {
            label: 'My Second dataset',
            borderColor: 'rgba(0, 0, 208, 0.2)',
            backgroundColor: [
                                'rgba(47, 152, 208, 0.2)',
                            ],
            data: [{
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }, {
                x: randomScalingFactor(),
                y: randomScalingFactor(),
            }]
        }]
    }
        console.log(this.datacollection);
      }

}
  }

 function randomScalingFactor () {
        return Math.round(Math.random() * 200) - 100;
    }
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>