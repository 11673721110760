import Vue from 'vue'

import VueRouter from 'vue-router'
// import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import NotFound from "@/views/NotFound.vue";
import Layout from "@/components/Layouts/Basic"
import MobileLayout from "@/components/Layouts/SimpleMobile"

import Dashboard from "@/views/Dashboard.vue";
import MapView from "@/views/SmartLight/MapView.vue";
import ListView from "@/views/SmartLight/ListView.vue";
import Login from "@/views/Users/Login"

import DeviceInfo from "@/views/SmartLightMobile/DeviceInfo"

import TestChart from "@/views/Test/ChartTest"

import store from '../store/index.js'

Vue.use(VueRouter)


function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function checkToken(token) {
  if (token) {
    let jtoken = parseJwt(token)
    console.log('parsed token', jtoken)
    let vtime = Math.floor(Date.now() / 1000)
    // console.log('token-waktu sekarang', vtime)
    // console.log('token-check vtime, iat', vtime, jtoken.exp)
  
    if (vtime<jtoken.exp) {
      return true
    }
  
  }
  return false
}

function requireAuth (to, from, next) {

  // console.log('STORE GETTERS', store.getters)
  // var username = store.getters.USERNAME
  // let userinfo = store.getters['login/deviceTreeSelected']
  // console.log('USER INFO', userinfo)

  var userinfo = store.getters.USER_INFO
  // var userinfo = xuserinfo.data
  // var username = null

  // var userinfo = store.getters.USER_INFO
  var tokenvalid = false
  var username = null
  if (userinfo) {
    // username = userinfo.username
    tokenvalid = checkToken(userinfo.token)
    console.log('token-valid', tokenvalid)
  }
  console.log('USERINFO', userinfo)
  console.log('USERNAME', username)
  // next()
  // alert(username)
  if (tokenvalid) {
    next()
  } else {
    // next()
    next({
      path: '/login',
      // query: { redirect: to.fullPath }
    })  
  }
}

const routes = [
  {
    path: '/login/',
    name: 'login',
    component: Login
  },
  {
    path: '/login2',
    name: 'login2',
    component: Login
  },

  {
    path: "/devices/",
    name: "Home",
    component: MobileLayout,
    children: [
      {
        path: 'xinfo/:devicecode',
        name: 'home',
        beforeEnter: requireAuth,
        component: DeviceInfo
      },
      {
        path: 'info/:devicecode',
        name: 'info',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "device_info" */ "@/views/SmartLightMobile/DetailInfo")
      },
      {
        path: 'add/:devicecode',
        name: 'add',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "device_add" */ "@/views/SmartLightMobile/AddDevice")
      },
      {
        path: 'location/:devicecode',
        name: 'location',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "device_location" */ "@/views/SmartLightMobile/Location")
      },
      {
        path: 'maintenance/:devicecode',
        name: 'maintenance',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "device_maintenance" */ "@/views/SmartLightMobile/Maintenance")
      },
      {
        path: 'photo/:devicecode',
        name: 'photo',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "device_photo" */ "@/views/SmartLightMobile/Photograph")
      },
    ]
  },

  {
    path: "/",
    name: "Home",
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        beforeEnter: requireAuth,
        component: Dashboard
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        beforeEnter: requireAuth,
        component: Dashboard
      },
      {
        path: 'viewmap',
        name: 'viewmap',
        beforeEnter: requireAuth,
        component: MapView
      },
      {
        path: 'viewlist',
        name: 'viewlist',
        beforeEnter: requireAuth,
        component: ListView
      },
      {
        path: 'store_viewer',
        name: 'store_debugger',
        // beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "about" */ '../views/Tools/StoreDebugger.vue')
      },
      {
        path: 'chart_test',
        name: 'chart_test',
        // beforeEnter: requireAuth,
        component: TestChart
      },
      {
        path: 'about/:id1/:id2/:id3',
        name: 'about',
        // beforeEnter: requireAuth,
        component: About
      },
      
      // { path: '/user/:afterUser(.*)', component: UserGeneric }
    ]
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];

const router = new VueRouter({
  routes
})

export default router;