<template>
  <v-card color="grey">
    <v-alert border="left" colored-border :color="color" class="px-0 py-0 grey lighten-5">
      <div class="d-flex flex-no-wrap justify-space-between align-center" v-if="false">
        <v-avatar :color="color" small>
          <v-icon dark v-if="icon"> {{icon}} </v-icon>
          <span class="white--text text-h5" v-else-if="initial"> {{initial}} </span>
        </v-avatar>
        <v-card-text class="py-0 px-2">
          <p class="mb-0">
            <span :class="titleClass+' text--primary'" >{{title}}</span>
          </p>
        </v-card-text>
      </div>
      <v-list-item >
          <v-list-item-avatar>
          <v-icon
            :class="iconClass"
            dark
          >
            {{icon}}
          </v-icon>
        </v-list-item-avatar>


          <v-list-item-content class="py-1">
            <v-list-item-title :class="titleClass+' text--primary'" v-html="title"></v-list-item-title>
            <v-list-item-subtitle :class="subtitleClass+' text--primary'" v-html="subtitle"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>


    </v-alert>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    test: 1,
  }),
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    subtitle: {
      type: String,
      default: 'testing...'
    },
    desc: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: 'grey lighten-1'
    },
    initial: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'red'
    },
    titleClass: {
      type: String,
      default: 'text-h5'
    },
    subtitleClass: {
      type: String,
      default: 'subtitle-1'
    }



  }
};
</script>
<style scoped>
.ta-top {
  vertical-align: top;
}
</style>