<template>
  <data-card
    title="Device Detail"
    :header="deviceDetails"
    :data="singleDevice"
    :height="row1Height"
  ></data-card>
</template>
<script>

import DataCard from "@/components/Cards/DataCard";

export default {
  components: {
    DataCard
  },
  data: () => ({
    drawer: null,
    drawerMode: 1,
    rightDrawer: false,
    row1Height: 920,
    deviceDetails: [
      { field: "name", caption: "Device Name", icon: "mdi-cog" },
      { field: "devicecode", caption: "Device EUI", icon: "mdi-cog" },
      { field: "lamp_sn", caption: "Lamp SN", icon: "mdi-cog" },
      { field: "controller_sn", caption: "Controller SN", icon: "mdi-cog" },
      { field: "profilename", caption: "Device Type", icon: "mdi-cog"},
      { field: "projectname", caption: "Project Name", icon: "mdi-cog"},
      { field: "year", caption: "Year of Project", icon: "mdi-cog"},
      { field: "instansiname", caption: "Organization", icon: "mdi-cog"},
      { field: "lastdata", caption: "Last Data Time", icon: "mdi-cog"},
      // {
      //   field: "address",
      //   caption: "Address",
      //   icon: "mdi-map-marker",
      //   default: "-",
      // },
      { field: "energy", caption: "Energy", icon: "mdi-cog", unit: "kWh" },
      { field: "voltage", caption: "Voltage", icon: "mdi-cog", unit: "V" },
      { field: "current", caption: "Current", icon: "mdi-cog", unit: "A" },
      { field: "load", caption: "Load" },
      { field: "power", caption: "Power", icon: "mdi-cog", unit: "W" },
      { field: "powerfactor", caption: "Power Factor" },
      // { field: "lightsensor", caption: "Light Sensor" },
      // { field: "battery", caption: "Battery Level", unit: "%" },
      { field: "relaystatus", caption: "Relay Status" },
      { field: "brightness", caption: "Brightness" },
      { field: "temp_int", caption: "Internal Temp (°C)" },
      { field: "temp_ext", caption: "External Temp (°C)" },
      { field: "on_time", caption: "Lamp On Time (h)" },
      { field: "op_time", caption: "Operating Time (h)" },
    ],
  }),
  computed: {
    singleDevice() {
      let vitem = this.$store.getters["devices/singleDevice"];
      if (vitem && Object.keys(vitem).length) {
        return vitem;
      }
      return null;
    },
  }
}
</script>
