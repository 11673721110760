<template>
<div>
  <v-card color="grey lighten-4" class="pb-2">
    <v-row>
      <v-col cols="12">
        <map-card :height="row1Height" :markers="locations" title="GPS Locations" :center="centers">
        </map-card>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn
        primary
        color="secondary"
        @click="centerMode='gps'"
        >GPS</v-btn
      >
      <v-btn
        primary
        color="secondary"
        @click="centerMode='data'"
        >Device</v-btn
      >
      <v-btn
        primary
        color="primary"
        @click="dialog=true"
        >Update Device Location</v-btn
      >
    </v-card-actions>
  </v-card>
  <v-dialog max-width="800" v-model="dialog">
    <v-card class="py-4">
      <v-card-text>Apakah akan mengupdate lokasi device dengan lokasi GPS ?</v-card-text>
      <v-card-actions>
        <v-btn @click="saveGPSLocation">OK</v-btn>
        <v-btn @click="dialog=false">Cancel</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</div>
</template>
<script>
import MapCard from "@/components/Cards/MapCard";

export default {
  components: {
    MapCard
  },
  data: () => ({
    currentLocation: null,
    centerMode: 'data',
    dialog: false
  }),
  computed: {
    mapHeight () {
      return 600
    },
    locations () {
      let vresult = []
      if (this.dataLocation) {
        vresult.push(this.dataLocation)
      }
      if (this.currentLocation) {
        vresult.push(this.currentLocation)
      }
      return vresult
    },

    dataLocation() {
      let vitem = this.$store.getters["devices/singleDevice"];
      if (vitem && Object.keys(vitem).length) {
        return {longitude: vitem.longitude, latitude: vitem.latitude};
      }
      return null;
    },

    centers () {
      let vresult = [-6.901461, 107.618551]
      let vloc = this.currentLocation
      let vdata = this.dataLocation
      if (this.centerMode==='gps') {
        if (vloc) {
          vresult = [vloc.latitude, vloc.longitude]
        } else {
          if (vdata) {
            vresult = [vdata.latitude, vdata.longitude]
          }
        }
      } else {
        if (vdata) {
          vresult = [vdata.latitude, vdata.longitude]
        }
      }
      return vresult
    }
  },
  methods: {
    getCurrentLocations () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.updateLocations);
      } else {
        this.currentLocation = null
      }
    },
    updateLocations (position) {
      this.currentLocation = {
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
        icon: 'mdi-crosshairs-gps',
        color: 'purple--text'
      }
    },
    async saveGPSLocation () {
      console.log('save gps location')
      let vitem = this.$store.getters["devices/singleDevice"]
      let vloc = this.currentLocation
      if (vitem && vloc) {
        let payload = {
          devicecode: vitem.devicecode,
          longitude: vloc.longitude,
          latitude: vloc.latitude
        }
        await this.$store.dispatch('devices/updateDeviceLocation', payload)
        this.dialog = false
        await this.$store.dispatch("devices/getSingleDevice", {devicecode: vitem.devicecode});
        alert ("GPS Location Sent")
      } else {
        alert ("no GPS Location")
      }
    }
  },
  mounted () {
    this.getCurrentLocations()
  }
}
  
</script>
