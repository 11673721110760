<template>
<v-card >
  <v-list-item>
    <v-list-item-subtitle>
    <v-icon small>mdi-home</v-icon>
    <span class="ml-2">Dashboard</span>
    <span class="ml-2">></span>
    <span class="ml-2">{{title1}}</span>
    <span class="ml-2" v-if="title2">></span>
    <span class="ml-2">{{title2}}</span>
    </v-list-item-subtitle>
  </v-list-item>

  <v-card-text>
    <template v-if="singleDevice">
      <single-dashboard />
    </template>
    <template v-if="!singleDevice">
      <multi-dashboard />
    </template>

    <v-row>
      <v-col></v-col>
    </v-row>
  </v-card-text>
  </v-card>
  
</template>

<script>

import SingleDashboard from "./SmartLight/SingleDeviceDashboard.vue";
import MultiDashboard from "./SmartLight/MultiDeviceDashboard.vue";
export default {
  components: {
    SingleDashboard,
    MultiDashboard
  },
  data: () => ({ 
    text2: 'x',
    // singleDevice: false
  }),
  computed: {
    singleDevice () {
      let vitem = this.$store.getters['devices/deviceTreeSelected']
      console.log('vitem....')
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type==='device') {
          return true
        }
      }
      return false
    },
    title1 () {
      let vitem = this.$store.getters['devices/deviceTreeSelected']
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type==='device') {
          return 'Single Device'
        } else {
          return 'Multi Device'
        }
      } else {
        return 'All Devices'
      }
      //return 'test-1'
    },
    title2 () {
      let vitem = this.$store.getters['devices/deviceTreeSelected']
      console.log('tree-selected', vitem)
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type==='device') {
          return vitem.name
        } else if (vitem.name==='root') {
          return 'All Devices'
        } else {
          return vitem.caption+': '+vitem.name
        }
      } else {
        return ''
      }
      // return 'test-2'
    }

  },
  methods: {
    onSelection(selection) {
      console.log('on-selection', selection)
    }
  },
  mounted () {
  }
};
</script>
