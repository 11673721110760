import axios from "axios"
import Vue from 'vue'
import {calculateDeviceTree, extractDeviceListFromTree} from './deviceHelper.js'

const js_sleep = function(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  })
}

const extractErrorData = function (err) {
  if ((typeof err.response !== 'undefined') && (typeof err.response.data !== 'undefined')) {
    return err.response.data
  } else {
    return err
  }
}

const convertPayloadToQueryString = function (payload) {
  let vresult = ''
  if (payload) {
    let vkeys = Object.keys(payload)
    vkeys.forEach((vkey, vindex) => {
      if (vindex > 0) {
        vresult += '&'
      }
      vresult += vkey + '=' + payload[vkey]
    })
  }
  return vresult
}

const counter = {
  namespaced: true,
  state: () => ({
    deviceTree: {
      loaded: false,
      dataset: [],
      selected: null,
      isLoading: false,
    },
    history: {},
    daily: {},
    testing: {},
    singleDevice: {},
    photographList: []
  }),
  mutations: {
    loadDeviceTree (state, payload) {
      console.log('Load Device Tree', payload)
      let xChildren = payload.data
      let vRoot = payload
      vRoot.name = 'root'
      delete vRoot['data']
      vRoot.children = xChildren
      console.log('Root Device', vRoot)
      
      let searchId = null
      if (payload.searchId) {
        searchId = payload.searchId
      }
      let vresult = calculateDeviceTree(vRoot, searchId, payload?.filter?.status, payload?.filter?.sub_status)
      let xresult = vresult.result
      // if (searchId !== null && xresult.selected) {
      //   console.log('selected....', xresult.selected)
      // }
      console.log('vresult', vresult)
      console.log('xresult', xresult)
      Vue.set(state, 'deviceTree', {
        loaded: true,
        root: vRoot,
        selected: xresult.selected
      })
      Vue.set(state, 'any_dc_device', vresult.any_dc_device)
    },
    selectDeviceItem (state, payload) {
      Vue.set(state.deviceTree, 'selected', payload)
    },
    isLoading(state, mode) {
      Vue.set(state.deviceTree, 'isLoading', mode)
    },
    deviceHistory (state, payload) {
      let devcode = payload.devicecode
      let devdata = payload.data
      let tanggal = payload.tanggal
      let index = devcode+'#'+tanggal
      Vue.set(state.history, index, devdata)
    },
    deviceDaily (state, payload) {
      let devcode = payload.devicecode
      let devdata = payload.data
      let from = payload.from
      let to = payload.to
      let index = devcode+'#'+from+'#'+to
      Vue.set(state.daily, index, devdata)
    },
    deviceTesting (state, payload) {
      let devcode = payload.devicecode
      let devdata = payload.data
      // state.history[devcode] = devdata
      // console.log('device-history-mutation', devcode, devdata)
      Vue.set(state.testing, devcode, devdata)
    },
    singleDevice (state, payload) {
      console.log('mutation single device', payload)
      Vue.set(state, 'singleDevice', payload)
    },
    photographList (state, payload) {
      console.log('mutation photograph list', payload)
      Vue.set(state, 'photographList', payload)
    },
  },
  actions: {
    
    async loadDeviceTree (context, payload) {
      console.log('channel Axios payload', payload)
      var data = await axios.get ('spju/devicetree')
      let vresult = data.data
      console.log('received data', data.data)
      context.commit('loadDeviceTree', vresult)
    },
    async refreshDeviceTree (context, payload) {
      // convert payload to query string
          
      context.commit('isLoading', true)
      console.log('channel Axios payload', payload)
      let xUrl = 'spju/devicetree'
      let xQueryString = convertPayloadToQueryString(payload)
      if (xQueryString) {
        xUrl += '?' + xQueryString
      }
      var data = await axios.get (xUrl, payload)
      let vresult = data.data
      vresult.filter = payload
      console.log('received data', data.data)
      if (typeof context.state !== 'undefined' && typeof context.state.deviceTree !== 'undefined' &&
        context.state.deviceTree.selected ) {
        vresult.searchId = context.state.deviceTree.selected.id
      }
      await js_sleep(100)
      
      context.commit('loadDeviceTree', vresult, payload)
      context.commit('isLoading', false)
      // console.log(context, payload)
      console.log('Selected', context.state)
      // context.commit('selectDeviceItem', payload)
    },
    selectDeviceItem (context, payload) {
      context.commit('selectDeviceItem', payload)
    },
    async setRelay (context, payload){
      try {
        const res = await axios.post(`spju/set/relay/${payload.devicecode}`, payload)
        console.log("setRelay", context, payload)
        return res.data          
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async setBrightness (context, payload){
      try {
        const res = await axios.post(`spju/set/brightness/${payload.devicecode}`, payload)
        console.log("setBrightness", context, payload)
        return res.data
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async setKwhRate (context, payload){
      try {
        const res = await axios.post(`spju/set/kwhrate/${payload.devicecode}`, payload)
        console.log("setKwhRate", context, payload)
        return res.data          
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async requestCurrentData (context, payload){
      try {
        const res = await axios.post(`spju/request/currentdata/${payload.devicecode}`, payload)
        console.log("setKwhRate", context, payload)
        return res.data
          
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async updateMaintenance (context, payload){
      try {
        const res = await axios.post(`spju/update/maintenance/${payload.devicecode}`, payload)
        console.log("updateMaintenance", context, payload)
        return res.data
          
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async setSchedule (context, payload){
      try {
        const res = await axios.post(`spju/set/schedule/${payload.devicecode}`, payload)
        console.log("set Schedule", context, payload)
        return res.data
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async setTimeSync (context, payload){
      try {
        const res = await axios.post(`spju/set/timesync/${payload.devicecode}`, payload)
        console.log("set Schedule", context, payload)
        return res.data
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async setTotalisator (context, payload){
      try {
        const res = await axios.post(`spju/set/totalisator/${payload.devicecode}`, payload)
        console.log("setTotalisator", context, payload)
        return res.data          
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async setSendInterval (context, payload){
      try {
        const res = await axios.post(`spju/set/sendinterval/${payload.devicecode}`, payload)
        console.log("setSendInterval", context, payload)
        return res.data          
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async setCalibrationTime (context, payload){
      try {
        const res = await axios.post(`spju/set/calibration/time/${payload.devicecode}`, payload)
        console.log("setCalibrationTime", context, payload)
        return res.data          
      } catch (error) {
        return extractErrorData(error)
      }
    },
    // getDriverModel getDeviceConfig setOnOffThreshold
    async getDriverModel (context, payload){
      try {
        const res = await axios.post(`spju/request/fw_version/${payload.devicecode}`, payload)
        console.log("getDriverModel", context, payload)
        return res.data          
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async getDeviceConfig (context, payload){
      try {
        const res = await axios.post(`spju/request/configuration/${payload.devicecode}`, payload)
        console.log("getDeviceConfig", context, payload)
        return res.data          
      } catch (error) {
        return extractErrorData(error)
      }
    },
    async setOnOffThreshold (context, payload){
      try {
        const res = await axios.post(`spju/set/threshold/${payload.devicecode}`, payload)
        console.log("setOnOffThreshold", context, payload)
        return res.data          
      } catch (error) {
        return extractErrorData(error)
      }
    },

    async setDimSchedule (context, payload){
      try {
        const res = await axios.post(`spju/set/dimschedule/${payload.devicecode}`, payload)
        console.log("setDimSchedule", context, payload)
        return res.data          
      } catch (error) {
        return extractErrorData(error)
      }
    },

    async getDeviceHistory (context, payload) {
      console.log('channel Axios payload', payload)
      var data = await axios.get(`spju/device/history/${payload.devicecode}?tanggal=${payload.tanggal}`)
      let vresult = data.data
      console.log('received data', vresult)
      let values = {
        devicecode: payload.devicecode,
        tanggal: payload.tanggal,
        data: vresult.data
      }
      context.commit('deviceHistory', values)
    },
    async getDeviceDaily (context, payload) {
      console.log('channel Axios payload', payload)
      var data = await axios.get(`spju/device/daily/${payload.devicecode}?from=${payload.from}&to=${payload.to}`)
      let vresult = data.data
      console.log('received data', vresult)
      let values = {
        devicecode: payload.devicecode,
        from: payload.from,
        to: payload.to,
        data: vresult.data
      }
      context.commit('deviceDaily', values)
    },
    async updateDeviceTesting(context, payload) {
      console.log('testing....', payload)
      let values = {
        devicecode: payload.devicecode,
        data: 'testing - '+Math.random()
      }
      context.commit('deviceTesting', values)

    },
    // async getDeviceHistory (context, payload){
    //   const res = await axios.post(`spju/set/calibration/time/${payload.devicecode}`)
    //   console.log("setCalibrationTime", context, payload)
    //   return res.data
    // },

    async getSingleDevice(context, payload) {
      console.log('channel Axios payload', payload)
      let vresult = {}
      try {
        var data = await axios.get(`spju/singledevice/${payload.devicecode}`)
        console.log('get single device data', data)
        vresult = data.data.data
        if (!vresult) {
          vresult = {}
        }
          
      } catch (error) {
        vresult = {}
      }
      context.commit('singleDevice', vresult)

    },
    async getPhotographList(context, payload) {
      console.log('photograph list Axios payload', payload)
      let vresult = []
      try {
        var axres = await axios.get(`spju/imagelist/${payload.devicecode}`)
        console.log('get single device data', axres)
        vresult = axres.data.data
        if (!vresult) {
          vresult = []
        }
          
      } catch (error) {
        vresult = []
      }
      context.commit('photographList', vresult)

    },
    async uploadPhoto (context, payload) {
      let result= await axios.post(`spju/upload/${payload.devicecode}`, payload.formData, payload.options)


      // var axres = await axios.get(`spju/imagelist/${payload.devicecode}`)
      // console.log('get single device data', axres)
      // vresult = axres.data.data
      return result

    },
    async updateDeviceLocation (context, payload) {
      const res = await axios.post(`spju/updatelocation/${payload.devicecode}`, payload)
      console.log("setBrightness", context, payload)
      return res.data
    }

  },

  // getTodoById: (state) => (id) => {
  //   return state.todos.find(todo => todo.id === id)
  // }
  getters: {
    anyDcDevice(state) {
      // let result = getAnyDcDevice()
      // console.log('sss anyDcDevice', result)
      return state.any_dc_device
    },
    deviceTree (state) {
      return state.deviceTree.root
    },
    deviceTreeLoaded (state) {
      return state.deviceTree.loaded
    },
    deviceTreeLoading (state) {
      return state.deviceTree.isLoading
    },
    deviceTreeSelected (state) {
      let selected = state.deviceTree.selected
      if (selected && Object.entries(selected).length>0) {
        return selected
      }
      return state.deviceTree.root
    },
    deviceTreeSelectedList (state) {
      let selected = state.deviceTree.selected
      if (!(selected && Object.entries(selected).length>0)) {
        selected = state.deviceTree.root
      }
      let vlist = extractDeviceListFromTree(selected)
      return vlist

    },
    deviceHistoryLoaded: (state) => (devcode, tanggal) => {
      let index = devcode+'#'+tanggal
      if (typeof state.history[index]!=='undefined') {
        return true
      }
      return false
    },
    deviceHistory: (state) => (devcode, tanggal) => {
      let index = devcode+'#'+tanggal
      if (typeof state.history[index]!=='undefined') {
        return state.history[index]
      }
      return undefined
    },
    deviceDailyLoaded: (state) => (devcode, from, to) => {
      let index = devcode+'#'+from+'#'+to
      if (typeof state.daily[index]!=='undefined') {
        return true
      }
      return false
    },
    deviceDaily: (state) => (devcode, from, to) => {
      console.log('device-daily')
      let index = devcode+'#'+from+'#'+to
      if (typeof state.daily[index]!=='undefined') {
        return state.daily[index]
      }
      return undefined
    },
    deviceTesting: (state) => (devcode) => {
      if (typeof state.testing[devcode]!=='undefined') {
        return state.testing[devcode]
      }
      return undefined
    },
    singleDevice (state) {
      console.log('state', state)
      return state.singleDevice
    },
    photographList (state) {
      return state.photographList
    }


  }
}

export default counter