<template>
  <div>
    <v-row v-if="false">
      <v-col cols="12">
        {{ userInfo }}xxx
        {{ commands }}
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="12">
        {{ typelist }}
        {{ commands }}
        {{ 'deviceList' }}


      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="12">
        {{ deviceData }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        :lg="item.width"
        v-for="(item, i) in numberDefs"
        :key="i"
      >
        <number-view
          :title="item.title"
          :xnumber="deviceData[item.field] ? `${deviceData[item.field]}` : '0'"
          :number="getValueNumber(item)"
          :unit="item.unit"
          :desc="item.desc"
          :icon="item.icon"
          :initial="item.initial"
          :color="item.color ? item.color : 'red'"
          v-if="(any_dc_device && !item.dc_hide) || !any_dc_device"
        ></number-view>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <map-card :height="row1Height" title="Location" :markers="deviceList">
        </map-card>
      </v-col>

      <v-col cols="3" v-if="userInfo.role_name!=='guest'">
        <data-card title="Control Group" :height="row1Height">
          <v-list-item v-if="!commands || commands.length==0">
            <v-list-item-avatar>
              <v-icon>mdi-checkbox-blank-off-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                No Realtime Control for this device
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <template v-for="item in commands" >
          <v-list-item :key="item" v-if="(any_dc_device && !commandDefs[item].dc_hide) || !any_dc_device">
            <v-list-item-avatar>
              <v-icon
                :color="
                  commandDefs[item].color ? commandDefs[item].color : 'blue'
                "
                light
                >{{ commandDefs[item].icon }}</v-icon
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{
                commandDefs[item].caption
              }}</v-list-item-title>

              <v-list-item-subtitle>{{
                commandDefs[item].desc
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="openDialog(item)">
                <v-icon color="grey darken-1"
                  >mdi-dots-horizontal-circle-outline</v-icon
                >
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          </template>
        </data-card>
      </v-col>
      <v-col :cols="userInfo.role_name!=='guest'?5:8">
        <v-card color="grey lighten-4" class="pb-0">
          <v-list-item class="grey lighten-3">
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Device List
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="deviceListTable"
            :items-per-page="8"
            :footer-props="{'items-per-page-options':[5, 8], 'disable-items-per-page':true}"
            class="elevation-1"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>

<v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog max-width="600" v-model="dialog" :style="{ zindex: 10001 }">
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark
                v-if="!controlProgress"
                >{{ dialogOpt.title }} to {{deviceData['count']}} devices</v-toolbar
              >
              <v-toolbar color="primary" dark
                v-if="controlProgress"
                >{{ dialogOpt.title }} {{controlCount}} of {{deviceData['count']}} devices</v-toolbar
              >
              <v-card-text>
                <template v-if="dialogName == 'relaystatus'">
                  <v-switch
                    v-model="dialogOpt.relaystatus"
                    :label="`Send switch command: ${
                      dialogOpt.relaystatus ? 'ON' : 'OFF'
                    }`"
                  ></v-switch>
                  <v-textarea
                    name="relaystatus-text"
                    label="Notes"
                    hint="Type notes on send switch command"
                    rows="2"
                    v-model="dialogOpt.relayNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'totalisator'">
                  <v-text-field
                    label="Energy Meter (kWh)"
                    hide-details="auto"
                    v-model="dialogOpt.energyMeter"
                  ></v-text-field>
                  <v-textarea
                    name="totalisator-text"
                    label="Notes"
                    hint="Type notes on reset Energy Meter"
                    rows="2"
                    v-model="dialogOpt.totalisatorNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'brightness'">
                  <v-row >
                    <v-col cols="6" >
                  <v-text-field
                    label="Brightness"
                    hide-details="auto"
                    v-model="dialogOpt.brightness"
                  ></v-text-field>
                  </v-col>
                  <v-col cols="6" >
                    <v-slider
                      v-model="dialogOpt.brightness"
                      class="mt-4"
                    ></v-slider>
                  </v-col>
                  </v-row>
                  <v-textarea
                    name="brightness-text"
                    label="Notes"
                    hint="Type notes on reset Brightness"
                    rows="2"
                    v-model="dialogOpt.brightnessNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'schedule'">
                  <v-text-field
                    label="Time ON (HH:MM)"
                    hide-details="auto"
                    v-model="dialogOpt.timeOn"
                  ></v-text-field>
                  <v-text-field
                    label="Time OFF (HH:MM)"
                    hide-details="auto"
                    v-model="dialogOpt.timeOff"
                  ></v-text-field>
                  <v-textarea
                    name="schedule-text"
                    label="Notes"
                    hint="Type notes on reset Schedule"
                    rows="2"
                    v-model="dialogOpt.scheduleNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'timesync'">
                  <v-switch
                    v-model="dialogOpt.timesyncStatus"
                    :label="`Synchronize Time: ${
                      dialogOpt.timesyncStatus ? 'ON' : 'OFF'
                    }`"
                  ></v-switch>
                  <v-textarea
                    name="relaystatus-text"
                    label="Notes"
                    hint="Type notes on Time Synchronize"
                    rows="2"
                    v-model="dialogOpt.timesyncNotes"
                  ></v-textarea>
                </template>

                <template v-if="dialogName == 'newdimschedule'">
                  <v-row v-for="(item, i) in dimIndex" :key="i">
                    <v-col cols="3" :key="i">
                      <v-text-field
                        cols = "4"
                        :label="item==='M'?'Main Start (MS)':'Schedule '+item+' Start ('+item+'S)'"
                        hide-details="auto"
                        v-model="dialogOpt.dimSchedule[item.toLowerCase()+'s']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :key="item">
                      <v-text-field
                        cols = "4"
                        :label="item==='M'?'Main End (ME)':'Schedule '+item+' End ('+item+'E)'"
                        hide-details="auto"
                        v-model="dialogOpt.dimSchedule[item.toLowerCase()+'e']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :key="item">
                      <v-text-field
                        cols = "4"
                        :label="item==='M'?'Main Level (ML)':'Schedule '+item+' Level ('+item+'L)'"
                        hide-details="auto"
                        v-model="dialogOpt.dimSchedule[item.toLowerCase()+'l']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :key="item">
                      <v-slider
                        v-model="dialogOpt.dimSchedule[item.toLowerCase()+'l']"
                        class="mt-4"
                      ></v-slider>
                    </v-col>

                  </v-row>

                </template>
                <template v-if="dialogName == 'kwh_rate'">
                  <v-text-field
                    label="KWH Rate"
                    hide-details="auto"
                    v-model="dialogOpt.kwhRate"
                  ></v-text-field>
                </template>
                <template v-if="dialogName == 'currentdata'">
                  <v-card-subtitle>Request Current Data of {{deviceData['name']}}</v-card-subtitle>
                </template>
                <template v-if="dialogName == 'maintenance'">
                  <v-switch
                    v-model="dialogOpt.maintenance"
                    :label="`Update Maintenance Status`"
                  ></v-switch>
                  <v-textarea
                    name="maintenance-text"
                    label="Notes"
                    hint="Type notes on update Maintenance Status"
                    rows="2"
                    v-model="dialogOpt.maintenanceNotes"
                  ></v-textarea>
                </template>

              </v-card-text>
              <v-card-actions class="justify-end">
                <v-progress-circular
                  v-if="controlLoading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-btn
                  text
                  primary
                  @click="json_dialog = true"
                  v-if="!controlLoading && dialogName=='newdimschedule'"
                  >JSON</v-btn>
                <v-btn text @click="dialog.value = false" v-if="!controlLoading">Cancel</v-btn>
                <!-- <v-btn primary @click="dialog.value = false">Submit</v-btn> -->
                <v-btn
                  primary
                  @click="processRealtimeControl(dialogName, dialogOpt)"
                  v-if="!controlLoading"
                  >Submit</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>

    <v-dialog v-model="json_dialog" max-width="800" height="800">
      <v-card>
        <json-editor color="white" v-model="dialogOpt.dimSchedule" mode="code" :show-btns="false"></json-editor>
        <v-card-actions class="justify-end">
          <v-btn
                  text
                  primary
                  @click="json_dialog = false"
                  v-if="!controlLoading && dialogName=='newdimschedule'"
                  >Close</v-btn>
        </v-card-actions>
      </v-card>

      
    </v-dialog>

    <v-row v-if="false">
      <v-col cols="6">
        <v-card class="cyan darken-2" height="300"></v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="cyan darken-2" height="300"></v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NumberView from "@/components/Cards/NumberView";
import MapCard from "@/components/Cards/MapCard";
import DataCard from "@/components/Cards/DataCard";
import JsonEditor from "vue-json-editor";

import xstore from '@/store/index.js'

const js_sleep = function(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  })
}

export default {
  components: {
    NumberView,
    MapCard,
    DataCard,
    JsonEditor
  },
  data: () => ({
    text2: "x",
    dialog: false,
    userInfo: {},
    json_dialog: false,
    // singleDevice: false
    controlSuccess: 0,
    controlFail: 0,
    controlMessages: [],
    controlCount: 0,
    counterLabel: '', 
    controlProgress: false,
    dimIndex: ['M', 'A', 'B', 'C', 'D'],
    dialogOpt: {
      title: "-",
      relaystatus: false,
      dimSchedule: {
        ms: '',
        me: '',
        ml: '',
        as: '',
        ae: '',
        al: '',
        bs: '',
        be: '',
        bl: '',
        cs: '',
        ce: '',
        cl: '',
        ds: '',
        de: '',
        dl: ''
      }
    },
    row1Height: 540,
    removeDefs: {
      "schedule": "newdimschedule" // bila ada dimschedule, maka schedule dihilangkan
    },
    commandDelays: {
      "default": 1000,
      "relaystatus": 5000, 
      "brightness": 5000,
      "currentdata": 500,
      "kwh_rate": 500,
      "maintenance": 500,
      "timesync": 5000,
      "schedule": 5000,
      "newdimschedule": 5000
    },
    commandDelayDevice: {
      "pju-avnet2": {
        "relaystatus": 5000, 
        "brightness": 5000,
        "currentdata": 500,
        "schedule": 10000,
        "newdimschedule": 10000
      }
    },
    typeDefs: {
      'default': ["relaystatus", "brightness", "currentdata", "kwh_rate", "maintenance", "schedule"],
      'pju-dc': ["relaystatus", "brightness", "currentdata", "schedule"],
      'pju-icode': ["relaystatus", "brightness", "currentdata", "kwh_rate", "maintenance", "timesync", "schedule"],
      'pju-avnet': ["relaystatus", "brightness", "currentdata", "kwh_rate", "maintenance", "timesync", "schedule"],
      'pju-avnet2': ["relaystatus", "brightness", "currentdata", "kwh_rate", "maintenance", "timesync", "schedule", "newdimschedule"]
    },
    // commands: ["relaystatus", "brightness", "currentdata", "kwh_rate", "maintenance", "schedule"],
    // commands: ["relaystatus", "totalisator", "brightness", "schedule", "kwh_rate"],
    commandDefs: {
      relaystatus: {
        caption: "Relay Status",
        desc: "Set Relay ON/OFF",
        title: "Set Relay ON/OFF",
        icon: "mdi-lightbulb-on-outline",
        color: "red",
      },
      totalisator: {
        caption: "Totalisator",
        desc: "Reset Energy Meter",
        title: "Reset Energy Meter",
        icon: "mdi-lightning-bolt-circle",
        color: "blue",
      },
      brightness: {
        caption: "Brightness",
        desc: "Set Lamp Brightness",
        title: "Set Lamp Brightness (10 - 100)",
        icon: "mdi-brightness-6",
        color: "yellow darken-2",
      },
      schedule: {
        caption: "Schedule",
        desc: "Set ON/OFF Schedule",
        title: "Set ON/OFF Schedule",
        icon: "mdi-calendar-clock",
        color: "purple",
      },
      newdimschedule: {
        caption: "Dimming Schedule",
        desc: "Update Dimming Schedule",
        title: "Update Dimming Schedule",
        icon: "mdi-calendar-clock",
        color: "purple",
      },
      timesync: {
        caption: "Time Synchronization Status",
        desc: "Set Synchronization ON/OFF",
        title: "Set Synchronization ON/OFF",
        icon: "mdi-timer-sync-outline",
        color: "blue darken-3",
      },
      currentdata: {
        caption: "Request Current Data",
        desc: "Request Current Data",
        title: "Rerqeust Current Data",
        icon: "mdi-lightbulb-on-outline",
        color: "green",
      },
      kwh_rate: {
        caption: "Setting KW Rate",
        desc: "Setting KWH Rate",
        title: "Setting KWH Rate",
        icon: "mdi-tag-multiple-outline",
        dc_hide: true,
        color: "green",
      },
      maintenance: {
        caption: "Maintenance Status",
        desc: "Update Maintenance Status",
        title: "Update Maintenance Status",
        icon: "mdi-cog-outline",
        dc_hide: true,
        color: "grey darken-3",
      },
    },
    openDialog (vname) {
      console.log("xvname", vname);
      this.dialogName = vname;
      if (typeof this.commandDefs[vname] !== "undefined") {
        this.dialogOpt.title = this.commandDefs[vname].title;
      } else {
        this.dialogOpt.title = vname;
      }
      this.dialog = true;
      this.controlLoading = false
    },
    numberDefs: [
      {
        field: "count",
        title: "Total Device",
        unit: "",
        icon: "mdi-ceiling-light-multiple-outline",
        color: "light-blue darken-2",
        width: "3",
      },
      {
        field: "percentOn",
        title: "% Device ON",
        unit: "%",
        icon: "mdi-lightbulb-on",
        color: "orange darken-1",
        width: "3",
        decimal: 2
      },
      {
        field: "countOn",
        title: "Device ON",
        unit: "",
        icon: "mdi-lightbulb-on",
        color: "orange darken-1",
        width: "3",
      },
      {
        field: "countOff",
        title: "Device OFF",
        unit: "",
        icon: "mdi-lightbulb-off-outline",
        color: "grey darken-3",
        width: "3",
      },
      {
        field: "countOffline",
        title: "Device Offline",
        unit: "",
        icon: "mdi-network-off",
        color: "grey darken-1",
        width: "3",
      },
      {
        field: "countError",
        title: "Warning",
        unit: "",
        icon: "mdi-alert-decagram",
        color: "orange lighten-3",
        width: "3",
      },
      {
        field: "countMaint",
        title: "Maintenance",
        unit: "",
        icon: "mdi-cog",
        color: "grey darken-1",
        width: "3",
      },
      {
        field: "totalPower",
        title: "Total Power",
        unit: "W",
        initial: "W",
        color: "deep-purple darken-2",
        width: "3",
        decimal: 2,
        dc_hide: true
      },
      {
        field: "averageVoltage",
        title: "Average Voltage",
        unit: "V",
        initial: "V",
        color: "teal lighten-1",
        width: "3",
        decimal: 2,
        dc_hide: true
      },
      {
        field: "totalCurrent",
        title: "Total Current",
        unit: "A",
        initial: "I",
        color: "green darken-3",
        width: "3",
        decimal: 2,
        dc_hide: true
      },
      // {
      //   field: "avgPowerFactor",
      //   title: "Average Power Factor",
      //   unit: "",
      //   icon: "mdi-lightning-bolt-circle",
      //   color: "pink darken-1",
      // },
    ],
    headers: [
      {
        text: "Device Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Power",
        align: "start",
        sortable: false,
        value: "power",
        decimal: 2
      },
      {
        text: "Voltage",
        align: "start",
        sortable: false,
        value: "voltage",
        decimal: 2
      },
      {
        text: "Last Data Time",
        align: "start",
        sortable: false,
        value: "lastdata"
      },
      {
        text: "Signal Strength",
        align: "start",
        sortable: false,
        value: "rssi_strength"
      },
    ],
  }),
  computed: {
    any_dc_device() {
      let value = this.$store.getters["devices/anyDcDevice"]
      return value
    },
    typelist () {
      // let names = {}
      let names = {}
      this.deviceList.forEach((item) => {
        names[item.devicetype] = (names[item.devicetype] || 0) +1
      })
      return Object.keys(names)
      // return names
    },
    commands () {
      if (this.userInfo.role_name !== 'guest') {
        let vlist = this.typelist
        // let vresult = this.typeDefs['default']
        let vresult = null
        console.log('command-list', vlist)
        vlist.forEach((item) => {
          console.log('command-foreach', item, this.typeDefs[item])
          if (vresult === null) {
            vresult = this.typeDefs[item]
            if (typeof vresult === 'undefined') {
              vresult = this.typeDefs['default']
            }
            console.log('command-foreach-1', vresult)
          } else {
            let vcheck = this.typeDefs['default']
            if (typeof this.typeDefs[item] !== 'undefined') {
              vcheck = this.typeDefs[item]
            }
            vresult = this.intersect(vresult, vcheck) 
          }
        })
        if (vresult === null) {
          vresult = []
        }
        vresult = this.removeDuplicates(vresult)
        return vresult
        // return this.getCommands()
        // return ["relaystatus", "brightness", "currentdata", "kwh_rate", "maintenance", "schedule"]
      } 
      return []
    },
    deviceSelectedList() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      return vitem
    },
    deviceData() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      if (vitem && typeof vitem.info !== "undefined") {
        let xinfo = {
          id: vitem.id,
          name: vitem.name,
          caption: vitem.caption,
          type: vitem.type,
        };
        let result = {
          ...xinfo,
          ...vitem.info,
        };
        console.log('device Data Items', vitem)
        return result;
      } else {
        console.log("deviceData blank");
        return {};
      }
      // return vitem
      // if (vitem && Object.keys(vitem).length) {
      //   if (vitem.type === "device") {
      //     return vitem;
      //   }
      // }
      // return {};
    },
    deviceListTable () {
      let result = this.deviceList
      return this.formatData(this.headers, result)
    },
    deviceList() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      if (vitem && Object.keys(vitem).length) {
        if (typeof vitem.listDevices !== "undefined") {
          // return this.formatData(this.headers, vitem.listDevices)
          return vitem.listDevices;
        }
      }
      return [];
    },
    singleDevice() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type === "device") {
          return true;
        }
      }
      return false;
    },
    title1() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type === "device") {
          return "Single Device";
        } else {
          return "Multi Device";
        }
      } else {
        return "All Devices";
      }
      //return 'test-1'
    },
    title2() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      console.log(vitem);
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type === "device") {
          return vitem.name;
        } else {
          return vitem.caption + ": " + vitem.name;
        }
      } else {
        return "";
      }
      // return 'test-2'
    },
  },
  methods: {
    getDelay(vdevicetype, vcommand) {
      let vresult = this.commandDelays['default']
      if (this.commandDelays[vcommand]) {
        vresult = this.commandDelays[vcommand]
      }
      if (typeof this.commandDelayDevice[vdevicetype] !== 'undefined') {
        if (typeof this.commandDelayDevice[vdevicetype][vcommand] !== 'undefined') {
          vresult = this.commandDelayDevice[vdevicetype][vcommand]
        }
      }
      return vresult
    },
    removeDuplicates (array1 = []) {
      // return array1
      // tidak menampilkan item yang item this.removeDefs[items] ada dalam list array1

      let result = array1.filter((item) => {
        if (typeof this.removeDefs[item] !== 'undefined') {
          let vcheck = this.removeDefs[item]
          console.log('vcheck', item, vcheck, array1.indexOf(vcheck), array1)
          return array1.indexOf(vcheck) === -1
        }
        return true
        // return typeof this.removeDefs[item] === 'undefined'
      })
      return result


      // hilangkan item dalam array1 yang ditunjuk oleh item yang ada di thsi.removeDefs
    //   let result = array1.filter((item) => {
    //     if (typeof this.removeDefs[item] !== 'undefined') {
    //       let vcheck = this.removeDefs[item]
    //       return typeof array1[vcheck] === 'undefined'
    //     }
    //     return true
    //     // return typeof this.removeDefs[item] === 'undefined'
    //   })
    //   return result
    },
    intersect(array1 = [], array2 = []) {
      console.log('intersect', array1, array2)
      return array1.filter(value => array2.includes(value))
    },
    listCommands() {
      console.log("listCommands");
    },
    anyDcDevice() {
      console.log('sssss any dc device', this.$store.getters["devices/anyDcDevice"])
      return this.$store.getters["devices/anyDcDevice"]
    },
    formatData(vformat, vdata) {
      let result = []
      for (let i=0; i<vdata.length; i++) {
        let row = {}
        let xrow = vdata[i]
        for (let j=0; j<vformat.length; j++) {
          let xformat = vformat[j]
          let xfield = xformat['value']
          if (typeof xrow[xfield] !== 'undefined') {
            if (typeof xformat['decimal'] !== 'undefined') {
              row[xfield] = Number(xrow[xfield]).toFixed(xformat['decimal'])
            } else {
              row[xfield] = xrow[xfield]
            }

          }
        }
        result.push(row)
      }
      return result
    },
    getValueNumber(item) {
      let result = '0'
      if (typeof item.field !== 'undefined') {
        if (typeof this.deviceData[item.field] !== 'undefined') {
          if (typeof item.decimal !=='undefined') {
            result = Number(this.deviceData[item.field]).toFixed(item.decimal)
          } else {
            result = this.deviceData[item.field]
          }
        }
      }
      return result
    },
    onSelection(selection) {
      console.log("on-selection", selection);
    },
    async controlSingle(dialogName, dialogOpt, deviceCode) {
      console.log('controlSingle', dialogName)
      switch (dialogName) {
        case "relaystatus":
          await this.controlRelay(
            deviceCode,
            dialogOpt.relaystatus,
            dialogOpt.relayNotes
          );
          break;
        case "currentdata":
          this.requestCurrentData(
            deviceCode
          );
          break;
        case "kwh_rate":
          await this.controlKwhRate(
            deviceCode,
            dialogOpt.kwhRate
          );
          break;
        case "maintenance":
          await this.updateMaintenance(
            deviceCode,
            dialogOpt.maintenance,
            dialogOpt.maintenanceNotes
          );
          break;
        case "schedule":
          this.controlSchedule(
            deviceCode,
            dialogOpt.timeOn,
            dialogOpt.timeOff,
            dialogOpt.relayNotes
          );
          break;
        case "newdimschedule":
          this.updateDimSchedule(
            deviceCode,
            dialogOpt.dimSchedule
          );
          break;
        case "brightness": 
          await this.controlBrightness(
            deviceCode,
            dialogOpt.brightness,
            dialogOpt.relayNotes
          );
      }
      
    },
    resetControlStatus() {
        this.controlSuccess = 0
        this.controlFail = 0
        this.controlMessages = []
        this.controlCount = 0
        this.counterLabel = ''
        
    },
    async processRealtimeControl(dialogName, dialogOpt) {
      this.resetControlStatus()
      this.controlProgress = true
      console.log("prc", dialogName, dialogOpt);
      console.log('COUNT', this.deviceData['count'])
      console.log('Data', this.deviceData)
      let vlist = this.$store.getters["devices/deviceTreeSelectedList"];
      console.log('device-list', vlist)
      let vdelay = 200
      if (vlist) {
        for (let i=0; i<vlist.length; i++) {
          let vitem = vlist[i]
          try {
            vdelay = this.getDelay(vitem.devicetype, dialogName)
            this.counterLabel = `Processing ${i+1} of ${vlist.length} devices`
            await this.controlSingle(dialogName, dialogOpt, vitem.devicecode)
          } catch (error) {
            console.log(error)
          }
          await js_sleep(vdelay)
          this.controlCount = this.controlCount+1
        }
      }
      this.dialog = false;
      this.controlProgress = false

    },


    async controlRelay(devicecode, state, notes) {
      // console.log('control-Relay', devicecode)
      // return

      console.log(devicecode, state, notes);
      this.controlLoading = true
      const payload = {
        devicecode,
        state,
        notes,
      };
      const { code, message } = await this.$store.dispatch(
        "devices/setRelay",
        payload
      );
      // console.log("responseControlRelay", res);
      this.controlLoading = false
      if (code == 200) {
        this.controlSuccess = this.controlSuccess+1
        // alert(data.message);
      } else {
        this.controlMessages.push(message)
        // alert(message);
      }
      // this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async controlBrightness(devicecode, brightness, notes) {
      this.controlLoading = true
      console.log(devicecode, brightness, notes);
      const payload = {
        devicecode,
        brightness,
        notes,
      };
      const { code, message } = await this.$store.dispatch(
        "devices/setBrightness",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        this.controlSuccess = this.controlSuccess+1
        // alert(data.message);
      } else {
        this.controlFail = this.controlFail+1
        this.controlMessages.push(message)
        // alert(message);
      }
      // this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async controlSchedule(devicecode, timeOn, timeOff, notes) {
      this.controlLoading = true
      console.log(devicecode, timeOn, timeOff, notes);
      const payload = {
        devicecode,
        timeOn,
        timeOff,
        notes,
      };
      const { code, message } = await this.$store.dispatch(
        "devices/setSchedule",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        this.controlSuccess = this.controlSuccess+1
        // alert(data.message);
      } else {
        this.controlFail = this.controlFail+1
        this.controlMessages.push(message)
        // alert(message);
      }
      // this.dialog = false;
      // delete this.dialogOpt.relayNotes;
    },
    async updateDimSchedule(devicecode, dimSchedule) {
      this.controlLoading = true
      console.log(devicecode, dimSchedule);
      const payload = {
        devicecode,
        dimSchedule
      };
      const { code, message } = await this.$store.dispatch(
        "devices/setDimSchedule",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        this.controlSuccess = this.controlSuccess+1
        // alert(data.message);
      } else {
        this.controlFail = this.controlFail+1
        this.controlMessages.push(message)
        // alert(message);
      }
      // this.dialog = false;
      // this.dialogOpt.dimSchedule = {}

    },
    async requestCurrentData(devicecode, state, notes) {
      console.log(devicecode, state, notes);
      this.controlLoading = true
      const payload = {
        devicecode
      };
      const { code, message } = await this.$store.dispatch(
        "devices/requestCurrentData",
        payload
      );
      // console.log("responseControlRelay", res);
      this.controlLoading = false
      if (code == 200) {
        this.controlSuccess = this.controlSuccess+1
        // alert(data.message);
      } else {
        this.controlMessages.push(message)
        // alert(message);
      }
      // this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async controlKwhRate(devicecode, value) {
      // console.log('control-Relay', devicecode)
      // return

      console.log(devicecode, value);
      this.controlLoading = true
      const payload = {
        devicecode,
        value
      };
      const { code, message } = await this.$store.dispatch(
        "devices/setKwhRate",
        payload
      );
      // console.log("responseControlRelay", res);
      this.controlLoading = false
      if (code == 200) {
        this.controlSuccess = this.controlSuccess+1
        // alert(data.message);
      } else {
        this.controlMessages.push(message)
        // alert(message);
      }
      // this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async updateMaintenance(devicecode, xvalue, notes) {
      // console.log('control-Relay', devicecode)
      // return
      let value = 0
      if (xvalue) {
        value = 1
      }

      console.log(devicecode, value, notes);
      this.controlLoading = true
      const payload = {
        devicecode,
        value, 
        notes
      };
      const { code, message } = await this.$store.dispatch(
        "devices/updateMaintenance",
        payload
      );
      // console.log("responseControlRelay", res);
      this.controlLoading = false
      if (code == 200) {
        this.controlSuccess = this.controlSuccess+1
        // alert(data.message);
      } else {
        this.controlMessages.push(message)
        // alert(message);
      }
      // this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async controlTotalisator(devicecode, totalisator, notes) {
      this.controlLoading = true
      console.log(devicecode, totalisator, notes);
      const payload = {
        devicecode,
        totalisator,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setTotalisator",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async controlSendInterval(devicecode, interval, notes) {
      this.controlLoading = true
      console.log(devicecode, interval, notes);
      const payload = {
        devicecode,
        interval,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setSendInterval",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async controlCalibrationTime(devicecode, notes) {
      this.controlLoading = true
      console.log(devicecode, notes);
      const payload = {
        devicecode,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setCalibrationTime",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
  },
  mounted() {},
  created() {
    this.userInfo = xstore.getters.USER_INFO
  }
};
</script>
