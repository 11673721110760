<template>
  <div>
  <v-card color="grey lighten-4" class="pb-0">
    <v-list-item class="grey lighten-3">
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          List View: {{ title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-data-table
              :headers="headers"
              :items="deviceList"
              :items-per-page="10"
              class="elevation-1"
            >

    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        small
        :color="getColor(item.status)"
        dark
      >
        {{ item.status_caption }}
      </v-chip>
    </template>
    <template v-slot:[`item.weather`]="{  }">
      <v-icon>mdi-weather-partly-cloudy</v-icon> 24°C - 26°C
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        class="mr-2"
        @click="detailItem(item)"
      >
        mdi-view-list-outline
      </v-icon>
      <v-icon
        @click="maintenanceItem(item)"
      >
        mdi-cogs
      </v-icon>
      <v-icon
        @click="notificationClick(item)"
      >
        mdi-bell-alert-outline
      </v-icon>

      
    </template>

    </v-data-table>
  </v-card>
    <v-dialog max-width="600" v-model="dialogDetail">
      <v-card >
        <v-list-item two-line >
          <v-list-item-content >
            <v-list-item-title>{{ detailData.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ detailData.devicecode }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <data-list width="500" :header="detailHeaders" :data="detailData" dense list-class="" list-item-class="">
        </data-list>
        <v-card-actions dense>
          <v-btn @click="dialogDetail = false" small dark color="primary"
            ><v-icon> mdi-close</v-icon> Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-model="dialogMaintenance">
      <v-card >
        <v-list-item two-line >
          <v-list-item-content >
            <v-list-item-title>{{ detailData.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ detailData.devicecode }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-text>
        <v-switch
          v-model="detailData.maintenance"
          :label="`Send maintenance status: ${
            detailData.maintenance ? 'Maintenance' : 'Not Maintenance'
          }`"
        ></v-switch>
        <v-textarea
          name="relaystatus-text"
          label="Notes"
          hint="Type notes on maintenance"
          rows="2"
          v-model="detailData.maintenanceNotes"
        ></v-textarea>
        </v-card-text>


        <v-card-actions dense>
          <v-btn @click="dialogMaintenance = false" small dark color="primary"
            ><v-icon> mdi-check</v-icon> Send</v-btn
          >
          <v-btn @click="dialogMaintenance = false" small dark color="primary"
            ><v-icon> mdi-close</v-icon> Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataList from "@/components/Cards/DataList";

export default {
  components: {
    DataList
  },
  data: () => ({ 
    dialogDetail: false,
    dialogMaintenance: false,
    dialotNotification: false,
    detailData: {},
    headers: [
      {
        text: 'Device Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Device EUI',
        align: 'start',
        sortable: true,
        value: 'devicecode',
      },
      // {
      //   text: 'Device Type',
      //   align: 'start',
      //   sortable: false,
      //   value: 'profilename',
      // },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'status',
      },
      // {
      //   text: 'Energy Meter',
      //   align: 'start',
      //   sortable: false,
      //   value: 'energy',
      // },
      {
        text: 'Voltage',
        align: 'start',
        sortable: true,
        value: 'voltage',
      },
      {
        text: 'Power',
        align: 'start',
        sortable: true,
        value: 'power',
      },
      {
        text: 'Last Data Time',
        align: 'start',
        sortable: true,
        value: 'lastdata',
      },
      {
        text: 'Signal Strength',
        align: 'start',
        sortable: true,
        value: 'rssi_strength_desc',
      },

      // {
      //   text: 'Current',
      //   align: 'start',
      //   sortable: false,
      //   value: 'current',
      // },
      // {
      //   text: 'Brightness',
      //   align: 'start',
      //   sortable: false,
      //   value: 'brightness',
      // },
      {
        text: 'Weather',
        align: 'start',
        sortable: false,
        value: 'weather',
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions',
      },
    ],
    detailHeaders: [
      { field: "profilename", caption: "Device Type", icon: "mdi-cog"},
      { field: "alamat", caption: "Lokasi / Alamat", icon: "mdi-cog"},
      { field: "projectname", caption: "Project Name", icon: "mdi-cog"},
      { field: "year", caption: "Year of Project", icon: "mdi-cog"},
      { field: "instansiname", caption: "Organization", icon: "mdi-cog"},
      { field: "lastdata", caption: "Last Data Time", icon: "mdi-cog"},
      { field: "energy", caption: "Energy", icon: "mdi-cog", unit: "kWh" },
      { field: "power", caption: "Power", icon: "mdi-cog", unit: "W" },
      { field: "voltage", caption: "Voltage", icon: "mdi-cog", unit: "V" },
      { field: "current", caption: "Current", icon: "mdi-cog", unit: "A" },
      { field: "powerfactor", caption: "Power Factor" },
      { field: "lightsensor", caption: "Light Sensor" },
      { field: "battery", caption: "Battery Level", unit: "%" },
      { field: "status_caption", caption: "Status", icon: "mdi-cog", unit: "kWh" },
      { field: "status_description", caption: "Status Description", icon: "mdi-cog", unit: "kWh" },
    ],
  }),
  computed: {
    deviceList () {
      let vitem = this.$store.getters['devices/deviceTreeSelected']
      if (vitem && Object.keys(vitem).length) {
        if (typeof vitem.listDevices !== 'undefined') {
          return vitem.listDevices
        }
      }
      return [vitem]
    },
    title () {
      let vitem = this.$store.getters['devices/deviceTreeSelected']
      console.log('tree-selected', vitem)
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type==='device') {
          return vitem.name
        } else if (vitem.name==='root') {
          return 'All Devices'
        } else {
          return vitem.caption+' '+vitem.name
        }
      } else {
        return ''
      }
      // return 'test-2'
    }


  },
  methods: {
    getColor (vstatus) {
      if (vstatus==='error') {
        return 'orange'
      } else if (vstatus==='off') {
        return 'grey'
      } else if (vstatus==='on') {
        return 'green'
      } else if (vstatus==='offline') {
        return 'grey'
      } else {
        return 'orange'
      }
    },
    detailItem(item) {
      this.detailData = item
      this.dialogDetail = true
    },
    maintenanceItem(item) {
      this.detailData = item
      this.dialogMaintenance = true
    }
  },
  mounted () {
  }
};
</script>

