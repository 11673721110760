<template>
  <div>
    <v-row v-if="false">
      <v-col>
        {{userInfo}}-{{currentDeviceHistory}}-{{deviceCode}}-{{testData}}-{{chartHistoryDef}}--
        
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col>
        {{deviceData}}
        
      </v-col>
    </v-row>
    <v-row>
      
      <template v-for="(item, i) in numberSetting" >
      <v-col
        cols="12"
        md="6"
        lg="3"
        v-if="deviceData.devicetype!=='pju-dc'"
        :key="i"
      >
        <number-view
          :title="item.title"
          :xnumber="deviceData[item.field] ? deviceData[item.field] : ''"
          :number="item.number"
          :unit="item.unit"
          :desc="item.desc"
          :icon="item.icon"
          :initial="item.initial"
          :number-class="item.numberClass ? item.numberClass : 'text-h4'"
          :color="item.color ? item.color : 'red'"
        ></number-view>
      </v-col>
      </template>
    </v-row>
    <v-row v-if="deviceData.devicetype==='pju-dc'">
      <v-col cols="12">
        <solar-view :dataset="deviceData"></solar-view>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <map-card :height="row1Height" :markers="locations" title="Location">
        </map-card>
      </v-col>
      <v-col cols="userInfo.role_name!='guest'?3:6">
        <data-card
          title="Device Detail"
          :header="deviceDetails"
          :data="deviceData"
          :height="row1Height"
        ></data-card>
      </v-col>
      <v-col cols="3" v-if="userInfo.role_name!='guest'">
        <data-card title="Realtime Control" :height="row1Height">
          <v-list-item v-if="!commands || commands.length==0">
            <v-list-item-avatar>
              <v-icon>mdi-checkbox-blank-off-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                No Realtime Control for this device
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="item in commands" :key="item">
            <v-list-item-avatar>
              <v-icon
                :color="
                  commandDefs[item].color ? commandDefs[item].color : 'blue'
                "
                light
                >{{ commandDefs[item].icon }}</v-icon
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{
                commandDefs[item].caption
              }}</v-list-item-title>

              <v-list-item-subtitle>{{
                commandDefs[item].desc
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="openDialog(item)">
                <v-icon color="grey darken-1"
                  >mdi-dots-horizontal-circle-outline</v-icon
                >
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </data-card>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="6">
        <v-card class="cyan darken-2" height="300"></v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="cyan darken-2" height="300"></v-card>
      </v-col>
    </v-row>

    <v-row v-if="false">
      <v-col>
        <v-btn @click="openDialog('test')"> TEST </v-btn>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog max-width="600" v-model="dialog" :style="{ zindex: 10001 }">
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark
                >{{ dialogOpt.title }}</v-toolbar
              >
              <v-card-text>
                <template v-if="dialogName == 'relaystatus'">
                  <v-switch
                    v-model="dialogOpt.relaystatus"
                    :label="`Send switch command: ${
                      dialogOpt.relaystatus ? 'ON' : 'OFF'
                    }`"
                  ></v-switch>
                  <v-textarea
                    name="relaystatus-text"
                    label="Notes"
                    hint="Type notes on send switch command"
                    rows="2"
                    v-model="dialogOpt.relayNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'timesync'">
                  <v-switch
                    v-model="dialogOpt.timesyncStatus"
                    :label="`Synchronize Time: ${
                      dialogOpt.timesyncStatus ? 'ON' : 'OFF'
                    }`"
                  ></v-switch>
                  <v-textarea
                    name="relaystatus-text"
                    label="Notes"
                    hint="Type notes on Time Synchronize"
                    rows="2"
                    v-model="dialogOpt.timesyncNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'totalisator'">
                  <v-text-field
                    label="Energy Meter (kWh)"
                    hide-details="auto"
                    v-model="dialogOpt.energyMeter"
                  ></v-text-field>
                  <v-textarea
                    name="totalisator-text"
                    label="Notes"
                    hint="Type notes on reset Energy Meter"
                    rows="2"
                    v-model="dialogOpt.totalisatorNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'brightness'">
                  <v-row >
                    <v-col cols="6" >
                  <v-text-field
                    label="Brightness"
                    hide-details="auto"
                    v-model="dialogOpt.brightness"
                  ></v-text-field>
                  </v-col>
                  <v-col cols="6" >
                    <v-slider
                      v-model="dialogOpt.brightness"
                      class="mt-4"
                    ></v-slider>
                  </v-col>
                  </v-row>
                  <v-textarea
                    name="brightness-text"
                    label="Notes"
                    hint="Type notes on reset Brightness"
                    rows="2"
                    v-model="dialogOpt.brightnessNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'interval'">
                  <v-text-field
                    label="Reporting Interval (hour)"
                    hide-details="auto"
                    v-model="dialogOpt.reportingInterval"
                  ></v-text-field>
                  <v-textarea
                    name="interval-text"
                    label="Notes"
                    hint="Type notes on setting Interval"
                    rows="2"
                    v-model="dialogOpt.intervalNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'schedule'">
                  <v-text-field
                    label="Time ON (HH:MM)"
                    hide-details="auto"
                    v-model="dialogOpt.timeOn"
                  ></v-text-field>
                  <v-text-field
                    label="Time OFF (HH:MM)"
                    hide-details="auto"
                    v-model="dialogOpt.timeOff"
                  ></v-text-field>
                  <v-textarea
                    name="schedule-text"
                    label="Notes"
                    hint="Type notes on reset Schedule"
                    rows="2"
                    v-model="dialogOpt.scheduleNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'set_threshold'">
                  <v-text-field
                    label="ON Threshold (Lux)"
                    hide-details="auto"
                    v-model="dialogOpt.luxmax"
                  ></v-text-field>
                  <v-text-field
                    label="OFF Threshold (Lux)"
                    hide-details="auto"
                    v-model="dialogOpt.luxmin"
                  ></v-text-field>
                  <v-textarea
                    name="interval-text"
                    label="Notes"
                    hint="Type notes on Set Threshold"
                    rows="2"
                    v-model="dialogOpt.thresholdNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'currentdata'">
                  <v-card-subtitle>Request Current Data of {{deviceData['name']}}</v-card-subtitle>
                </template>
                <template v-if="dialogName == 'kwh_rate'">
                  <v-text-field
                    label="KWH Rate"
                    hide-details="auto"
                    v-model="dialogOpt.kwhRate"
                  ></v-text-field>
                </template>
                <template v-if="dialogName == 'maintenance'">
                  <v-switch
                    v-model="dialogOpt.maintenance"
                    :label="`Update Maintenance Status`"
                  ></v-switch>
                  <v-textarea
                    name="maintenance-text"
                    label="Notes"
                    hint="Type notes on update Maintenance Status"
                    rows="2"
                    v-model="dialogOpt.maintenanceNotes"
                  ></v-textarea>
                </template>
                <template v-if="dialogName == 'newdimschedule'">
                  <v-row v-for="(item, i) in dimIndex" :key="i">
                    <v-col cols="3" :key="i">
                      <v-text-field
                        cols = "4"
                        :label="item==='M'?'Main Start (MS)':'Schedule '+item+' Start ('+item+'S)'"
                        hide-details="auto"
                        v-model="dialogOpt.dimSchedule[item.toLowerCase()+'s']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :key="item">
                      <v-text-field
                        cols = "4"
                        :label="item==='M'?'Main End (ME)':'Schedule '+item+' End ('+item+'E)'"
                        hide-details="auto"
                        v-model="dialogOpt.dimSchedule[item.toLowerCase()+'e']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :key="item">
                      <v-text-field
                        cols = "4"
                        :label="item==='M'?'Main Level (ML)':'Schedule '+item+' Level ('+item+'L)'"
                        hide-details="auto"
                        v-model="dialogOpt.dimSchedule[item.toLowerCase()+'l']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :key="item">
                      <v-slider
                        v-model="dialogOpt.dimSchedule[item.toLowerCase()+'l']"
                        class="mt-4"
                      ></v-slider>
                    </v-col>

                  </v-row>

                </template>

              </v-card-text>
              <v-card-actions class="justify-end">
                <v-progress-circular
                  v-if="controlLoading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-btn
                  text
                  primary
                  @click="json_dialog = true"
                  v-if="!controlLoading && dialogName=='newdimschedule'"
                  >JSON</v-btn>
                <v-btn
                  text
                  primary
                  @click="loadDimScheduleData"
                  v-if="!controlLoading && dialogName=='newdimschedule'"
                  >Load Device Config</v-btn>
                <v-btn text @click="dialog.value = false" v-if="!controlLoading">Cancel</v-btn>
                <!-- <v-btn primary @click="dialog.value = false">Submit</v-btn> -->
                <v-btn
                  primary
                  @click="processRealtimeControl(dialogName, dialogOpt)"
                  v-if="!controlLoading"
                  >Submit</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" >
        <tab-chart 
          title="History"
          :charts="chartList"
          :date="currentDate"
          :datasets="currentDeviceHistory" 
          :headers="chartHistoryDef"
          @datechanged="onDateChanged"
          :height="220"
        >
        </tab-chart>
        <v-btn
                  primary
                  @click="loadCurrentHistoryData"
                  v-if="!controlLoading && false"
                  >Submit</v-btn
                >
      </v-col>
            <v-col cols="6">
        <data-card
          v-if = "false"
          title="Device Detail"
          :header="deviceDetails"
          :data="deviceData"
          :height="row1Height"
        ></data-card>
        <multi-chart 
          title="Usage & Cost"
          :date="usageFromDate"
          :date2="usageToDate"
          :datasets="currentDeviceDaily" 
          :headers="chartDailyDef"
          :footer="footerCost"
          :yscales="yscales"
          @datechanged="onDateMultiChanged"
        >
        </multi-chart>
        
        

      </v-col>

    </v-row>
    <v-dialog v-model="json_dialog" max-width="800" height="800">
      <v-card>
        <json-editor color="white" v-model="dialogOpt.dimSchedule" mode="code" :show-btns="false"></json-editor>
        <v-card-actions class="justify-end">
          <v-btn
                  text
                  primary
                  @click="json_dialog = false"
                  v-if="!controlLoading && dialogName=='newdimschedule'"
                  >Close</v-btn>
        </v-card-actions>
      </v-card>

      
    </v-dialog>
  </div>
</template>

<script>
// import ProfileCard from "@/components/Cards/ProfileCard";
import NumberView from "@/components/Cards/NumberView";
import DataCard from "@/components/Cards/DataCard";
import MapCard from "@/components/Cards/MapCard";
// import SimpleChart from "@/views/SmartLight/SimpleChart";
import TabChart from "@/views/SmartLight/TabChart";
import MultiChart from "@/views/SmartLight/MultiChart";
import xstore from '@/store/index.js'
import SolarView from "@/views/SmartLight/SolarDeviceView";
import JsonEditor from "vue-json-editor";

const checkStatus = function (vnode) {
  let result = ''
  // let pwThreshold = 4
  // console.log('XPOWER', vnode.current*vnode.voltage)
  if (vnode.maintenance) {
    result = 'maintenance'
  }else if (vnode.error_status) {
    result = 'error'
  } else if (vnode.offlineStatus==='offline') {
    result = 'offline'
  } else if (vnode?.status === 'on' ){
    result = 'on'
  // } else if ((typeof vnode.statuson !== 'undefined' ) && (vnode.statuson)){
  //   result = 'on'
  // } else if ((typeof vnode.current !== 'undefined' ) && (typeof vnode.voltage !== 'undefined' ) && (vnode.current*vnode.voltage>=pwThreshold)) {
  //   result = 'on'
  // } else if ((typeof vnode.current !== 'undefined' ) && (vnode.current*1>=0.06)) {
  //   result = 'on'
  // } else if ((typeof vnode.brightness !== 'undefined' ) && (vnode.brightness>0.0000001)) {
  //   result = 'on'
  } else {
    result = 'off'
  }
  return result
}


export default {
  components: {
    // ProfileCard,
    NumberView,
    DataCard,
    MapCard,
    TabChart,
    MultiChart,
    SolarView,
    JsonEditor
  },
  data: () => ({
    chartList: [
      { title: "Energy Meter" },
      { title: "Power" },
      { title: "Voltage" },
      { title: "Current" },
      { title: "Brightness" }
    ],
    dimIndex: ['M', 'A', 'B', 'C', 'D'],
    testData: 999,
    dialog: false,
    json_dialog: false,
    datasets: [],
    usageFromDate: '',
    usageToDate: '',
    // startMonthDate: '',
    currentDate: (new Date()).toISOString().substr(0, 10),
    dialogName: "",
    dialogOpt: {
      title: "-",
      relaystatus: false,
      dimSchedule: {
        ms: '',
        me: '',
        ml: '',
        as: '',
        ae: '',
        al: '',
        bs: '',
        be: '',
        bl: '',
        cs: '',
        ce: '',
        cl: '',
        ds: '',
        de: '',
        dl: ''
      }
    },
    controlLoading: false,
    text2: "x",
    row1Height: 540,
    yscales: [
      {
        "id": "rupiah",
        "type": "linear",
        "position": "left",
        "scaleLabel": {
          "display": true,
          "labelString": "Rupiah"
        }
      },
      {
        "id": "kwh",
        "type": "linear",
        "position": "right",
        "scaleLabel": {
          "display": true,
          "labelString": "KWh"
        }
      }
    ],
    xcommands: ["relaystatus", "totalisator", "brightness", "schedule"],
    commandDefs: {
      relaystatus: {
        caption: "Relay Status",
        desc: "Set Relay ON/OFF",
        title: "Set Relay ON/OFF",
        icon: "mdi-lightbulb-on-outline",
        color: "red",
      },
      totalisator: {
        caption: "Totalisator",
        desc: "Reset Energy Meter",
        title: "Reset Energy Meter",
        icon: "mdi-lightning-bolt-circle",
        color: "blue",
      },
      brightness: {
        caption: "Brightness",
        desc: "Set Lamp Brightness",
        title: "Set Lamp Brightness (10 - 100)",
        icon: "mdi-brightness-6",
        color: "yellow darken-2",
      },
      schedule: {
        caption: "Schedule",
        desc: "Set ON/OFF Schedule",
        title: "Set ON/OFF Schedule",
        icon: "mdi-calendar-clock",
        color: "purple",
      },
      currentdata: {
        caption: "Request Current Data",
        desc: "Request Current Data",
        title: "Rerqeust Current Data",
        icon: "mdi-lightbulb-on-outline",
        color: "green",
      },
      kwh_rate: {
        caption: "Setting KW Rate",
        desc: "Setting KWH Rate",
        title: "Setting KWH Rate",
        icon: "mdi-tag-multiple-outline",
        color: "green",
      },
      maintenance: {
        caption: "Maintenance Status",
        desc: "Update Maintenance Status",
        title: "Update Maintenance Status",
        icon: "mdi-cog-outline",
        color: "grey darken-3",
      },
      timesync: {
        caption: "Time Synchronization Status",
        desc: "Set Synchronization ON/OFF",
        title: "Set Synchronization ON/OFF",
        icon: "mdi-timer-sync-outline",
        color: "blue darken-3",
      },
      interval: {
        caption: "Set Report Interval",
        desc: "Set Report Interval",
        title: "Set Device Reporting Interval",
        icon: "mdi-timer-cog-outline",
        color: "green darken-2",
      },
      driver_model: {
        caption: "Get Driver Model",
        desc: "Driver Model & Firmware Version",
        title: "Get Driver Model and Firmware Version",
        icon: "mdi-information-outline",
        color: "blue darken-3",
      },
      device_config: {
        caption: "Get Device Configuration",
        desc: "Device Configuration",
        title: "Get Device Configuration",
        icon: "mdi-wrench-outline",
        color: "blue darken-3",
      },
      set_threshold: {
        caption: "Set On-Off Threshold",
        desc: "Set On-Off Threshold",
        title: "Set On-Off Threshold",
        icon: "mdi-cogs",
        color: "red darken-3",
      },
      newdimschedule: {
        caption: "Dimming Schedule",
        desc: "Update Dimming Schedule",
        title: "Update Dimming Schedule",
        icon: "mdi-calendar-clock",
        color: "purple",
      },



    },
    chartHistoryDef: [
      {
        caption: 'Time',
        field: 'datatime',
        unit: 'time'
      },
      {
        caption: 'Energy Meter',
        field: 'energy',
        unit: 'kwh'
      },
      {
        caption: 'Power',
        field: 'power',
        unit: 'watt'
      },
      {
        caption: 'Volt',
        field: 'voltage',
        unit: 'volt'
      },
      {
        caption: 'Current',
        field: 'current',
        unit: 'ampere'
      },
      {
        caption: 'Brightness',
        field: 'brightness',
        unit: 'percentage'
      }
    ],
    chartDailyDef: [
      {
        caption: 'Date',
        field: 'devicedate',
        unit: 'date'
      },
      {
        caption: 'Daily Usage',
        field: 'usage',
        unit: 'kwh'
      },
      {
        caption: 'Daily Cost',
        field: 'cost',
        unit: 'rupiah'
      }
    ],
    profileMain: [
      { field: "name", caption: "Device Name", icon: "mdi-cog" },
      { field: "deveui", caption: "Device EUI", icon: "mdi-cog" },
      {
        field: "address",
        caption: "Address",
        icon: "mdi-map-marker",
        default: "-",
      },
      {
        field: "hardwaretype",
        caption: "Hardware Type",
        icon: "mdi-map-marker",
        default: "Public Lighting Controller",
      },
    ],
    profileMore: [
      {
        field: "organization",
        caption: "Organization Name",
        default: "Antares",
      },
      { field: "deviceid", caption: "Device Id" },
      { field: "serialnum", caption: "Serial Number" },
      { field: "appname", caption: "Application Name" },
    ],
    statusMain: [
      { field: "energy", caption: "Energy", icon: "mdi-cog", unit: "kWh" },
      { field: "power", caption: "Power", icon: "mdi-cog", unit: "W" },
      { field: "voltage", caption: "Voltage", icon: "mdi-cog", unit: "V" },
      { field: "current", caption: "Current", icon: "mdi-cog", unit: "A" },
    ],
    statusMore: [
      { field: "powerfactor", caption: "Power Factor" },
      { field: "lightsensor", caption: "Light Sensor" },
      { field: "battery", caption: "Battery Level", unit: "%" },
      { field: "relaystatus", caption: "Relay Status" },
      { field: "brightness", caption: "Brightness" },
    ],
    deviceDetails: [
      { field: "name", caption: "Device Name", icon: "mdi-cog" },
      { field: "devicecode", caption: "Device EUI", icon: "mdi-cog" },
      { field: "lamp_sn", caption: "Lamp SN", icon: "mdi-cog" },
      { field: "controller_sn", caption: "Controller SN", icon: "mdi-cog" },
      { field: "profilename", caption: "Device Type", icon: "mdi-cog"},
      { field: "projectname", caption: "Project Name", icon: "mdi-cog"},
      { field: "year", caption: "Year of Project", icon: "mdi-cog"},
      { field: "instansiname", caption: "Organization", icon: "mdi-cog"},
      { field: "lastdata", caption: "Last Data Time", icon: "mdi-cog"},
      { field: "rssi_strength_desc", caption: "Signal Strength", icon: "mdi-cog"},
      // {
      //   field: "address",
      //   caption: "Address",
      //   icon: "mdi-map-marker",
      //   default: "-",
      // },

      { field: "device_time", caption: "Device Time", icon: "mdi-cog"},
      { field: "timesync", caption: "Time Sync", icon: "mdi-cog"},
      
      { field: "energy", caption: "Energy", icon: "mdi-cog", unit: "kWh", decimal: 3 },
      { field: "voltage", caption: "Input Voltage", icon: "mdi-cog", unit: "V", decimal: 2 },
      { field: "current", caption: "Input Current", icon: "mdi-cog", unit: "A", decimal: 3 },
      // { field: "load", caption: "Load", decimal: 2 },
      { field: "power", caption: "Input Power", icon: "mdi-cog", unit: "W", decimal: 2 },
      { field: "powerfactor", caption: "Power Factor", decimal: 2 },
      { field: "out_volt", caption: "Output Voltage", icon: "mdi-cog", unit: "V", decimal: 2 },
      { field: "out_curr", caption: "Output Current", icon: "mdi-cog", unit: "A", decimal: 3 },
      { field: "out_pwr", caption: "Output Power", icon: "mdi-cog", unit: "W", decimal: 2 },


      // { field: "lightsensor", caption: "Light Sensor" },
      // { field: "battery", caption: "Battery Level", unit: "%" },
      { field: "relaystatus", caption: "Relay Status" },
      { field: "brightness", caption: "Brightness" },
      { field: "temp_int", caption: "Internal Temp (°C)" },
      // { field: "temp_ext", caption: "External Temp (°C)" },
      { field: "on_time", caption: "Lamp On Time (h)" },
      { field: "op_time", caption: "Operating Time (h)" },
      { field: "current_time", caption: "Current Time", icon: "mdi-cog"},
      { field: "device_time_num", caption: "Device Time Num", icon: "mdi-cog"},
      { field: "current_time_num", caption: "Current Time Num", icon: "mdi-cog"},
      { field: "difference", caption: "Time difference", icon: "mdi-cog"},
      { field: "device_downlink", caption: "Device Time", icon: "mdi-cog"},
      { field: "device_downlink_value", caption: "Time diff sent", icon: "mdi-cog"},
      { field: "device_downlink_hexval", caption: "Hex time diff sent", icon: "mdi-cog"},
      { field: "send_downlink", caption: "Time Sync Downlink", icon: "mdi-cog"},
      { field: "driver_model", caption: "Driver Model", icon: "mdi-cog"},
      { field: "fw_version", caption: "Firmware Version", icon: "mdi-cog"},
      { field: "led_on_time", caption: "ON Schedule", icon: "mdi-cog"},
      { field: "led_off_time", caption: "OFF Schedule", icon: "mdi-cog"},
      { field: "dim_level", caption: "Brightness Config", icon: "mdi-cog"},
      { field: "on_threshold", caption: "ON Threshold", icon: "mdi-cog"},
      { field: "off_threshold", caption: "OFF Threshold", icon: "mdi-cog"},
      { field: "default_rpt_interval", caption: "Default Report Interval", icon: "mdi-cog"},
      { field: "custom_rpt_interval", caption: "Custom Report Interval", icon: "mdi-cog"},
      { field: "custom_rpt_start", caption: "Custom Report Start", icon: "mdi-cog"},
      { field: "custom_rpt_end", caption: "Custom Report End", icon: "mdi-cog"},
      { field: "main_start", caption: "Main Schedule Start", icon: "mdi-cog"},
      { field: "main_end", caption: "Main Schedule End", icon: "mdi-cog"},
      { field: "main_level", caption: "Main Schedule Level", icon: "mdi-cog"},
      { field: "a_start", caption: "Schedule A Start", icon: "mdi-cog"},
      { field: "a_end", caption: "Schedule A End", icon: "mdi-cog"},
      { field: "a_level", caption: "Schedule A Level", icon: "mdi-cog"},
      { field: "b_start", caption: "Schedule B Start", icon: "mdi-cog"},
      { field: "b_end", caption: "Schedule B End", icon: "mdi-cog"},
      { field: "b_level", caption: "Schedule B Level", icon: "mdi-cog"},
      { field: "c_start", caption: "Schedule C Start", icon: "mdi-cog"},
      { field: "c_end", caption: "Schedule C End", icon: "mdi-cog"},
      { field: "c_level", caption: "Schedule C Level", icon: "mdi-cog"},
      { field: "d_start", caption: "Schedule D Start", icon: "mdi-cog"},
      { field: "d_end", caption: "Schedule D End", icon: "mdi-cog"},
      { field: "d_level", caption: "Schedule D Level", icon: "mdi-cog"},

      
    ],

    numberDefs: [
      {
        field: "name",
        title: "Device",
        unit: "",
        icon: "mdi-ceiling-light-outline",
        color: "light-blue darken-2",
      },
      {
        field: "lightstatus",
        title: "Light Status",
        unit: "",
        icon: "mdi-lightbulb-on",
        color: "orange darken-1",
      },
      {
        field: "energy",
        title: "Energy Meter",
        unit: "kWh",
        icon: "mdi-lightning-bolt",
        color: "light-blue darken-2",
      },
      {
        field: "power",
        title: "Power",
        unit: "W",
        initial: "W",
        color: "deep-purple darken-2",
      },
      {
        field: "voltage",
        title: "Voltage",
        unit: "V",
        initial: "V",
        color: "pink darken-1",
      },
      {
        field: "current",
        title: "Current",
        unit: "A",
        initial: "I",
        color: "green darken-3",
      },
      {
        field: "brightness",
        title: "Brightness",
        unit: "%",
        icon: "mdi-car-light-high",
        color: "orange darken-1",
      },
      {
        field: "on_time",
        title: "Lamp On Time",
        unit: "hours",
        icon: "mdi-timer-cog-outline",
        color: "green darken-1",
      },
      {
        field: "usage_today",
        title: "Today Usage",
        unit: "kWh",
        icon: "mdi-lightning-bolt",
        color: "light-blue darken-2",
      },
      {
        field: "usage_month",
        title: "This Month Usage",
        unit: "kWh",
        icon: "mdi-lightning-bolt",
        color: "light-blue darken-2",
      },
      {
        field: "rp_today",
        title: "Today Cost",
        unit: "Rp",
        initial: "Rp",
        // icon: "mdi-lightning-bolt",
        color: "light-blue darken-2",
      },
      {
        field: "rp_month",
        title: "This Month Cost",
        unit: "Rp",
        initial: "Rp",
        // icon: "mdi-lightning-bolt",
        color: "light-blue darken-2",
      },
      // {
      //   field: "battery",
      //   title: "Battery Level",
      //   unit: "%",
      //   icon: "mdi-battery-70",
      //   color: "light-blue lighten-1",
      // },
    ],
    // singleDevice: false
  }),
  computed: {
    numberSetting() {
      let result = [];
      for (let i = 0; i < this.numberDefs.length; i++) {
        let vitem = this.numberDefs[i];
        let xitem = { ...vitem };
        let showItem = true
        if (vitem.field === "lightstatus") {
          xitem["numberClass"] = "text-h4";
          let lstatus = checkStatus(this.deviceData)
          if (lstatus==='maintenance') {
            xitem["number"] = "Maintenance";
            xitem["desc"] = this.deviceData["error_reason"];
            xitem["color"] = "grey darken-1";
            xitem["icon"] = "mdi-cog";
            xitem["numberClass"] = "text-h5 ta-top";
          } else if ((this.deviceData["error_status"]) || (lstatus==='error')) {
            xitem["number"] = "Warning";
            xitem["desc"] = this.deviceData["error_reason"];
            xitem["color"] = "orange lighten-3";
            xitem["icon"] = "mdi-alert-decagram";
          } else if (lstatus==='offline') {
            xitem["number"] = "OFFLINE";
            xitem["desc"] = this.deviceData["error_reason"];
            xitem["color"] = "grey darken-1";
            xitem["icon"] = "mdi-network-off";
          } else if (lstatus==='on') {
            xitem["number"] = "ON";
          } else if (lstatus==='off') {
            xitem["number"] = "OFF";
            xitem["color"] = "grey darken-3";
            xitem["icon"] = "mdi-lightbulb-off-outline";
          }

          // if (this.deviceData["error_status"]) {
          //   xitem["number"] = "ERR";
          //   xitem["desc"] = this.deviceData["error_reason"];
          //   xitem["color"] = "red darken-3";
          //   xitem["icon"] = "mdi-alert-decagram";
          // } else if (this.deviceData["lightstatus"] || checkStatus(this.deviceData)) {
          //   xitem["number"] = "ON";
          // } else {
          //   xitem["number"] = "OFF";
          //   xitem["color"] = "grey darken-3";
          //   xitem["icon"] = "mdi-lightbulb-off-outline";
          // }
        } else if (vitem.field === "name") {
          (xitem["number"] = this.deviceData["name"]), 2;
          xitem["unit"] = "";
          xitem["desc"] = "Dev EUI: " + this.deviceData["devicecode"];
          xitem["color"] = vitem.color;
          xitem["icon"] = vitem.icon;
          xitem["numberClass"] = "text-h6 ta-top";
        } else if (vitem.field === "energy") {
          if(typeof this.deviceData["energy"]==='undefined') {
            showItem = false
          } else {
            xitem["number"] = this.numberFormat(this.deviceData[vitem.field], 4);
          }
        } else if (vitem.field === "current") {
          xitem["number"] = this.numberFormat(this.deviceData[vitem.field], 3);
        } else if (vitem.field === "on_time") {
          if(typeof this.deviceData["on_time"]==='undefined') {
            showItem = false
          } else {
            xitem["number"] = this.numberFormat(this.deviceData[vitem.field], 0);
            let optime = this.numberFormat(this.deviceData['op_time'], 0);
            xitem["desc"] = "Operating Time: " + optime + ' hours.';
          }
        } else if (vitem.field === "usage_today") {
          xitem["number"] = this.numberFormat(this.deviceData[vitem.field], 3);
        } else if (vitem.field === "usage_month") {
          xitem["number"] = this.numberFormat(this.deviceData[vitem.field], 3);
        } else if (vitem.field === "rp_today") {
          xitem["number"] = this.numberFormat(this.deviceData[vitem.field], 0);
          xitem["desc"] = "Cost per Kwh: Rp. " + this.deviceData["kwh_rate"];
        } else if (vitem.field === "rp_month") {
          xitem["number"] = this.numberFormat(this.deviceData[vitem.field], 0);
          xitem["desc"] = "Cost per Kwh: Rp. " + this.deviceData["kwh_rate"];
        } else {
          xitem["number"] = this.numberFormat(this.deviceData[vitem.field], 2);
        }
        if (showItem) {
          result.push(xitem);
        }
      }
      console.log('number settings', result)
      return result;
    },
    deviceData() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type === "device") {
          return vitem;
        }
      }
      return {};
    },
    deviceCode () {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type === "device") {
          return vitem.devicecode;
        }
      }
      return '';

    },
    singleDevice() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type === "device") {
          return true;
        }
      }
      return false;
    },
    title1() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type === "device") {
          return "Single Device";
        } else {
          return "Multi Device";
        }
      } else {
        return "All Devices";
      }
      //return 'test-1'
    },
    title2() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      console.log(vitem);
      if (vitem && Object.keys(vitem).length) {
        if (vitem.type === "device") {
          return vitem.name;
        } else {
          return vitem.caption + ": " + vitem.name;
        }
      } else {
        return "";
      }
      // return 'test-2'
    },
    locations() {
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      let result = [];
      // {
      //   name: 'marker',
      //   longitude: 107.618551,
      //   latitude: -6.901461
      // }
      console.log("map vitem", vitem);
      if (vitem && Object.keys(vitem).length) {
        if (
          typeof vitem["longitude"] !== "undefined" &&
          typeof vitem["latitude"] !== "undefined"
        ) {
          result.push({
            name: vitem["name"],
            longitude: vitem["longitude"],
            latitude: vitem["latitude"],
          });
          // result = [vitem['latitude'], vitem['longitude']]
        }
      }
      return result;
    },
    currentDeviceHistory () {
      let devicecode = this.deviceData['devicecode']
      let vitem = this.$store.getters["devices/deviceHistory"](devicecode, this.currentDate)
      console.log('deviceHistory', vitem)
      return vitem
    },
    currentDeviceDaily () {
      let devicecode = this.deviceData['devicecode']
      let vitem = this.$store.getters["devices/deviceDaily"](devicecode, this.usageFromDate, this.usageToDate)
      console.log('deviceDaily', vitem)
      return vitem
    },

    currentDeviceTesting () {
      let devicecode = this.deviceData['devicecode']
      let vitem = this.$store.getters["devices/deviceTesting"](devicecode)
      return vitem
    },
    commands () {
      if (this.userInfo.role_name==='guest') {
        return []
      }
      let devcommands = {
        'pju-avnet': ["relaystatus", "brightness", "currentdata", "kwh_rate", "maintenance", "schedule", "timesync", "interval",
          'driver_model', 'device_config', 
          // 'set_threshold'
        ],
        'pju-avnet2': ["relaystatus", "brightness", "currentdata", "kwh_rate", "maintenance", "timesync", "interval",
          'driver_model', 'device_config', 
          // 'set_threshold', 
          'newdimschedule'],
        'pju-telkom': ["relaystatus", "totalisator", "brightness", "kwh_rate", "maintenance", "schedule"],
        'pju-icode': ["relaystatus", "brightness", "kwh_rate", "maintenance", "timesync", "schedule"],
        'pju-dc': ["relaystatus", "brightness", "currentdata", "schedule"]
      }
      // ["relaystatus", "totalisator", "brightness", "schedule"]
      let vitem = this.$store.getters["devices/deviceTreeSelected"];
      // console.log("commands.....", vitem);
      let result = ["kwh_rate", "maintenance"];
      if (vitem && Object.keys(vitem).length) {
        let devtype = vitem.devicetype
        let xresult = devcommands[devtype]
        if (typeof xresult !== 'undefined') {
          result = xresult
        }
      }
      // console.log("commands.....", result);
      return result
    },
    footerCost () {
      let result
      if (this.deviceData["kwh_rate"]) {
        result = `Asumsi cost per kwh: Rp. ${this.deviceData["kwh_rate"]} `
      } else {
        result = ''
      }
      return result
    }
  },
  methods: {
    onDateChanged (value) {
      console.log('Date Changed', value)
      this.currentDate = value
    },
    onDateMultiChanged (value, value2) {
      console.log('Date Multi Chart Changed', value, value2)
      // this.currentDate = value
      this.usageFromDate = value
      this.usageToDate = value2
    },
    processRealtimeControl(dialogName, dialogOpt) {
      console.log("prc", dialogName, dialogOpt);
      switch (dialogName) {
        case "relaystatus":
          this.controlRelay(
            this.deviceData.devicecode,
            dialogOpt.relaystatus,
            dialogOpt.relayNotes
          );
          break;
        case "currentdata":
          this.requestCurrentData(
            this.deviceData.devicecode
          );
          break;
        case "kwh_rate":
          this.controlKwhRate(
            this.deviceData.devicecode,
            dialogOpt.kwhRate
          );
          break;
        case "maintenance":
          this.updateMaintenance(
            this.deviceData.devicecode,
            dialogOpt.maintenance,
            dialogOpt.maintenanceNotes
          );
          break;
        case "schedule":
          this.controlSchedule(
            this.deviceData.devicecode,
            dialogOpt.timeOn,
            dialogOpt.timeOff,
            dialogOpt.relayNotes
          );
          break;
        case "totalisator":
          this.controlTotalisator(
            this.deviceData.devicecode,
            dialogOpt.energyMeter,
            dialogOpt.totalisatorNotes
          );
          break;
        case "timesync":
          this.controlTimeSync(
            this.deviceData.devicecode,
            dialogOpt.timesyncStatus,
            dialogOpt.timesyncNotes
          );
          break;
        case "brightness": 
          this.controlBrightness(
            this.deviceData.devicecode,
            dialogOpt.brightness,
            dialogOpt.relayNotes
          );
          break;
        case "interval":
          this.controlSendInterval(
            this.deviceData.devicecode,
            dialogOpt.reportingInterval,
            dialogOpt.intervalNotes
          );
          break;
        case "driver_model":
          this.controlDriverModel(
            this.deviceData.devicecode
          );
          break;
        case "device_config":
          this.controlDeviceConfig(
            this.deviceData.devicecode
          );
          break;
        case "set_threshold":
          this.controlSendThreshold(
            this.deviceData.devicecode,
            dialogOpt.luxmax,
            dialogOpt.luxmin,
            dialogOpt.intervalNotes
          );
          break;
        case "newdimschedule":
          this.updateDimSchedule(
            this.deviceData.devicecode,
            dialogOpt.dimSchedule
          );
          break;

      }
    },
    async controlRelay(devicecode, state, notes) {
      console.log(devicecode, state, notes);
      this.controlLoading = true
      const payload = {
        devicecode,
        state,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setRelay",
        payload
      );
      // console.log("responseControlRelay", res);
      this.controlLoading = false
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async requestCurrentData(devicecode, state, notes) {
      console.log(devicecode, state, notes);
      this.controlLoading = true
      const payload = {
        devicecode
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/requestCurrentData",
        payload
      );
      // console.log("responseControlRelay", res);
      this.controlLoading = false
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async controlKwhRate(devicecode, value) {
      console.log(devicecode, value);
      this.controlLoading = true
      const payload = {
        devicecode,
        value
      };
      const { code, message } = await this.$store.dispatch(
        "devices/setKwhRate",
        payload
      );
      // console.log("responseControlRelay", res);
      this.controlLoading = false
      if (code == 200) {
        alert(message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async updateMaintenance(devicecode, xvalue, notes) {
      let value = 0
      if (xvalue) {
        value = 1
      }
      console.log(devicecode, value);
      this.controlLoading = true
      const payload = {
        devicecode,
        value,
        notes
      };
      const { code, message } = await this.$store.dispatch(
        "devices/updateMaintenance",
        payload
      );
      // console.log("responseControlRelay", res);
      this.controlLoading = false
      if (code == 200) {
        alert(message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.maintenanceNotes;
    },
    async controlBrightness(devicecode, brightness, notes) {
      this.controlLoading = true
      console.log(devicecode, brightness, notes);
      const payload = {
        devicecode,
        brightness,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setBrightness",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
        this.dialog = false;
      } else {
        alert(message);
      }
      delete this.dialogOpt.relayNotes;
    },
    async controlTimeSync(devicecode, syncStatus, notes) {
      this.controlLoading = true
      console.log(devicecode, syncStatus, notes);
      const payload = {
        devicecode,
        syncStatus,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setTimeSync",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
        this.dialog = false;
      } else {
        alert(message);
      }
      delete this.dialogOpt.timesyncNotes;
    },
    async controlSchedule(devicecode, timeOn, timeOff, notes) {
      console.log('control--schedule')
      this.controlLoading = true
      console.log(devicecode, timeOn, timeOff, notes);
      const payload = {
        devicecode,
        timeOn,
        timeOff,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setSchedule",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
        this.dialog = false;
      } else {
        alert(message);
      }
      delete this.dialogOpt.relayNotes;
    },
    async controlTotalisator(devicecode, totalisator, notes) {
      console.log('totalisator....', totalisator)
      this.controlLoading = true
      console.log(devicecode, totalisator, notes);
      const payload = {
        devicecode,
        totalisator,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setTotalisator",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
        this.dialog = false;
      } else {
        alert(message);
      }
      delete this.dialogOpt.relayNotes;
    },
    async controlSendInterval(devicecode, interval, notes) {
      this.controlLoading = true
      console.log(devicecode, interval, notes);
      const payload = {
        devicecode,
        interval,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setSendInterval",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    async controlCalibrationTime(devicecode, notes) {
      this.controlLoading = true
      console.log(devicecode, notes);
      const payload = {
        devicecode,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setCalibrationTime",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },
    // controlDriverModel controlDeviceConfig controlSendThreshold
    // getDriverModel getDeviceConfig getSendThreshold
    async controlDriverModel(devicecode) {
      this.controlLoading = true
      console.log(devicecode);
      const payload = {
        devicecode
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/getDriverModel",
        payload
      );
      this.controlLoading = false
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
    },
    async controlDeviceConfig(devicecode) {
      this.controlLoading = true
      console.log(devicecode);
      const payload = {
        devicecode
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/getDeviceConfig",
        payload
      );
      this.controlLoading = false
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
    },
    async controlSendThreshold(devicecode, maxvalue, minvalue, notes) {
      this.controlLoading = true
      console.log(devicecode, notes);
      const payload = {
        devicecode,
        maxvalue, 
        minvalue,
        notes,
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setOnOffThreshold",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
      delete this.dialogOpt.relayNotes;
    },

    async updateDimSchedule(devicecode, dimSchedule) {
      this.controlLoading = true
      console.log(devicecode, dimSchedule);
      const payload = {
        devicecode,
        dimSchedule
      };
      const { code, data, message } = await this.$store.dispatch(
        "devices/setDimSchedule",
        payload
      );
      this.controlLoading = false
      // console.log("responseControlRelay", res);
      if (code == 200) {
        alert(data.message);
      } else {
        alert(message);
      }
      this.dialog = false;
      // this.dialogOpt.dimSchedule = {}

    },

    async loadCurrentHistoryData () {
      console.log('loadCurrentHistoryData', this.deviceData)
      
      if (typeof this.deviceData['devicecode'] !== 'undefined') {

        const payload = {
          devicecode: this.deviceData['devicecode'],
          tanggal: this.currentDate
        }

        await this.$store.dispatch(
          "devices/updateDeviceTesting",
          payload
        )

        await this.$store.dispatch(
          "devices/getDeviceHistory",
          payload
        )

      }
    },
    async loadCurrentDailyData() {
      console.log('loadCurrentDailyData', this.deviceData)
      
      if (typeof this.deviceData['devicecode'] !== 'undefined') {

        const payload = {
          devicecode: this.deviceData['devicecode'],
          from: this.usageFromDate,
          to: this.usageToDate
        }

        // await this.$store.dispatch(
        //   "devices/updateDeviceTesting",
        //   payload
        // )

        await this.$store.dispatch(
          "devices/getDeviceDaily",
          payload
        )

      }

    },
    numberFormat(vnumber, vlen) {
      let vres = (vnumber * 1).toFixed(vlen);
      if (isNaN(vres)) {
        return "";
      }
      return vres;
    },
    onSelection(selection) {
      console.log("on-selection", selection);
    },
    loadDimScheduleData() {
      let vdata = this.deviceData || {};
      for (let i=0; i<this.dimIndex.length; i++) {
        let xi = this.dimIndex[i].toLowerCase()
        let vxi = xi
        if (xi==='m') {
          vxi = 'main'
        }
        let vname = vxi+'_start'
        if (typeof vdata[vname] !== 'undefined') {
          this.dialogOpt.dimSchedule[xi+'s'] = vdata[vname]
        } else {
          this.dialogOpt.dimSchedule[xi+'s'] = ''
        }
        vname = vxi+'_end'
        if (typeof vdata[vname] !== 'undefined') {
          this.dialogOpt.dimSchedule[xi+'e'] = vdata[vname]
        } else {
          this.dialogOpt.dimSchedule[xi+'e'] = ''
        }
        vname = vxi+'_level'
        if (typeof vdata[vname] !== 'undefined') {
          this.dialogOpt.dimSchedule[xi+'l'] = vdata[vname]
        } else {
          this.dialogOpt.dimSchedule[xi+'l'] = ''
        }
        console.log('dim', xi, vxi)
      }
    },
    openDialog (vname) {
      console.log("xvname", vname);
      let vdata = this.deviceData['dim_schedule'] || {};
      console.log('vdata', vdata)
      if (vname==='newdimschedule') {
        // this.loadDimScheduleData()
      }
      // if (vname==='newdimschedule') {
      //   // looping for this.dimIndex
      //   for (let i=0; i<this.dimIndex.length; i++) {
      //     let xi = this.dimIndex[i].toLowerCase()
      //     let vxi = xi
      //     if (xi==='m') {
      //       vxi = 'main'
      //     }
      //     let vname = vxi+'_start'
      //     if (typeof vdata[vname] !== 'undefined') {
      //       this.dialogOpt.dimSchedule[xi+'s'] = vdata[vname]
      //     } else {
      //       this.dialogOpt.dimSchedule[xi+'s'] = ''
      //     }
      //     vname = vxi+'_end'
      //     if (typeof vdata[vname] !== 'undefined') {
      //       this.dialogOpt.dimSchedule[xi+'e'] = vdata[vname]
      //     } else {
      //       this.dialogOpt.dimSchedule[xi+'e'] = ''
      //     }
      //     vname = vxi+'_level'
      //     if (typeof vdata[vname] !== 'undefined') {
      //       this.dialogOpt.dimSchedule[xi+'l'] = vdata[vname]
      //     } else {
      //       this.dialogOpt.dimSchedule[xi+'l'] = ''
      //     }
      //     console.log('dim', xi, vxi)
      //   }


      //   this.dialogOpt.dimSchedule
      // }
      this.dialogName = vname;
      if (typeof this.commandDefs[vname] !== "undefined") {
        this.dialogOpt.title = this.commandDefs[vname].title;
      } else {
        this.dialogOpt.title = vname;
      }
      this.dialog = true;
      this.controlLoading = false
    },
    randomData (count, c1, c2) {
      let result = [{x: -c2, y: 0}]
      for (let i=0; i<count; i++) {
        let x = Math.round(Math.random() * c1) - c2
        let y = Math.round(Math.random() * c1) - c2
        result.push({x: x, y: y})
      }
      result.sort((a, b)=>a.x-b.x)
      return result
    }
  },
  watch: {
    deviceCode(newValue) {
      this.testData = Math.random()*1000
      let stload = this.$store.getters["devices/deviceHistoryLoaded"] (newValue, this.currentDate)
      if (!stload) {
        console.log('load HIstory')
        this.loadCurrentHistoryData()
      } else {
        console.log ('NOT load history')
      }

      let xstload = this.$store.getters["devices/deviceDailyLoaded"] (this.usageFromDate, this.usageToDate)
      if (!xstload) {
        console.log('load Daily')
        this.loadCurrentDailyData()
      } else {
        console.log ('NOT load daily')
      }


    },
    currentDate (newValue) {
      console.log('current date changed', newValue, this.currentDate)
      let stload = this.$store.getters["devices/deviceHistoryLoaded"] (newValue, this.currentDate)
      if (!stload) {
        console.log('load HIstory')
        this.loadCurrentHistoryData()
      } else {
        console.log ('NOT load history')
      }
    },
    usageFromDate (newValue) {
      console.log('usage-from-date', newValue)
      let stload = this.$store.getters["devices/deviceDailyLoaded"] (this.usageFromDate, this.usageToDate)
      if (!stload) {
        console.log('load Daily')
        this.loadCurrentDailyData()
      } else {
        console.log ('NOT load daily')
      }

    },
    usageToDate (newValue) {
      console.log('usage-to-date', newValue)
      let stload = this.$store.getters["devices/deviceDailyLoaded"] (this.usageFromDate, this.usageToDate)
      if (!stload) {
        console.log('load Daily')
        this.loadCurrentDailyData()
      } else {
        console.log ('NOT load daily')
      }
    },
  },
  created () {
    this.userInfo = xstore.getters.USER_INFO

    this.datasets = [this.randomData(20, 200, 100), this.randomData(20, 200, 100)]
    console.log('datasets....', this.datasets)
    let now = new Date()
    // jam 00 di tanggal 2 setelah convert jadi iso jadi tanggal 1
    this.usageFromDate = (new Date(now.getFullYear(), now.getMonth(), 2)).toISOString().substr(0, 10)
    this.usageToDate = (new Date()).toISOString().substr(0, 10)

  },
  async mounted() {
    // console.log("mapLocation", this.locations);
    this.testData = Math.random()*100000
    this.loadCurrentHistoryData()
    // await this.loadCurrentHistoryData()
  },
};
</script>

<style>
.jsoneditor-vue {
    height: 500px;
}
</style>
