const deviceFilter = {
  status: 'all'
}

var any_dc_device = false

function getAnyDcDevice () {
  return any_dc_device
}

function setAnyDcDevice(value) {
  any_dc_device = value
}

const checkStatus = function (vnode) {
  let result = ''
  let pwThreshold = 7
  console.log('POWER', vnode.current*vnode.voltage)
  if (vnode.maintenance) {
    result = 'maintenance'
  }else if (vnode.error_status) {
    result = 'error'
  } else if (vnode.offlineStatus==='offline') {
    result = 'offline'
  } else if ((typeof vnode.statuson !== 'undefined' ) && (vnode.statuson)){
    result = 'on'
  } else if ((typeof vnode.current !== 'undefined' ) && (typeof vnode.voltage !== 'undefined' ) && (vnode.current*vnode.voltage>=pwThreshold)) {
    result = 'on'
  } else if ((typeof vnode.current !== 'undefined' ) && (vnode.current*1>=0.06)) {
    result = 'on'
  // } else if ((typeof vnode.brightness !== 'undefined' ) && (vnode.brightness>0.0000001)) {
  //   result = 'on'
  } else {
    result = 'off'
  }
  return result
}

const checkRssiStrength = function (vnode) {
  // <-110 --> rendah
  // -110 s/d -100 --> sedang
  // >-100 --> tinggi
  let result = 'other'
  if (typeof vnode.rssi !== 'undefined') {
    if (vnode.rssi*1 < -110) {
      result = 'rendah'
    } else if (vnode.rssi*1 < -100) {
      result = 'sedang'
    } else {
      result = 'tinggi'
    }
  }
  return result
}

const calcLoad = function (vnode) {
  let result = undefined
  if ((typeof vnode.current !=='undefined') && (typeof vnode.voltage !== 'undefined')) {
    result = vnode.current * vnode.voltage
    if (isNaN(result)) {
      result = undefined
    }
  }
  return result
}

// konversi huruf besar di awal kata
const capitalize = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
  // return str+'***'
}


// // konversi snake case ke caption case
// const snakeToCamel = function (str) { 
//   let xstr = str.replace(/([-_][a-z])/ig, ($1) => {
//     let vstr = $1.toUpperCase()
//         .replace('-', ' ')
//         .replace('_', ' ')
//     return vstr
//   });
//   return capitalize(xstr)
// }

// const convertTODesc = function (str) {
//   const data = {
//     'high': 'Tinggi',
//     'medium': 'Sedang',
//     'other': 'Tidak diketahui',
//   }
// }

const inSearch = function (vnode, deviceFilter) {
  // console.log(vnode, deviceFilter)

  
  // return false
  if (deviceFilter.status==='all') {
    console.log('filter all', vnode.status, true)
    return true
  } else if (deviceFilter.status==='error') {
    if (vnode.status==='error') {
      if (deviceFilter.subStatus==='all') {
        return true
      } else if (deviceFilter.subStatus==='no_current_data') {
        if(vnode.error_status==='no_current_data') {
          return true
        } else {
          return false
        }
      } else if (deviceFilter.subStatus==='other') {
        if(vnode.error_status==='no_current_data') {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    } else {
      console.log('filter '+deviceFilter.status, vnode.status, false)
      return false
    }
  } else if (deviceFilter.status===vnode.status) {
    console.log('filter '+deviceFilter.status, vnode.status, true)
    return true
  } else {
    console.log('filter '+deviceFilter.status, vnode.status, false)
    return false
  }
}

const calculateChildren = function (vParent, searchId) {
  let vcount = 0
  let vcountOn = 0
  let vcountOff = 0
  let vcountError = 0
  let vcountMaint = 0
  let vcountOffline = 0
  let vtotalPower = 0
  let vcountVoltage = 0
  let vtotalVoltage = 0
  let vtotalCurrent = 0
  let vChildren = vParent.children
  let xlist = []
  let vselected = null
  for (let i=0; i<vChildren.length; i++) {
    let vnode = vChildren[i]
    if (vnode.type==='device' && vnode.devicetype==='pju-dc') {
      setAnyDcDevice(true)
      console.log('sssssss', vnode, any_dc_device)
    }
    if (vnode.id == searchId) {
      vselected = vnode
    }
    if (vnode.type==='device') {
      
      let vErr = checkErrorStatus(vnode)
      let vOffline = checkOfflineStatus(vnode)
      vnode['onlineStatus'] = vOffline.onlineStatus
      vnode['dataAge'] = vOffline.dataAge
      console.log('Err Status', vErr)
      if (vErr) {
        vnode.error_status = vErr.errorname
        vnode.error_reason = vErr.message
      } else {
        delete vnode.error_status
        delete vnode.error_reason
      }
      vnode.rssi_strength = checkRssiStrength(vnode)
      vnode.rssi_strength_desc = capitalize(vnode.rssi_strength)
      vnode.offlineStatus = vOffline.offlineStatus
      vnode.status = checkStatus(vnode)
      if (inSearch(vnode, deviceFilter)) {
        vcount = vcount+1

        if (vnode.status!=='maintenance') {
          if (typeof vnode.current!=='undefined') {
            vtotalCurrent = vtotalCurrent + vnode.current * 1
          }
          if (typeof vnode.voltage!=='undefined') {
            vcountVoltage = vcountVoltage + 1
            vtotalVoltage = vtotalVoltage + vnode.voltage * 1
          }
          if (typeof vnode.power!=='undefined') {
            vtotalPower = vtotalPower + vnode.power * 1
          }

        }
        if (vnode.status==='offline') {
          vcountOffline = vcountOffline + 1
          vnode.status_description = vnode.error_reason
          vnode.status_caption = 'Offline'
        } else if (vnode.status==='maintenance') {
          vcountMaint = vcountMaint + 1
          vnode.status_description = vnode.maintenance_desc
          vnode.status_caption = 'Maintenance'
        } else if (vnode.status==='error') {
          vcountError = vcountError + 1
          vnode.status_description = vnode.error_reason
          vnode.status_caption = 'Warning'
        } else if (vnode.status==='on') {
          vcountOn = vcountOn + 1
          vnode.status_description = undefined
          vnode.status_caption = 'ON'
        } else if (vnode.status==='off') {
          vcountOff = vcountOff + 1
          vnode.status_description = undefined
          vnode.status_caption = 'OFF'
        } else {
          vcountError = vcountError + 1
          vnode.status_description = undefined
          vnode.status_caption = ''
        }
        xlist.push(vnode)
      }
      // if (vErr || (vOffline.offlineStatus==='offline')) {
      //   vcountError = vcountError + 1
      //   vnode['lightstatus'] = false
      // } else {
      //   if (checkOnStatus(vnode)) {
      //     vcountOn = vcountOn + 1
      //     vnode['lightstatus'] = true
      //   } else {
      //     vcountOff = vcountOff + 1
      //     vnode['lightstatus'] = false
      //   }
      // }
      vnode.load = calcLoad(vnode)
    } else {
      if (vParent.name==='Bandung') {
        console.log('xparent', vParent, xlist)
      }
      if (vnode.hasChildren) {
        let {count, countOn, countOff, countOffline, countMaint, countError, listDevices, selected, 
          totalPower, countVoltage, totalVoltage, totalCurrent} = calculateChildren(vnode, searchId)
        xlist = xlist.concat(listDevices)
        let averageVoltage = 0
        if (countVoltage>0) {
          averageVoltage = totalVoltage/countVoltage
        }
        vnode.info = {count: count, countOn: countOn, countMaint: countMaint, countOffline: countOffline, 
          countOff: countOff, countError: countError, totalPower: totalPower, countVoltage: countVoltage, 
          totalVoltage: totalVoltage, totalCurrent: totalCurrent, averageVoltage: averageVoltage}
        vnode.listDevices = listDevices
        vcount = vcount+count
        vcountOn = vcountOn + countOn
        vcountMaint = vcountMaint + countMaint
        vcountOff = vcountOff + countOff
        vcountOffline = vcountOffline + countOffline
        vcountError = vcountError + countError
        if (selected) {
          vselected = selected
        }
        vtotalPower = vtotalPower + totalPower
        vcountVoltage = vcountVoltage + countVoltage
        vtotalVoltage = vtotalVoltage + totalVoltage
        vtotalCurrent = vtotalCurrent + totalCurrent
      }

    }
  }
  return {count: vcount, countOn: vcountOn, countOff: vcountOff, countError: vcountError, 
    countOffline: vcountOffline, countMaint: vcountMaint, listDevices: xlist, selected: vselected,
    totalPower: vtotalPower, countVoltage: vcountVoltage, totalVoltage: vtotalVoltage, totalCurrent: vtotalCurrent}
}

const calculateDeviceTree = function (deviceTree, searchId, statusMode, subStatus = '') {
  any_dc_device = false
  deviceFilter.status = statusMode
  deviceFilter.subStatus = subStatus
  console.log('calculateDeviceTree', statusMode, subStatus, deviceTree)
  let result = {count: 0, countOn: 0, countOff: 0, countOffline: 0, countError: 0}
  if (typeof deviceTree.children !== 'undefined') {
    // const vChildren = deviceTree.children
    let {count, countOn, countOff, countMaint, countOffline, countError, listDevices, selected, 
      totalPower, countVoltage, totalVoltage, totalCurrent} = calculateChildren(deviceTree, searchId)
    result = {count: count, countOn: countOn, countMaint: countMaint, countOffline: countOffline, 
      countOff: countOff, countError: countError, selected: selected, totalPower: totalPower, countVoltage: countVoltage,
      totalVoltage: totalVoltage, totalCurrent: totalCurrent}
    if (typeof deviceTree.info === 'undefined') {
      deviceTree.info = {}
    }
    deviceTree.info.count = result.count
    deviceTree.info.countOn = result.countOn
    deviceTree.info.countOff = result.countOff
    deviceTree.info.countOffline = result.countOffline
    deviceTree.info.countMaint = result.countMaint
    deviceTree.info.countError = result.countError
    deviceTree.info.totalPower = result.totalPower
    deviceTree.info.totalCurrent = result.totalCurrent
    deviceTree.info.totalVoltage = result.totalVoltage
    let averageVoltage = 0
    if (result.countVoltage>0) {
      averageVoltage = result.totalVoltage/result.countVoltage
    }
    deviceTree.info.averageVoltage = averageVoltage
    deviceTree.listDevices = listDevices
    if (deviceTree.info.count>0) {
      deviceTree.info.percentOn = deviceTree.info.countOn/deviceTree.info.count * 100
    } else {
      deviceTree.info.percentOn = 0
    }
    console.log('xcalc count', count, countOn, countOff, countError, listDevices)
  }
  // console.log('ssss any data device', getAnyDcDevice())
  return {result: result, any_dc_device: getAnyDcDevice()}
  // return result
}

const checkOnStatus = function (vdata) {
  // if (vdata) {
  //   return true
  // }
  // return true
  if (vdata && (typeof vdata.current !== 'undefined')) {
    if (!(isNaN(vdata.current))) {
      let smallNumber = 0.000000001
      let vcurrent = vdata.current * 1
      if (vcurrent>smallNumber) {
        return true
      }  
    }
  }
  return false
}

const checkDataAge = function (vdata) {
  // bila field waktu tidak ada maka umur data dianggap besar (default)
  // default menggunakan angka yg sangat besar (mis 1000 hari = 1000 * 24 * 3600)
  let result = 100 * 24 * 3600
  if ((typeof vdata !== 'undefined') && (typeof vdata.lastdata !== 'undefined' )) {
    let ms = Date.now()
    let xdate = new Date(vdata.lastdata).getTime();
    result = Math.floor((ms - xdate)/1000)
  }
  return result
}

const checkErrorStatus = function (vdata) {
  // error
  // - no data
  // - no current data
  // - broken (status relay on tapi current = 0)
  // - offline
  // kalau tidak error, return false
  if (typeof vdata ==='undefined') {
    return {errorname: 'no_data', message: 'No Data'}
  } else {
    // ada data error
    if (typeof vdata.error_status !== 'undefined' && vdata.error_status) {
      let err_stat = vdata.error_status
      let err_reason = 'Unknown Error'
      if (typeof vdata.error_reason !== 'undefined') {
        err_reason = vdata.error_reason
      }
      return {errorname: err_stat, message: err_reason}
    }
    // tidak ada data current
    if ( typeof vdata.current === 'undefined') {
      return {errorname: 'no_current_data', message: 'No Current Data'}
    } else {
      if (typeof vdata.relaystatus !== 'undefined') {
        if (vdata.relaystatus==='ON') {
          let statusOn = checkOnStatus(vdata)
          if (!statusOn) {
            return {errorname: 'broken', message: 'Lamp Broken. Relay: ON, Current: 0'}
          }

          // let smallNumber = 0.000000001
          // if (Math.abs(vdata.current * 1) < smallNumber) {
          //   return {errorname: 'broken', message: 'Lamp Broken'}
          // }
        }
      }
    }

  }
  return false
}

const formatInterval = function(seconds) {
  if (seconds < 60) {
    return seconds + ' sec'
  } else if (seconds < 3600) {
    let minutes = Math.floor(seconds/60)
    return minutes + ' min'
  } else if (seconds < (24 * 3600)) {
    let hours = Math.floor(seconds/3600)
    return hours + ' hours'
  } else if (seconds < (100 * 24 * 3600)) {
    let days = Math.floor(seconds/(24 * 3600))
    return days + ' days'
  } else {
    return '> 99 days'
  }
}

const checkOfflineStatus = function (vdata) {
  // let maxTime = 1 * 24 * 3600 // 24 jam
  let maxTime = 3600 * 2 // 2 jam
  let dataAge = checkDataAge(vdata)
  let interval = formatInterval(dataAge)
  if (dataAge>maxTime) {
    return {offlineStatus: 'offline', dataAge: interval}
  }
  return {offlineStatus: 'online', dataAge: interval}
}

const getDeviceItem = function(vchildren, vlist) {
  if (vchildren) {
    for (let i = 0; i<vchildren.length; i++) {
      let vitem = vchildren[i]
      if (vitem.type==='device') {
        vlist.push(vitem)
      }
      if (vitem.children) {
        getDeviceItem(vitem.children, vlist)
      }
    }
  }
}
const extractDeviceListFromTree = function (vselected) {
  let result = []
  if (vselected.children) {
    getDeviceItem(vselected.children, result)
  }
  return result
}

export {calculateDeviceTree, checkErrorStatus, checkOnStatus, extractDeviceListFromTree, getAnyDcDevice}