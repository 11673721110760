import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Counter from "./counter";
import DbLogin from "./dblogin";
import Devices from "./devices";

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    counter: Counter,
    login: DbLogin,
    devices: Devices
  }
})
